import React, { useState } from 'react'
import Dropdown from './Dropdown'
import { Assignment, Dashboard, ImportExport, Search } from '@mui/icons-material'

const ClaimsDropdown = ({verifiedUserNpi,active}) => {
    const [tabs] = useState([
        {label: "Dashboard",path: "/ClaimTab", icon: <Dashboard fontSize="small" /> },
        {label: 'Claim',path: "/ClaimTab?preSelectedTab=1", icon: <Assignment fontSize="small" />},
        {label: 'Search',path: "/ClaimTab?preSelectedTab=2", icon: <Search fontSize="small" />},
        {label: 'Import Claims',path: "/ClaimTab?preSelectedTab=3", icon: <ImportExport fontSize="small" />}
    ])
  return (
    <Dropdown
    tabs={tabs}
    verifiedUserNpi={verifiedUserNpi}
    heading={'Claims'}
    path={'/ClaimTab'}
    active={active}
    />
  )
}

export default ClaimsDropdown