import { colors } from "../../../utils/colors";

export const styles ={
    patientHistoryTypography:{
        mb: 4,
        textAlign: "center",
        fontWeight: "500"
    },
    visitCard:{ 
        mb: 3,
        borderRadius: 2,
        boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
        transition: "transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out",
        "&:hover": {
          transform: "translateY(-2px)",
          boxShadow: "0 6px 12px rgba(0, 0, 0, 0.15)"
        }
      },
      visitHeading: { display: "flex", alignItems: "center", mb: 2 },
      visitCardIcon : { mr: 2, color: colors?.blue},
      cardChip:{ mr: 1, mb: 1 },
      cardBasicInfo: { display: "flex", justifyContent: "space-between", alignItems: "center" }
}