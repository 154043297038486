import React from 'react'
import { Route, Routes} from "react-router-dom";
import FindInsurance from './Pages/Insurance/FindInsurance';
import Patients from './Pages/Patients/Patients';
import Discoverytab from './Pages/Discovery/DiscoveryTab';
import Discovery from './Pages/Discovery/Discovery';
import Discoverydetails1 from './Pages/Discovery/Discoverydetails1';
import Search from '@mui/icons-material/Search';
import Searchresults from './Pages/Discovery/Searchresults';
import Searchresultdata from './Pages/Discovery/Searchresultdata';
import Plandetail from './Pages/Discovery/Plandetail';
import Dashboard from './Pages/Dashboard';
import DentalSearchresults from './Pages/Eligibility/DentalSearchresults'
import DentalSearchresultsdata from './Pages/Eligibility/DentalSearchresultdata'
import Claim from './Pages/Claims/Claim';
import Claimdetail from './Pages/Claims/Claimdetail';
import Claimdetails1 from './Pages/Claims/Claimdetails1';
import Claimresults from './Pages/Claims/Claimresults';
import ClaimSearch from './Pages/Claims/ClaimSearch';
import ClaimSearchresultdata from './Pages/Claims/ClaimSearchresultdata';
import ClaimTab from './Pages/Claims/ClaimTab';
import EligibilityTab from './Pages/Eligibility/EligibilityTab'
import LandingPage from './Pages/LandingPage';
import PatientDetail from './Pages/Patients/PatientDetail';
import Profile from './Pages/Profile/Profile';
import NpiDetail from './Pages/Profile/NpiDetail';
import DentalSearch from './Pages/Eligibility/DentalSearch';
import Dentaldetails1 from './Pages/Eligibility/Dentaldetails1';
import ClaimBatchSearchresultdata from './Pages/Claims/ClaimBatchSearchresultdata';
import ImportClaims from './Pages/Claims/ImportClaims';
import BatchClaimResult from './Pages/Claims/BatchClaimResponse';
import PayerDetail from './Pages/Profile/PayerDetail';
import Notification from './Pages/Notifications/Notification';
import Auth from './Pages/Auth/Auth';
import EligibilitySearchResults from './Pages/Eligibility/EligibilitySearchResults'
import EligibilityDetails1 from './Pages/Eligibility/Eligibilitydetails1.js'
import Appointments from './Pages/Appointments/Appointments.js';
import ClaimEligibilityDetails from './Pages/Eligibility/ClaimEligibilityDetails.jsx';
import ImportDiscoveryPreview from './Pages/Discovery/ImportDiscoveryPreview.jsx';
import PaDetails from './Pages/Eligibility/PaDetails.jsx';

const AllRoutes = () => {
  return (
    <Routes>
                      <Route path='/' element={<LandingPage />} />
                      <Route path="/home" element={<Dashboard />} />
                      <Route path="/Registration" element={<Auth />} />
                      <Route path="/findInsurance" element={<FindInsurance />} />
                      <Route path="/patients" element={<Patients />} />
                      <Route path="/patients/:id" element={<PatientDetail />} />
                      <Route path="/Discoverytab" element={<Discoverytab />} />
                      <Route path="/Discovery" element={<Discovery />} />
                      <Route path="/Search" element={<Search />} />
                      <Route path="/DentalSearch" element={<DentalSearch />} />
                      <Route path="/DentalSearchresults" element={<DentalSearchresults />} />
                      <Route path="/DentalSearchresultsdata" element={<DentalSearchresultsdata />} />
                      <Route path="/Searchresults" element={<Searchresults />} />
                      <Route path="/Searchresultdata" element={<Searchresultdata />} />
                      <Route path="/Discoverydetails1" element={<Discoverydetails1 />} />
                      <Route path="/Plandetail" element={<Plandetail />} />
                      <Route path="/Claimresults" element={<Claimresults />} />
                      <Route path="/ClaimSearchresultdata" element={<ClaimSearchresultdata />} />
                      <Route path="/ClaimTab" element={<ClaimTab />} />
                      <Route path="/Claim" element={<Claim />} />
                      <Route path="/Claimdetail" element={<Claimdetail />} />
                      <Route path="/Claimdetails1" element={<Claimdetails1 />} />
                      <Route path="/Dentaldetails1" element={<Dentaldetails1 />} />
                      <Route path="/ClaimSearch" element={<ClaimSearch />} />
                      <Route path="/EligibilityTab" element={<EligibilityTab />} />
                      <Route path='/payerDetail' element={<PayerDetail/>} />
                      <Route path="/userSettings" element={<Profile />} />
                      <Route path="/npiDetail" element={<NpiDetail />} />
                      <Route path="/ImportClaims" element={<ImportClaims />} />
                      <Route path="/batchclaims" element={<ClaimBatchSearchresultdata />} />
                      <Route path="/batchclaimresponse" element={<BatchClaimResult />} />
                      <Route path="/notifications" element={<Notification />} />
                      <Route path='EligibilitySearchResults' element={<EligibilitySearchResults/>} />
                      <Route path='Eligibilitydetails1' element={<EligibilityDetails1/>} />
                      <Route path="/appointments" element={<Appointments/>}/>
                      <Route path="/claimEligibilityDetails" element={<ClaimEligibilityDetails/>}/>
                      <Route path="/previewBulkUpload" element={<ImportDiscoveryPreview/>}/>
                      <Route path="/priorAuthDetails" element={<PaDetails/>}/>
                      
                    </Routes>
  )
}

export default AllRoutes