import React, { useEffect, useState } from 'react';
import MyTabs from '../../components/tabs/MyTabs';
import PatientManagement from './PatientManagement';
import AddPatient from './AddPatient';
import { Box } from '@mui/material';
import SyncPatient from './SyncPatient';
import ImportPatient from './ImportPatient';
import { useLocation, useSearchParams } from 'react-router-dom';
import PatientDashboard from './PatientDashboard';

const Patients = () => {
    const [searchParams] = useSearchParams();
    const preSelectedTabParam = searchParams.get('preSelectedTab');
    const [preSelectedTab, setPreSelectedTab] = useState(preSelectedTabParam ? Number(preSelectedTabParam) : 0);
    const location = useLocation()

    const tabs = [
        { label: "Dashboard", value: <PatientDashboard />, button: 'Add Patient', changeTab: 2 },
        { label: "Patient Management", value: <PatientManagement />, button: 'Add Patient', changeTab: 2 },
        { label: "Add Patient", value: <AddPatient />, changeTab: 0, button: 'Back' },
        { label: "Import Patient", value: <ImportPatient />, changeTab: 0, button: 'Back' },
        { label: "Patient Sync", value: <SyncPatient />, changeTab: 0, button: 'Back' }
    ];
    
    useEffect(() => {
        setPreSelectedTab(preSelectedTabParam ? Number(preSelectedTabParam) : 0);
    }, [preSelectedTabParam]);

    return (
        <section className='patients' key={location?.key}>
            <Box sx={{ boxShadow: '0 0 2px 0 #919EAB', borderRadius: '12px' }}>
                <MyTabs tabs={tabs} preSelectedTab={preSelectedTab} />
            </Box>
        </section>
    );
};

export default Patients;
