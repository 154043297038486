import React, { useEffect, useState } from 'react'
import { getAllPatientsIds } from '../../utils/globalFunctions'
import { getAdjacentElements } from '../../utils/reusableFunctions'
import { FaChevronCircleLeft, FaChevronCircleRight } from 'react-icons/fa'
import { sharedStyles } from '../../sharedStyles'
import { colors } from '../../utils/colors'
import HorizontalBox from '../../components/shared/HorizontalBox'
import { Link } from 'react-router-dom'

const PatiensDetailsNavigator = ({ id, url,preSelectedTab }) => {
    const [adjacentELems, setAdjacentElems] = useState({ before: null, after: null })

    const getListOfAllPatientIds = async () => {
        const list = await getAllPatientsIds()
        if (list?.length) {
            const adjacentIds = getAdjacentElements(list, Number(id))
            setAdjacentElems(adjacentIds)
        }
    }
    useEffect(() => {
        getListOfAllPatientIds()
    }, [])
    

    return (
        <HorizontalBox sx={sharedStyles?.mr2} gap={2}>
            {adjacentELems?.before ?
                <Link reloadDocument
                    to={`${url}/${adjacentELems?.before}${preSelectedTab ? `?preSelectedTab=${preSelectedTab}` : ''}`}
                >
                    <FaChevronCircleLeft
                        style={sharedStyles?.cursorPointerOnly}
                        color={colors?.themeGreen}
                    />
                </Link>
                :
                null
            }
            {
                adjacentELems?.after ?
                    <Link reloadDocument
                        to={`${url}/${adjacentELems?.after}${preSelectedTab ? `?preSelectedTab=${preSelectedTab}` : ''}`}
                    >
                        <FaChevronCircleRight
                            style={sharedStyles?.cursorPointerOnly}
                            color={colors?.themeGreen}
                        />
                    </Link>
                    :
                    null
            }
        </HorizontalBox>
    )
}

export default PatiensDetailsNavigator