
import { colors } from "../../utils/colors";

export const styles ={
    headerButtonActive:{
        color:colors?.black,
        borderBottom:`1px solid ${colors?.themeGreen}`,
        borderRadius:0,
        cursor:'pointer',
        fontWeight:500
    },
    headerButtonNonActive:{
        color:colors?.black,
        cursor:'pointer',
        fontWeight:500
    },
    menuContainer:{
        position: 'absolute',
        top: 20,
        backgroundColor: colors?.white,
        zIndex: 1,
        left: -12,
        paddingTop: 20,
        boxShadow: `0 4px 2px -2px ${colors?.grayShadow}` ,
        borderRadius:10
    }
}