import React from 'react'
import Discovery from './Discovery'
import { Box } from '@mui/material'
import { styles } from './styles'
import ScheduledTransactions from './ScheduledTransactions'
import { DiscoveryTableProvider } from '../../utils/contexts/DiscoveryTableContext'

const DiscoveryTables = () => {
  return (
    <DiscoveryTableProvider>
    <Box sx={styles?.discoveryTablesContainer}>
    <Discovery/>
    <ScheduledTransactions/>
    </Box>
    </DiscoveryTableProvider>
      
  )
}

export default DiscoveryTables