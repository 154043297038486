import { useEffect, useState } from 'react';
import { getPVerifyToken } from '../services/pVerify';

const usePVerifyToken = () => {
  const [pverifyToken, setPverifyToken] = useState(null);

  const getToken = async () => {
    try {
      const response = await getPVerifyToken();
      if (response?.status === 200) {
        const { access_token } = response?.data;
        setPverifyToken(access_token)
      }
    } catch (error) {
      console.log(error)
    }
  }


  useEffect(() => {
    getToken();
  }, []);

  return { pverifyToken };
};

export default usePVerifyToken;
