import React, { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom';
import { Box} from '@mui/material';
import GridComponent from '../../css/GridComponent';
import { capitalizedFirstLetter } from '../../utils/reusableFunctions';

const Searchresultdata = () => {
    const pagename = "Searchresultdata";
    const location = useLocation();
    const searchData = location.state?.searchData || [];
    return (
        <Box>
          <Box sx={{
            display: 'flex',
            alignItems: 'center',
            gap: '1.2em',
          }}>
            <Box sx={{ width: '25%' }}>
            <p>{`${capitalizedFirstLetter(searchData?.insuranceDiscoveryDtosList?.[0]?.patientData?.firstName)} ${capitalizedFirstLetter(searchData?.insuranceDiscoveryDtosList?.[0]?.patientData?.lastName)}`}</p>
            </Box>
          </Box>
          <Box sx={{ marginTop: '2em' }}>
          </Box>
          <GridComponent
         pagename={pagename}
         searchData={searchData}
          />
        </Box>
      )
}
export default Searchresultdata