import React from 'react'

const RadioGroup = ({ label, options, selectedValue, onChange }) => (
    <div className="radio-group">
        <label className="label-form"><strong>{label ?? ''}</strong></label>
        {options?.length && options?.map(({ value, label }) => (
            <label className="label-form" key={value}>
                <input className="form-checkbox-radio"
                    type="radio"
                    value={value}
                    checked={selectedValue === value}
                    onChange={onChange}
                />
                {label}
            </label>
        ))}
    </div>
);

export default RadioGroup