import { Typography } from '@mui/material'
import React from 'react'
import { importantFieldStr } from '../../utils/staticData'

const ErrorrText = ({ text,importantField }) => {
    return (
        <Typography color={'error'}>
            {importantField ? importantFieldStr : text}
        </Typography>
    )
}

export default ErrorrText