import toast from "react-hot-toast";
import { get_GetAllPatientsDataAsync, get_GetPatientDataById } from "../../services/addPatient";
import { ApprovedClaims, checkScheduleTask, getAllScheduledTransactionsDetailsClaims, mostCommonDenialReason, pendingClaims, post_Scheduletask } from "../../services/ClaimService";
import {  get_payersdata } from "../../services/PayerService";
import { ymdDateFormat } from "../../utils/DateFunctions";
import { getLocalData } from "../../utils/localStorageFunctions";
import { failedGetApi, smallLettersStr } from "../../utils/reusableFunctions";
import { emptyClaimsTable, noApprovedClaimsDataFoundStr, noPendingClaimsDataFoundStr, noReasonFoundForClaimsStr, succesfullReqStr, succesfulScheduleDateUpdationStr, successfullyDeletedTaskStr, taskScheduledFailedStr, taskScheduledSuccessfullyStr } from "../../utils/staticData";
import { cancelScheduledTask, editScheduleDate } from "../../services/registration";
import dayjs from "dayjs";

// export const fetchClaimsData = async (setSuggestions, setIsSuggestionsLoading, setInput) => {
//   try {
//     setIsSuggestionsLoading(true)
//     let npiInfo = getLocalData('selectedNpi')
//     const patientdata = await get_GetAllPatientsDataAsync(npiInfo?.id);
//     const results = patientdata?.patientDataList?.length ? patientdata?.patientDataList?.map(patient => ({
//       firstName: patient?.firstName,
//       lastName: patient?.lastName,
//       dob: patient?.dob
//     })) : emptyClaimsTable
//     setSuggestions(results);
//     setInput('')
//   } catch (error) {
//     failedGetApi(error)
//   }
//   finally {
//     setIsSuggestionsLoading(false)
//   }
// };

export const handleSuggestionToShow = (event,value, setSuggestionsToShow,suggestions,setInput) => {
    setInput(value)
    const results = suggestions?.filter((patient) => {
      return (
        value &&
        patient?.firstName &&
        patient?.lastName &&
        patient?.dob &&
        (patient?.firstName?.toLowerCase()?.includes(value?.toLowerCase()) ||
          patient?.lastName?.toLowerCase()?.includes(value?.toLowerCase()) ||
          patient?.dob?.toLowerCase()?.includes(value?.toLowerCase()))
      );
    });
    setSuggestionsToShow(results);
  }

export const filterRowData = (setRowDataToUse, rowData, selectedUser) => {
    const filteredData = rowData?.filter(({ Patient, DOB }) => {
        const [name, dob] = selectedUser?.split(' - ')

        if (DOB) {
            return smallLettersStr(Patient) === smallLettersStr(name) && dob === DOB
        }

        else return smallLettersStr(Patient) === smallLettersStr(name)

    })
    setRowDataToUse(filteredData)
}

export const searchButtonCheck = (selectedPatient,formDetails) => {
  const response =  !selectedPatient || selectedPatient?.PatientFirstName !== formDetails.PatientFirstName && selectedPatient?.LastName !== formDetails?.LastName
  return response
}

export const fetchPatientDetails = async (patientSelected,setSelectedPatient,setformDetails,formDetails,currentNpi) => {
    const patientData = await get_GetPatientDataById(patientSelected?.id)

    if (patientData?.data) {
        const {
            id: patientid,
            firstName: PatientFirstName,
            lastName: LastName,
            middleName,
            dob,
            phone,
            patientInsuranceDetails: insuranceName,
            memberId,
        } = patientData?.data

        const data = {
            patientid,
            PatientInsurancePayer: insuranceName ?? [],
            PatientFirstName,
            middleName: middleName || "",
            LastName,
            dateofservice: ymdDateFormat(new Date()),
            dob,
            memberId,
            phone,
            NPI: currentNpi
        }
        setSelectedPatient(data)
        setformDetails({...formDetails,...data})
    }
    else {
        failedGetApi()
    }
}

export const PayersDropdown = async (setPayersData) => {
    const payersdata = await get_payersdata();
    if (payersdata?.data?.length) {
      const payers_array = payersdata?.data?.map(({ payerName }) => payerName);
        setPayersData(payers_array)
    }
    else {
        failedGetApi()
    }
};

export const handleScheduleTask = async (newValue,setformDetails) => {
    if (newValue) {
            setformDetails((prevDetails) => ({
                ...prevDetails,
                scheduleDate: newValue,
            }));
        } 
    }

export const scheduleTask = async(formDetails,scheduleDetails,setSelectedPatient,setformDetails,defaultFormDetails,currentNpi) => {
    const response = await post_Scheduletask(formDetails?.patientid, scheduleDetails);
    if (response?.isSuccessful) {
        toast.success(taskScheduledSuccessfullyStr);
        setSelectedPatient(null);
        setformDetails({
            ...defaultFormDetails,
            NPI: currentNpi
        });
    } else {
        toast.error(taskScheduledFailedStr);
    }
}

export const formDetailsCheck = (formDetails) => {
   return (formDetails?.PatientFirstName === "" || formDetails?.LastName === ""  || formDetails?.dateofservice === "" || formDetails.NPI === "" || formDetails?.scheduleDate == null) 
}

export const getScheduledTransactions = async (setRowData,setIsLoading) => {
    try {
      setIsLoading && setIsLoading(true)
      const transactionsData = await getAllScheduledTransactionsDetailsClaims()
      if (transactionsData?.data?.length) {
        const dataNeeded = transactionsData?.data?.map(({
          scheduleTaskData: { scheduleDate, status, id, requestParameters },
          patientData: { firstName, lastName, phone,id:patientId }
        }) => {
          return (
            {
              scheduleDate,
              patient: firstName + ' ' + lastName,
              phone,
              status,
              id,
              patientId,
              requestParameters
            }
          )
        })
        setRowData(dataNeeded)
      }
    }
    catch (e) {
      console.log(e)
    }
    finally {
      setIsLoading && setIsLoading(false)
    }
  }

  export const handleAction = async(setShowEditModal,setNewScheduleDate,setDefaultScheduleDate,setRowData,id,newScheduleDate,setIsLoading,setIsEditing,setRefetchApi,refetchApi) => {
    try{ 
     setIsEditing(true)
     const editData = await editScheduleDate(id,ymdDateFormat(dayjs(newScheduleDate))?.toString())
     if(editData?.isSuccessful){
       toast?.success(succesfulScheduleDateUpdationStr)
       clearData(setShowEditModal,setNewScheduleDate,setDefaultScheduleDate,setRefetchApi,refetchApi)
     }}
     catch(e){
       console.log(e)
     }
     finally{
       setIsEditing(false)
     }
   }

   const clearData = (setShowEditModal,setNewScheduleDate,setDefaultScheduleDate,setRefetchApi,refetchApi) => {
    setShowEditModal(false)
    setNewScheduleDate('')
    setDefaultScheduleDate('')
    setRefetchApi(!refetchApi)
}

export const handleCancelTask = async (id, setRowData, setIsCancelling,setRefetchApi,refetchApi) => {
  try {
    setIsCancelling(true)
    const cancellingTask = await cancelScheduledTask(id)
    if (cancellingTask?.isSuccessful) {
      toast?.success(successfullyDeletedTaskStr)
      setRefetchApi(!refetchApi)
      // getScheduledTransactions(setRowData)
    }
  }
  catch (e) {
    console.log(e)
  }
  finally {
    setIsCancelling(false)
  }
}

export const checkScheduleTaskNow = async (id,setIsChecking,setRowData,setIsLoading,reqParams,setRefetchApi,refetchApi) => {
  try{ 
    setIsChecking &&  setIsChecking(true)
    const request = await checkScheduleTask(id,reqParams)
    if(request){
      toast?.success(succesfullReqStr)
      setRefetchApi(!refetchApi)
      // getScheduledTransactions(setRowData,setIsLoading)
    }
  }
    catch(e){
      console.log(e)
    }
    finally{
      setIsChecking &&  setIsChecking(false)
    }
}

export const getApprovedClaims = async(setApprovedClaimsData,setLoader) => {
  try{
    setLoader(true)
    const approvedClaimsData = await ApprovedClaims()
    if(approvedClaimsData?.data?.length){
      const length = approvedClaimsData?.data?.length
      const totalPaidAmount = approvedClaimsData?.data?.reduce((curr,accum)=>{
        return curr + Number(accum?.payerPaid)
      },0)
      setApprovedClaimsData({
        length,
        totalPaidAmount
      })
    }
  }
  catch(e){
    failedGetApi()
  }
  finally{
    setLoader(false)
  }
}

export const getPendingClaims = async(setPendingClaimsData,setLoader) => {
  try{
    setLoader(true)
    const pendingClaimsData = await pendingClaims()
    if(pendingClaimsData?.data?.length){
      const length = pendingClaimsData?.data?.length
      const totalPaidAmount = pendingClaimsData?.data?.reduce((curr,accum)=>{
        return curr + Number(accum?.payerPaid)
      },0)
      setPendingClaimsData({
        length,
        totalPaidAmount
      })
    }
  }
  catch(e){
    failedGetApi()
  }
  finally{
    setLoader(false)
  }
}

export const getMostCommonDenialReason = async(setMostCommonDenialReason, setLoader) => {
  try{
    setLoader(true)
    const reason = await mostCommonDenialReason()
    if(reason?.mostCommonDenialReason){
      const commonReason = reason?.mostCommonDenialReason
      setMostCommonDenialReason(commonReason)
    }
  }
  catch(e){
    failedGetApi()
  }
  finally{
    setLoader(false)
  }
}