import React, { createContext, useContext, useState } from 'react';

const DiscoveryTableContext = createContext();

export const useDiscoveryTable = () => {
  return useContext(DiscoveryTableContext);
};

// Provider component
export const DiscoveryTableProvider = ({ children }) => {
  const [refetchDiscoveryTable, setRefetchDiscoveryTable] = useState(0);


  return (
    <DiscoveryTableContext.Provider value={{ refetchDiscoveryTable, setRefetchDiscoveryTable }}>
      {children}
    </DiscoveryTableContext.Provider>
  );
};
