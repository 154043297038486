import React, { useEffect, useState } from 'react'
import ModalWrapper from '../../components/shared/ModalWrapper/ModalWrapper'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { Box, Button, CircularProgress, FormControl, FormControlLabel, Grid, Radio } from '@mui/material'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { styles } from './styles'
import GreenButton from '../../components/claims/GreenButton'
import { sharedStyles } from '../../sharedStyles'
import dayjs from 'dayjs'
import { dateFilterIntervals } from '../../utils/staticData'
import { extractDateFromDayjs, getPastDate } from '../../utils/DateFunctions'

const DateFilterModal = ({open,handleClose,startDate, endDate, setStartDate, setEndDate,action, isLoading,resetAction,allowReset,setLastSelectedFilter,dateRecord,lastSelectedFilter}) => {

  const [filterIntervals, setFilterIntervals] = useState(dateFilterIntervals)
  
  const handleRadioClick = (interval) => {
    try {
      const dateOneWeekAgo = getPastDate(interval);
      setStartDate(dayjs(dateOneWeekAgo));
      setEndDate(dayjs(new Date()))
      setFilterIntervals(filterIntervals?.map((elem)=>{
        if(elem?.value === interval){
          setLastSelectedFilter && setLastSelectedFilter(elem?.label)
        }
        return(
          elem?.value === interval ? {...elem,selected:true} : {...elem,selected:false}
        )
      }))
    } catch (error) {
      console.error(error);
    }
  };

   
  useEffect(()=>{
    const matchingVal = dateFilterIntervals?.find((elem)=>{
      const date = getPastDate(elem?.value)
      if(dateRecord){
        return extractDateFromDayjs(dayjs(date)) === extractDateFromDayjs(startDate?.toString())
      }  
      else{
        return extractDateFromDayjs(dayjs(date)) === extractDateFromDayjs(startDate?.toString()) && dayjs(new Date(endDate))?.toISOString()?.split('T')[0] === dayjs(new Date())?.toISOString()?.split('T')[0]
      }
    })
    setFilterIntervals(filterIntervals?.map((elem)=>{
      return(
        (elem?.value === matchingVal?.value) 
         ? {...elem,selected:true} : {...elem,selected:false}
      )
    }))
  },[startDate])

  
  return (
    <ModalWrapper
       open={open}
       handleClose={() => {
        if(dateRecord){
          setStartDate(dateRecord?.startDate)
          setEndDate(dateRecord?.endDate)
          setLastSelectedFilter(lastSelectedFilter)
        }
        // else{
        //   setStartDate(dayjs(new Date()))
        //   setEndDate(dayjs(new Date()))
  
        // }
        setFilterIntervals(dateFilterIntervals)
        handleClose()
      }}
       >
        <FormControl sx={styles?.datePicker}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
  label="From"
  value={startDate}
  onChange={(newValue) => {
    setStartDate(newValue)
  }}
  disableFuture={true}
/>
</LocalizationProvider>
        </FormControl>
        <FormControl sx={styles?.datePicker}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
  label="Until"
  value={endDate}
  onChange={(newValue) => {
    setEndDate(newValue)
  }}
  disableFuture={true}
/>
</LocalizationProvider>

        <Grid container sx={sharedStyles?.mt5px}>
          {filterIntervals?.map(({ label, value, selected }) => {
            return (
              <Grid item xs={6} key={value}>
                <FormControlLabel
                 
                  control={
                    <Radio
                      checked={selected}
                      onChange={() => handleRadioClick(value)}
                    />
                  }
                  label={label}
                />
              </Grid>
            )
          })}
        </Grid>
        </FormControl>
        
        <Box display={'flex'} flexDirection={'row'} justifyContent={'flex-end'} sx={sharedStyles?.cursorPointer}>
          {allowReset &&
          <Button variant='contained' onClick={resetAction} sx={sharedStyles?.mr1} disabled={isLoading}>
            Reset
          </Button>
          }
              <GreenButton variant="contained" onClick={()=>action()} disabled={!endDate || !startDate || isLoading}>
                  {isLoading ?
                      <CircularProgress
                          color="inherit" size={22}
                      /> :
                      'Filter'
                  }
              </GreenButton>
      </Box>
       </ModalWrapper>
  )
}

export default DateFilterModal