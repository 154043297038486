import { Tooltip } from '@mui/material'
import React from 'react'

const ToolTipCellRenderer = ({text}) => {
  return (
       text !== 'N/A' && text?
          <Tooltip title={text} placement="bottom-start">
          <span>{text}</span>
          </Tooltip>
          :
          <span>{text}</span>
        )
}

export default ToolTipCellRenderer