import { AgGridReact } from 'ag-grid-react';
import "ag-grid-community/styles/ag-grid.css"; 
import "ag-grid-community/styles/ag-theme-quartz.css";
import { get_GetAllInsuranceDiscoveryData, get_GetInusuranceDiscoveryByRequestId,get_GetAllEligibilityData, get_GeteligibilitydetailsByrequestId } from '../services/registration';
import React, { useEffect, useMemo, useState } from 'react';
import { Button,Tooltip } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { capitalizedFirstLetter } from '../utils/reusableFunctions';
import { phoneCellRenderer } from '../components/GridComponents/GridComponents';
import { filterRowData } from '../Pages/Claims/ClaimFunctions';
import PageLoader from '../components/loader/PageLoader';
import { dayJsMdyFormat } from '../utils/DateFunctions';

const GridComponent = ({ pagename, searchData,eligibilitycheckData,openEditor,setIsLoading, filterPatient, isLoading,throughEligibility }) => {
  const [rowData, setRowData] = useState([]);
  const [rowDataToUse, setRowDataToUse] = useState([])
  let navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        let data;
        let flattenedRows = [];
  
        if (pagename === "Discovery" || pagename ==='DiscoveryPage') {
          setIsLoading && setIsLoading(true)
          data = await get_GetAllInsuranceDiscoveryData();

          if (Array.isArray(data.insuranceDiscoveryDtosList)) {
            flattenedRows = data.insuranceDiscoveryDtosList.flatMap(element => {
              return element.patientData ? [{
                ID: element?.patientId,
                Transactiondate: dayJsMdyFormat(element?.transactionDate) || 'N/A',
                Patient: `${capitalizedFirstLetter(element?.patientData?.firstName)} ${capitalizedFirstLetter(element?.patientData?.lastName)}`,
                Phone: element.patientData.phone || 'N/A',
                Insurance: element?.payerName || 'N/A',
                Status: element?.planCoverageSummary ? element?.planCoverageSummary?.status : 'N/A',
                Requestid: element?.requestID || 'N/A',
                completeData: {...element?.patientData,payerName:element?.payerName}
              }] : [];
            });
          }
        } 
        else if (pagename === "Searchresultdata") {
          data = searchData;
          flattenedRows = data?.insuranceDiscoveryDtosList?.map((elem)=>{
            return{
                 ID: elem?.patientData?.id,
            Date: dayJsMdyFormat(elem?.transactionDate) ?? 'N/A',
            // Date: elem?.patientData?.appointments?.length ? elem?.patientData?.appointments[0] : 'N/A',
            Patient: `${capitalizedFirstLetter(elem?.patientData?.firstName)} ${capitalizedFirstLetter(elem?.patientData?.lastName)}`,
            Phone: elem?.patientData?.phone || 'N/A',
            DOB: dayJsMdyFormat(elem?.patientData?.dateOfBirth) || 'N/A',
            Insurance: elem?.payerName || 'N/A',
            Status: elem?.planCoverageSummary ? elem?.planCoverageSummary?.status : 'N/A',
            Transactiondate: elem?.transactionDate || 'N/A',
            Requestid: elem?.requestID || 'N/A',
            Notes:(elem?.addtionalInfo ?? elem?.exceptionNotes) || 'N/A'
            }
          })
        }
        else if (pagename === "Eligibility") {  
          data = await get_GetAllEligibilityData();
          if (Array.isArray(data.eligibilityDtosList)) {
            flattenedRows = data.eligibilityDtosList.flatMap(element => {
              return element.patientData ? [{
                ID: element?.patientData?.id,
                Date: element?.patientData?.appointments ? dayJsMdyFormat(element?.patientData?.appointments[0]) : 'N/A',
                Patient: `${capitalizedFirstLetter(element?.patientData?.firstName)} ${capitalizedFirstLetter(element?.patientData?.lastName)}`,
                Phone: element?.patientData?.phone || 'N/A',
                DOB: dayJsMdyFormat(element?.patientData?.dob) || 'N/A',
                Insurance: element?.payerName || 'N/A',
                Copay: element?.additionalInfo || 'N/A', // Assuming additionalInfo contains Copay
                Status: element?.planCoverageSummary ? element?.planCoverageSummary?.status : 'N/A',
              }] : [];
            });
          }
        }
        else if (pagename === "EligibilitySearchresultdata" && throughEligibility) {
          const requestid =  eligibilitycheckData?.requestID 
          const patientid =  searchData?.selectedPatientId
          const transactionDate = formatTransactionDate(eligibilitycheckData?.transactionDate);
          data = await  get_GeteligibilitydetailsByrequestId(requestid, patientid, transactionDate)
            flattenedRows = [{
              ID: data?.eligibilityDto?.patientId,
              Date: data?.eligibilityDto?.transactionDate ? dayJsMdyFormat(data?.eligibilityDto?.transactionDate) : 'N/A',
              Patient: `${capitalizedFirstLetter(data?.eligibilityDto?.patientData?.firstName)} ${capitalizedFirstLetter(data?.eligibilityDto?.patientData?.lastName)}`,
              Phone: data?.eligibilityDto?.patientData?.phone || 'N/A',
              DOB: dayJsMdyFormat(data?.eligibilityDto?.patientData?.dateOfBirth) || 'N/A',
              Insurance: data?.eligibilityDto?.payerName || 'N/A',
              Copay: data?.eligibilityDto?.additionalInfo || 'N/A', 
              Status: data?.eligibilityDto?.planCoverageSummary ? data?.eligibilityDto?.planCoverageSummary?.status : 'N/A',
              requestID: data?.eligibilityDto?.requestID || 'N/A',
              transactionDate: data?.eligibilityDto?.transactionDate || 'N/A',
            }];
        
      
        } 
        else if (pagename === "EligibilitySearchresultdata" && !throughEligibility) {
          const requestid =  eligibilitycheckData.Requestid;
          const patientid =  eligibilitycheckData.ID;
          const transactionDate = formatTransactionDate(eligibilitycheckData.Transactiondate);
          data = await  get_GetInusuranceDiscoveryByRequestId(requestid, patientid, transactionDate);
            flattenedRows = [{
              ID: data?.insuranceDiscoveryDto?.patientData?.id,
              Date: data?.insuranceDiscoveryDto?.patientData?.appointments ? dayJsMdyFormat(data?.insuranceDiscoveryDto?.patientData?.appointments[0]) : 'N/A',
              Patient: `${capitalizedFirstLetter(data?.insuranceDiscoveryDto?.patientData?.firstName)} ${capitalizedFirstLetter(data?.insuranceDiscoveryDto?.patientData?.lastName)}`,
              Phone: data?.insuranceDiscoveryDto?.patientData?.phone || 'N/A',
              DOB: dayJsMdyFormat(data?.insuranceDiscoveryDto?.patientData?.dob) || 'N/A',
              Insurance: data?.insuranceDiscoveryDto?.payerName || 'N/A',
              Copay: data?.insuranceDiscoveryDto?.additionalInfo || 'N/A', // Assuming additionalInfo contains Copay
              Status: data?.insuranceDiscoveryDto?.planCoverageSummary ? data?.insuranceDiscoveryDto?.planCoverageSummary?.status : 'N/A',
              requestID: data?.insuranceDiscoveryDto?.requestID || 'N/A',
              transactionDate: data?.insuranceDiscoveryDto?.transactionDate || 'N/A',
              patientMemberId:data?.insuranceDiscoveryDto?.miscellaneousInfoSummary?.memberID,
              isSubscriberPatient:data?.insuranceDiscoveryDto?.isSubscriberPatient ?? 'true',
              insuranceName:data?.insuranceDiscoveryDto?.payerName,
              dateOfService:data?.insuranceDiscoveryDto?.dos,
              practiceTypeCode:data?.insuranceDiscoveryDto?.practiceTypeCode ?? '3'
            }];
        
      
        }
        
        else {
          data = searchData;
          flattenedRows = [{
            ID: data?.insuranceDiscoveryDto?.patientData?.id,
            Date: data?.insuranceDiscoveryDto?.patientData?.appointments ? dayJsMdyFormat(data?.insuranceDiscoveryDto?.patientData?.appointments[0]) : 'N/A',
            Patient: `${capitalizedFirstLetter(data?.insuranceDiscoveryDto?.patientData?.firstName)} ${capitalizedFirstLetter(data?.insuranceDiscoveryDto?.patientData?.lastName)}`,
            Phone: data?.insuranceDiscoveryDto?.patientData?.phone || 'N/A',
            DOB: dayJsMdyFormat(data?.insuranceDiscoveryDto?.patientData?.dob) || 'N/A',
            Insurance: data?.insuranceDiscoveryDto?.payerName || 'N/A',
            Copay: data?.insuranceDiscoveryDto?.additionalInfo || 'N/A', // Assuming additionalInfo contains Copay
            Status: data?.insuranceDiscoveryDto?.planCoverageSummary ? data?.insuranceDiscoveryDto?.planCoverageSummary?.status : 'N/A',
          }];
        }
  
        setRowData(flattenedRows);
        setIsLoading && setIsLoading(false)
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
  
    fetchData(); // Call fetchData function when component mounts
  }, [pagename]);
  
  useEffect(()=>{
    if(filterPatient){
      filterRowData(setRowDataToUse,rowData,filterPatient)
  }
  else setRowDataToUse(rowData)
  },[rowData, filterPatient])

  const formatTransactionDate = (dateString) => {
    const date = new Date(dateString);
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    const year = date.getFullYear();
    return `${month}-${day}-${year}`;
  };

  const statusCellRenderer = (params) => {
    const statusValue = params.value;
    const statusStyle = {
      backgroundColor: '#F8FFF6', // Green background color
      color: '#229A16', // Green font color
    };
    return <span style={statusStyle}>{statusValue}</span>;
  };

  const notesCellRenderer = ({data}) => {
    const text= data?.Notes
    return(
   text !== 'N/A' ?
      <Tooltip title={text} placement="bottom-start">
      <span>{text}</span>
      </Tooltip>
      :
      <span>{text}</span>
    )
  }

  const EligibilityDetailsButton  = (params) => {
    return (
      <Button
        className='primary'
        style={{ backgroundColor: 'green', border: 'none', color: 'white', fontSize: 'small' }}
        onClick={() => handleeligibilityButtonClick({...params.data,RequestId:params?.data?.requestID,TransactionDate:params?.data?.transactionDate})}
      >
        View Eligibility Details
      </Button>
    );
  };

  const handleeligibilityButtonClick = (data) => {
    navigate({
      pathname: '/Eligibilitydetails1',
      search: `?rowData=${encodeURIComponent(JSON.stringify(data))}`,
    });
  };

  const DetailsButton = (params) => {
    
    return (
      <Button
        className='primary'
        style={{ backgroundColor: 'green', border: 'none', color: 'white', fontSize: 'small' }}
        onClick={() => handleButtonClick(params.data)}
      >
        View Details
      </Button>
    );
  };

  const handleButtonClick = (data) => {
    navigate({
      pathname: '/Discoverydetails1',
      search: `?rowData=${encodeURIComponent(JSON.stringify(data))}${pagename === "Searchresultdata" ? '&through=Discovery':''}`,
    });
  };


  const columnDefs = useMemo(() => {
    if (pagename === "Discovery" || pagename === 'DiscoveryPage') {
      return [
      // { headerName: 'ID#', field: 'ID', width: 236 },
      { headerName: 'Transaction Date', field: 'Transactiondate', sortable: true, filter: true,width:163 },
      { headerName: 'Patient', field: 'Patient',sortable: true, filter: true ,width:236 },
      { headerName: 'Phone', field: 'Phone',sortable: true, filter: true, cellRenderer:(params) => phoneCellRenderer(params,()=>openEditor({...params?.data?.completeData,Requestid:params?.data?.Requestid})) ,width:123 },
      { headerName: 'Insurance', field: 'Insurance', width: 174 , sortable: true, filter: true },
      { headerName: 'Status', field: 'Status', sortable: true, filter: true ,cellRenderer: statusCellRenderer,width:92 },
      { headerName: 'Details', field: 'Details', width: 150, filter: true, cellRenderer: DetailsButton, flex:1,minWidth:143 },
    ];
    }
    else if(pagename === "Eligibility" || pagename === "EligibilitySearchresultdata"){
      return [
        // { headerName: 'ID#', field: 'ID', width:236 },
        { headerName: 'Date', field: 'Date', sortable: true, filter: true ,width:201},
        { headerName: 'Patient', field: 'Patient', filter: true,width:236 },
        { headerName: 'Phone', field: 'Phone', filter: true,width:123 },
        { headerName: 'Date of Birth', field: 'DOB', width: 120 },
        { headerName: 'Insurance Carrier', field: 'Insurance', width: 176, filter: true },
        { headerName: 'Status', field: 'Status', cellRenderer: statusCellRenderer, filter: true,width: 100 },
        { headerName: 'Details', field: 'Details', width: 150, filter: true, cellRenderer: EligibilityDetailsButton, flex:1,minWidth:143},
      ];
    }
    else if(pagename === 'Searchresultdata'){
      return [
        { headerName: 'Date', field: 'Date', sortable: true, filter: true,width:128 },
        { headerName: 'Date of Birth', field: 'DOB', width: 150 },
        { headerName: 'Insurance Carrier', field: 'Insurance', width: 176, filter: true },
        { headerName: 'Status', field: 'Status', cellRenderer: statusCellRenderer, filter: true ,width:92},
        { headerName: 'Notes', field: 'Notes', cellRenderer: notesCellRenderer, filter: true ,width:92},
        { headerName: 'Details', field: 'Details', width: 150, filter: true, cellRenderer: DetailsButton, flex:1,minWidth:143 },
      ];
    }
    else {
      return [
        // { headerName: 'ID#', field: 'ID', width: 236 },
        { headerName: 'Date', field: 'Date', sortable: true, filter: true,width:128 },
        { headerName: 'Patient', field: 'Patient', filter: true,width:236 },
        { headerName: 'Phone', field: 'Phone', filter: true,width:123 },
        { headerName: 'Date of Birth', field: 'DOB', width: 150 },
        { headerName: 'Insurance Carrier', field: 'Insurance', width: 176, filter: true },
        { headerName: 'Status', field: 'Status', cellRenderer: statusCellRenderer, filter: true ,width:92},
        { headerName: 'Details', field: 'Details', width: 150, filter: true, cellRenderer: DetailsButton, flex:1,minWidth:143 },
      ];
    }
  },[pagename])

 

  const gridOptions = {
    defaultColDef: {
      flex: 1,
      minWidth: 200
    },
    pagination: true,
    paginationPageSize: 10, 
    domLayout: 'paginationPrevNext,paginationPageSize,paginationFirstLast',
    overlayNoRowsTemplate: `
    <span class="ag-overlay-loading-center">
        Loading
    </span>
`
  };

  return (
    <div className="ag-theme-quartz"
      style={{ height: 500 }}  >

    {isLoading ?
    <PageLoader/>:
    <AgGridReact
        columnDefs={columnDefs}
        rowData={pagename === "Discovery" || pagename ==='DiscoveryPage' || 'Eligibility' ? rowDataToUse :rowData}
        gridOptions={ pagename === "EligibilitySearchresultdata" ? gridOptions : {...gridOptions,defaultColDef: {
          flex: 1,
          minWidth: 200
        }}}
      />}
    </div>
  );
};

export default GridComponent;
