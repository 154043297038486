import React from 'react'
import { styles } from './styles'
import { Box, Button, Modal } from '@mui/material'

const DisqualifiedModal = ({open,handleClose,onOk,onCancel}) => {
  return (
    <Modal open={open} onClose={handleClose} aria-labelledby='confirmation-modal' aria-describedby='modal-confirmation-description'>
    <Box sx={styles?.modalWrapper}>
       This selection disqualifies you from submitting your data, do you wish to change your answer ?
        <Box sx={styles?.buttonWrapper}>
            <Button  variant='contained' onClick={onOk} color='success'>Yes</Button>
            <Button  variant='contained' onClick={onCancel} color='error'>NO</Button>
        </Box>
    </Box>
</Modal>
  )
}

export default DisqualifiedModal