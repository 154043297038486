import React, { useEffect, useState } from 'react'
import { questionsString } from './QuestionString';
import RadioGroup from '../../../components/shared/RadioGroup';
import InputGroup from '../../Questionnaire/Questions/InputGroup';
import { bmiConsentString, consentAcknowledgedOptions, unAcceptedConsentStr } from '../../../utils/staticData';
import DisqualifiedModal from '../../Questionnaire/DisqualifiedModal';
import ErrorrText from '../../../components/shared/ErrorrText';

const QuestionTwo = ({
    heightFeet,
    setHeightFeet,
    heightInches,
    setHeightInches,
    weight,
    setWeight,
    bmi,
    bmiConsentAcknowledged,
    setBmiConsentAcknowledged,
    setBmiMessage,
    setBmi,
    setUserConsent,
    userConsent,
    failedValidations,
    missingFields
}) => {

    // states
    const [showModal, setShowModal] = useState(false)

    // functions
    const handleBmiConsentChange = (e) => {
        const { value } = e.target
        setBmiConsentAcknowledged(value)
        if (value === 'acknowledge') {
            setUserConsent({ ...userConsent, bmi: true })
        }
        else {
            setShowModal(true)
            setUserConsent({ ...userConsent, bmi: false })
        }
    }

    useEffect(()=>{
const {bmi} = userConsent
bmi ? setBmiConsentAcknowledged('acknowledge') : setBmiConsentAcknowledged('decline')
    },[userConsent])

    const calculateBMI = () => {
        const heightInFeetParsed = parseInt(heightFeet) || 0;
        const heightInInchesParsed = parseInt(heightInches) || 0;
        const weightParsed = parseInt(weight) || 0;

        if (heightInFeetParsed && heightInInchesParsed && weightParsed) {
            const heightInInches = heightInFeetParsed * 12 + heightInInchesParsed;
            const bmiValue = (weightParsed / (heightInInches ** 2)) * 703;
            setBmi(bmiValue.toFixed(1));
            if (bmiValue < 23) {
                setBmiMessage("Disqualified (BMI under 23 for a patient currently on treatment).");
            } else if (bmiValue < 27) {
                setBmiMessage("Disqualified (BMI under 27 for a treatment-naive patient).");
            } else {
                setBmiMessage("BMI is within the acceptable range for treatment.");
            }
        }
    };

    useEffect(() => {
        if (heightFeet && heightInches && weight) {
            calculateBMI()
        }
    }, [heightFeet, heightInches, weight])

    return (
        <div className="question">
            <label className="label-form"><strong>{questionsString?.followup2}</strong></label>
            <InputGroup
                heightFeet={heightFeet}
                setHeightFeet={setHeightFeet}
                heightInches={heightInches}
                setHeightInches={setHeightInches}
                weight={weight}
                setWeight={setWeight}
                missingFields={missingFields}
            />

            {/* Conditional BMI Consent */}
            {bmi && (
                <div className="consent-section">
                    <p className="consent-text">
                        <strong>Consent (BMI):</strong> <br />
                        {bmiConsentString}
                    </p>
                    <RadioGroup
                        label="Please acknowledge the information:"
                        options={consentAcknowledgedOptions}
                        selectedValue={bmiConsentAcknowledged}
                        onChange={handleBmiConsentChange}
                    />
                </div>
            )}

            {/* BMI Result */}
            {bmi && (
                <div className="bmi-result">
                    <p>Your BMI is: {bmi}</p>
                </div>
            )}
            <DisqualifiedModal
                open={showModal}
                handleClose={() => setShowModal(false)}
                onOk={() => {
                    setUserConsent({ ...userConsent, bmi: true })
                    setBmiConsentAcknowledged('acknowledge')
                    setShowModal(false)

                }}
                onCancel={() => {
                    setUserConsent({ ...userConsent, bmi: false })
                    setShowModal(false)
                }}
            />
            {failedValidations?.includes('bmiConsent') && <ErrorrText text={unAcceptedConsentStr}/>}
            {failedValidations?.includes('lowBmi') && <ErrorrText text={'Sorry, We dont accept patients with bmi less than 23'}/>}
        </div>
    );
};

export default QuestionTwo