import React, { useState, useEffect } from "react";
import {
  Box,
  FormControl,
  Button,
  Grid,
  Autocomplete,
  CircularProgress,
  TextField,
  InputAdornment,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import styles from "../../css/addpatient.module.css";
import { useNavigate } from "react-router-dom";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import { getLocalData } from "../../utils/localStorageFunctions";
import { defaultFormDetails } from "../../utils/claimDefualtValues";
import { defaultEligibilityUserDetails } from "../../utils/eligibilityDefaultValues";
import { ymdDateFormat } from "../../utils/DateFunctions";
import toast from "react-hot-toast";
import { get_GetAllPatientsDataAsync } from "../../services/addPatient";
import { get_NpiDataByNpiNumber } from "../../services/ClaimService";
import { get_GetPayerCodeByName } from "../../services/PayerService";
import { post_dentaleligibility } from "../../services/DentalEligibilityService";
import customStyles from "../../css/DentalSearchStyles.css"; 
import { getRequestNpi, smallLettersStr } from "../../utils/reusableFunctions";
import TableHeader from "../../components/TableHeader";
import { PayersDropdown, fetchPatientDetails, handleUserEligibilitySearch } from "./EligibilityFunctions";
import { ArrowDropDownIcon } from "@mui/x-date-pickers/icons";
import { sharedStyles } from "../../sharedStyles";

const DentalSearch = () => {
    const navigate = useNavigate();
    const { formWrapper } = styles;
    const [payersData, setPayersData] = useState([]);
    const [selectedPatient, setSelectedPatient] = useState(null);
    const [maxDate] = useState(() => {
        const date = new Date();
        date.setDate(date.getDate() - 14);
        return date;
    });
    //Default npi from user logged in : from browsers storage 
    const currentNpi = getRequestNpi()
    const [formDetails, setformDetails] = useState({
        ...defaultFormDetails,
        NPI: currentNpi
    });
    const [isLoading, setIsLoading] = useState(false)
    const [userDetails, setUserDetails] = useState(defaultEligibilityUserDetails());
    const [patientSelected, setPatientSelected] = useState('')
    const [payersLoader, setPayersLoader] = useState(false)
  
    const DropDownHandle = (event, newValue) => {
        setformDetails((prevDetails) => ({
            ...prevDetails,
            PatientInsurancePayer: newValue
        }));
    };

    const handleChange = (event, value) => {
        if (event && event.target) {
            const { name, value: targetValue } = event.target;
            if (name === "PatientFirstName") {
                setUserDetails(prevState => ({
                    ...prevState,
                    subscriber: {
                        ...prevState.subscriber,
                        FirstName: targetValue
                    }
                }));

                setformDetails((prevDetails) => ({
                    ...prevDetails,
                    PatientFirstName: targetValue,
                }));
            }
            else if (name === "LastName") {
                setUserDetails(prevState => ({
                    ...prevState,
                    subscriber: {
                        ...prevState.subscriber,
                        lastName: targetValue
                    }
                }));

                setformDetails((prevDetails) => ({
                    ...prevDetails,
                    LastName: targetValue,
                }));
            }
            else if (name === "NPI") {
                setformDetails((prevDetails) => ({
                    ...prevDetails,
                    NPI: targetValue,
                }));
            }
            else if (name === "dateofservice") {
                setformDetails((prevDetails) => ({
                    ...prevDetails,
                    dateofservice: ymdDateFormat(dayjs(targetValue)),
                }));
            }
            else {
                setformDetails((prevDetails) => ({
                    ...prevDetails,
                    [name]: targetValue,
                }));
            }
        }
    };
    const searchbtn = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        try {
            if (!selectedPatient || selectedPatient?.PatientFirstName !== formDetails?.PatientFirstName || selectedPatient?.LastName !== formDetails?.LastName) {
                let npiInfo = getLocalData('selectedNpi');
                const patientdata = await get_GetAllPatientsDataAsync(npiInfo?.id);
                let specificData = null;
                if (patientdata?.data?.length > 0) {
                    patientdata.data.forEach(patient_record => {
                        if (smallLettersStr(formDetails?.PatientFirstName) === smallLettersStr(patient_record?.firstName) &&
                        smallLettersStr(formDetails?.LastName) === smallLettersStr(patient_record?.lastName)) {
                            specificData = patient_record;
                            return;
                        }
                    });
                }

                if (specificData) {
                    setformDetails(prevState => ({
                        ...prevState,
                        memberId: specificData?.memberId ?? "",
                        dob: specificData?.dob ?? ""
                    }));
                }
            }

            const npidatabynumber = await get_NpiDataByNpiNumber(formDetails?.NPI);
            const payerNameCodePair = {};
            const payerCodes1 = [];
            await Promise.all((formDetails?.PatientInsurancePayer || [])?.map(async (i) => {
                const payerInfo = await get_GetPayerCodeByName(i);
                if (payerInfo?.[0]) {
                    payerNameCodePair[payerInfo[0]?.payerName] = payerInfo[0]?.payerCode;
                    payerCodes1.push(payerInfo[0]?.payerCode);
                }
            }));

            setformDetails((prevDetails) => ({
                ...prevDetails,
                payerCodes: payerCodes1 ?? [],
            }));

            const updatedDetails = {
                ...userDetails,
                IncludeHtmlResponse: "True",
                Location: "VA",
                PracticeTypeCode: "0",
                ProviderLastName: npidatabynumber?.npiData?.providerLastName ?? '',
                ProviderNPI: formDetails?.NPI?.toString() ?? '',
                dependent: "null",
                doS_StartDate: dayjs(formDetails?.dateofservice).subtract(5, 'day').format('MM/DD/YYYY'),
                doS_EndDate: dayjs(formDetails?.dateofservice).add(5, 'day').format('MM/DD/YYYY'),
                isSubscriberPatient: "False",
                payerCode: payerCodes1[0] ?? '',
                payerCodes: payerCodes1 ?? [],
                payerName: formDetails?.PatientInsurancePayer?.[0] ?? '',
                provider: {
                    ...userDetails.provider,
                    firstName: npidatabynumber?.npiData?.providerFirstName ?? '',
                    middleName: " ",
                    lastName: npidatabynumber?.npiData?.providerLastName ?? '',
                    npi: formDetails?.NPI?.toString() ?? '',
                    TaxId: "12345"
                },
                referenceId: "0",
                subscriber: {
                    ...userDetails.subscriber,
                    memberID: formDetails?.memberId ?? '',
                    FirstName: formDetails?.PatientFirstName ?? '',
                    firstName: formDetails?.PatientFirstName ?? '',
                    lastName: formDetails?.LastName ?? '',
                    dob: formDetails?.dob ?? '',
                },
            };
            setUserDetails(updatedDetails);
            if (!formDetails?.PatientFirstName || !formDetails?.LastName  || !formDetails?.dateofservice || !formDetails?.NPI) {
                toast.error("Enter all the required fields");
            } else {
              handleUserEligibilitySearch(updatedDetails,navigate)
            }
        } catch (error) {
            toast.error('Error fetching data');
        } finally {
            setIsLoading(false);
        }
    };

   

    useEffect(() => {
        PayersDropdown(setPayersData,setPayersLoader);
    }, []);

    useEffect(()=>{
      if (patientSelected?.id) {
          fetchPatientDetails(patientSelected,setSelectedPatient,setformDetails,formDetails)
        }
      else {
          setUserDetails(defaultEligibilityUserDetails())
          setformDetails({
            ...defaultFormDetails,
            NPI: getRequestNpi()
        });
      }
  },[patientSelected])


  return (
    <Box>
      <Box className={customStyles.searchContainer}>
        <TableHeader setFilterPatient={setPatientSelected} idNeeded/>
      </Box>
      <Box className={customStyles.marginTop2em}>
        <Box className={formWrapper}>
          <form>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} lg={6}>
                <FormControl className={customStyles.firstNameField} fullWidth>
                  <TextField
                    label="First Name"
                    variant="outlined"
                    name="PatientFirstName"
                    onChange={handleChange}
                    color="error"
                    required
                    inputProps={{
                      maxLength: 20,
                      className: 'capitalize-text',
                    }}
                    value={formDetails?.PatientFirstName ?? ""}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} lg={6}>
                <FormControl className={customStyles.lastNameField} fullWidth>
                  <TextField
                    label="Last Name"
                    variant="outlined"
                    name="LastName"
                    onChange={handleChange}
                    color="error"
                    required
                    inputProps={{
                      maxLength: 20,
                      className: 'capitalize-text',
                    }}
                    value={formDetails?.LastName ?? ""}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} lg={6}>
                <FormControl className={customStyles.insuranceField} fullWidth>
                  <Autocomplete
                    disabled={payersLoader}
                    multiple
                    options={payersData}
                    name="InsurancePayer"
                    getOptionLabel={(option) => option}
                    value={
                      formDetails.PatientInsurancePayer
                        ? formDetails.PatientInsurancePayer
                        : null
                    }
                    onChange={DropDownHandle}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Insurance Name"
                        variant="outlined"
                        color="success"
                        helperText="Select insurance names"
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <InputAdornment position="end">
                             { payersLoader && <CircularProgress color="success" size={20} sx={sharedStyles?.mr1}/>}
                             <ArrowDropDownIcon sx={sharedStyles?.mr_3}/>
                            </InputAdornment>
                          ),
                        }}
                      />
                    )}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} lg={6}>
                <FormControl className={customStyles.dateOfServiceField} fullWidth>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      label="Date of service"
                      onChange={(val) =>
                        handleChange({
                          target: {
                            name: "dateofservice",
                            value: dayjs(new Date(val)?.toISOString()),
                          },
                        })
                      }
                      name="dateofservice"
                      maxDate={dayjs(maxDate)}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          color="error"
                          required
                          inputProps={{
                            ...params.inputProps,
                            maxLength: 20,
                          }}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} lg={6}>
                <FormControl className={customStyles.npiField} fullWidth>
                  <TextField
                    label="NPI"
                    variant="outlined"
                    name="NPI"
                    color="primary"
                    onChange={handleChange}
                    value={formDetails?.NPI ?? currentNpi}
                  />
                </FormControl>
              </Grid>
              <Grid
                item
                lg={6}
                className={`${customStyles.textAlignLeft} ${customStyles.paddingTop140px}`}
              >
                <Button
                  label="Search"
                  variant="contained"
                  color="success"
                  className={customStyles.searchButton}
                  onClick={searchbtn}
                  disabled={isLoading}
                >
                  {isLoading ? (
                    <CircularProgress
                      className={customStyles.circularProgress}
                    />
                  ) : (
                    "Search"
                  )}
                </Button>
              </Grid>
            </Grid>
          </form>
        </Box>
      </Box>
    </Box>
  );
};
export default DentalSearch;