import { colors } from "../../utils/colors";

export const styles = {
    patientContainer: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "flex-start",
        minHeight: "100vh",
        textAlign: "center",
        backgroundColor: colors?.mattBlack,
        padding: "7rem",
    },
    patientImage: {
        width: "150px",
        marginBottom: "20px"
    },
    patientHeading: {
        mb: 2,
        color: colors?.white,
        fontWeight: "600",
        fontSize: { xs: "1.75rem", sm: "2.25rem" }
    },
    patientSubHeading: {
        mb: 6,
        color: colors?.gray,
        fontWeight: "400",
        maxWidth: "600px"
    },
    patientCardsContainer: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        gap: 4,
        width: "100%",
        maxWidth: "1200px",
        mb: 6,
        flexDirection: { xs: "column", md: "row" }
    },
    patientCard: {
        p: 4,
        borderRadius: "16px",
        backgroundColor: colors?.lightMattBlack,
        border: `1px solid ${colors?.ligthGrayMatt}`,
        boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.25)",
        transition: "transform 0.2s, box-shadow 0.2s",
        "&:hover": {
            transform: "translateY(-4px)",
            boxShadow: "0px 8px 20px rgba(0, 0, 0, 0.35)"
        },
        width: "375px",
    },
    patientCardHeading: { 
        mb: 2, 
        color: colors?.white, 
        fontWeight: "600"
    },
    patientCardButton: {
        px: 4,
        py: 1.5,
        fontSize: "16px",
        textTransform: "none",
        borderRadius: "8px",
        backgroundColor: colors?.themeLightBlue,
        "&:hover": {
            backgroundColor: colors?.blue
        }
    },
    patientCardListing:{ 
        mb: 3, 
        color: colors?.white, 
        textAlign: "left" 
    },
    userLoginButton:{
        px: 4,
        py: 1.5,
        fontSize: "16px",
        textTransform: "none",
        borderRadius: "8px",
    },
    userLogo: { 
        width: "150px", 
        marginBottom: "20px"
    },
    userMainPageContainer: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
        textAlign: "center",
        backgroundColor: "white",
    }

}