import { Button } from '@mui/material'
import React from 'react'

const ConditionalButtonElseTextRender = ({ text, buttonText,onClick }) => {
    return (
        text ?
            <span>
                {text}
            </span>
            :
            <Button variant="contained" size='small' color="success"
            onClick={onClick}
            >
                {buttonText}
            </Button>
    )
}

export default ConditionalButtonElseTextRender