import React, { useEffect, useState } from 'react'
import { TREATMENT_INFO_TEXT } from '../../../utils/staticData'
import DisqualifiedModal from '../DisqualifiedModal';

const GlpConsent = ({setUserConsent,userConsent}) => {
    // states
    const [glp1Consent, setGlp1Consent] = useState(""); 
    const [showModal, setShowModal] = useState(false)

    // functions
     const handleGlp1ConsentChange = (value) => {
        setGlp1Consent(value);
        if(value === 'yes'){
            setUserConsent({...userConsent,glp:true})
        }
        else {
            setShowModal(true)
            setUserConsent({...userConsent,glp:false})
        }
    };

    // use Effects
    useEffect(() => {
        const { glp } = userConsent
        glp ? setGlp1Consent('yes') : setGlp1Consent('no')
    }, [userConsent])

  return (
    <div className="consent-section">
                <h3>Consent (GLP-1 and GLP-1/GIP)</h3>
                {TREATMENT_INFO_TEXT}
                <label className="label-form">
                    <input className="form-checkbox-radio"
                        type="radio"
                        name="glp1Consent"
                        value="yes"
                        checked={glp1Consent === "yes"}
                        onChange={(e) => handleGlp1ConsentChange(e.target.value)}
                    />
                    I consent
                </label>
                <label className="label-form">
                    <input className="form-checkbox-radio"
                        type="radio"
                        name="glp1Consent"
                        value="no"
                        checked={glp1Consent === "no"}
                        onChange={(e) => handleGlp1ConsentChange(e.target.value)}
                    />
                    I have read the above information and I do not wish to continue
                </label>
                <DisqualifiedModal
            open={showModal}
            handleClose={()=>setShowModal(false)}
            onOk={()=>{
                setUserConsent({...userConsent,glp:true})
                setGlp1Consent('yes')
                setShowModal(false)

            }}
            onCancel={()=>{
                setUserConsent({...userConsent,glp:false})
                setShowModal(false)
            }}
            />
            
            
            </div>
  )
}

export default GlpConsent