import { getNpiDataByNpiNumber } from '../../services/NpiFinderInquiry/getNpiNumberById';
import { Box, Button, Grid, Paper, Typography } from '@mui/material';
import PageLoader from '../../components/loader/PageLoader';
import { Link, useLocation } from 'react-router-dom'
import React, { useEffect, useState } from 'react'
import { styles } from './styles';
import BoxComponent from '../../components/BoxComponent';
import GreenButton from '../../components/claims/GreenButton';
import { settingMailingAddress, settingNpiFields, settingPrimaryPracticeAddressFields, settingSecondaryAddress } from './dataFormat';
import { sharedStyles } from '../../sharedStyles';



const NpiDetail = () => {
  const location = useLocation();
  const { details } = location.state;
  const [npiDetail, setNpiDetail] = useState({});
  const [personalDetails, setPersonalDetails] = useState([]);
  const [primaryPracticeAddress, setPrimaryPracticeAddress] = useState([]);
  const [secondaryAddress, settingSecondaryAddress] = useState([])
  const [mailingAddress, setMailingAddress] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (npiDetail?.personalDetails) {
      setPersonalDetails(settingNpiFields(npiDetail?.personalDetails))
    }

    if (npiDetail?.primaryPracticeAddress) {
      setPrimaryPracticeAddress(settingPrimaryPracticeAddressFields(npiDetail?.primaryPracticeAddress));
    }

    if (npiDetail?.mailingAddress) {
      setMailingAddress(settingMailingAddress(npiDetail?.mailingAddress));
    }

    if (npiDetail?.secondaryPracticeAddresses) {
      settingSecondaryAddress(npiDetail?.secondaryPracticeAddresses)
    }
  }, [npiDetail])




  const fetchData = async () => {
    setIsLoading(true);
    try {
      let response = await getNpiDataByNpiNumber(details);
      let data = response?.data?.pG_NpiData;
      if (data) {
        const { providerFirstName, providerLastName, npi, gender, soleProprietor, status, mailingAddress, primaryPracticeAddress, taxonomy, secondaryPracticeAddresses, npiType,address } = data;
        const personalDetails =
        {
          providerFirstName,
          providerLastName,
          npi,
          npiType,
          gender,
          soleProprietor,
          status
        }
        const newObj = { 
          personalDetails, 
          primaryPracticeAddress:address?.find((elem)=>elem?.type === 'PrimaryPractice'), 
          taxonomy, 
          mailingAddress:address?.find((elem)=>elem?.type === 'Mailing'), 
          secondaryPracticeAddresses
         };
        setNpiDetail(newObj)
      }
    } catch (error) {
      console.error(error)
    } finally {
      setIsLoading(false)
    }
  }
  
  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Paper sx={styles?.npiDetailContainer} elevation={3}>
      <Box sx={styles?.backButtonContainer}>
        <Typography variant='h5' fontWeight="bold">
          NPI Detail for ({npiDetail?.personalDetails?.providerFirstName} {npiDetail?.personalDetails?.providerLastName})
        </Typography>

      </Box>
      {
        isLoading ?
          (
            <PageLoader />
          )
          :

          <Grid container spacing={10}>
            <Grid item xs={12} sm={10} lg={6} xl={4}>
              <Box sx={styles?.cardStyles}>
                <div style={styles?.innerCardHeader}>
                  <Typography variant='h6'>Personal Details</Typography>
                </div>
                {personalDetails?.map(({ label, value }) => (
                  <BoxComponent
                    key={value}
                    isLoading={isLoading}
                    label={`${label}`}
                    value={value || '-----'}
                  />
                ))}
              </Box>
            </Grid>
            <Grid item xs={12} sm={10} lg={6} xl={4}>
              <Box sx={styles?.cardStyles}>
                <div style={styles?.innerCardHeader}>
                  <Typography variant='h6'>Mailing Information</Typography>
                </div>
                <React.Fragment>
                  {mailingAddress?.map(({ label, value }) => (
                    <BoxComponent
                      key={value}
                      isLoading={isLoading}
                      label={label}
                      value={value || '----'}
                    />
                  ))}
                </React.Fragment>
              </Box>
            </Grid>
            <Grid item xs={12} sm={10} lg={6} xl={4}>
              <Box sx={styles?.cardStyles}>
                <div style={styles?.innerCardHeader}>
                  <Typography variant='h6'>Primary Practice Address</Typography>
                </div>
                {primaryPracticeAddress?.map(({ label, value }) => (
                  <BoxComponent
                    key={value}
                    isLoading={isLoading}
                    label={label}
                    value={value || '----'}
                  />
                ))}
              </Box>
            </Grid>
            <Grid item xs={12} sm={10} lg={6} xl={4}>
              <Box sx={styles?.cardStyles}>
                <div style={styles?.innerCardHeader}>
                  <Typography variant='h6'> Secondary Practice Address</Typography>
                </div>
                {
                  !npiDetail?.secondaryPracticeAddresses || npiDetail?.secondaryPracticeAddresses?.length === 0 ?
                    "No Data Found"
                    :
                    secondaryAddress?.map(({ city, country, fax, phone, state, street1, street2, zip }, index) => (
                      <>
                        <BoxComponent
                          key={index}
                          isLoading={isLoading}
                          label={'City: '}
                          value={city || '----'}
                        />
                        <BoxComponent
                          key={index}
                          isLoading={isLoading}
                          label={'Country: '}
                          value={country || '----'}
                        />
                        <BoxComponent
                          key={index}
                          isLoading={isLoading}
                          label={'Fax: '}
                          value={fax || '----'}
                        />
                        <BoxComponent
                          key={index}
                          isLoading={isLoading}
                          label={'Phone: '}
                          value={phone || '----'}
                        />
                        <BoxComponent
                          key={index}
                          isLoading={isLoading}
                          label={'State: '}
                          value={state || '----'}
                        />
                        <BoxComponent
                          key={index}
                          isLoading={isLoading}
                          label={'Street1: '}
                          value={street1 || '----'}
                        />
                        <BoxComponent
                          key={index}
                          isLoading={isLoading}
                          label={'Street2: '}
                          value={street2 || '----'}
                        />
                        <BoxComponent
                          key={index}
                          isLoading={isLoading}
                          label={'Zip: '}
                          value={zip || '----'}
                        />
                      </>
                    ))
                }
              </Box>
            </Grid>
            <Grid item xs={12} sm={10} lg={6} xl={4}>
              <Box sx={styles?.cardStyles}>
                <div style={styles?.innerCardHeader}>
                  <Typography variant='h6'>Taxonomy</Typography>
                </div>
                {
                  !npiDetail?.taxonomy?.length || !npiDetail?.taxonomy ? 
                  "No Data Found"
                  :
                  npiDetail?.taxonomy?.map((item, index) => (
                    <>
                      <div key={index}>
                        <BoxComponent
                          isLoading={isLoading}
                          label={"Primay Taxonomy:"}
                          value={item?.primayTaxonomy || '----'}
                        />
                        <BoxComponent
                          isLoading={isLoading}
                          label={"Selected Taxonomy:"}
                          value={item?.selectedTaxonomy || '----'}
                        />
                        <BoxComponent
                          isLoading={isLoading}
                          label={"State:"}
                          value={item?.state || '----'}
                        />
                        <BoxComponent
                          isLoading={isLoading}
                          label={"Lisence Number:"}
                          value={item?.licenseNumber || '----'}
                        />
                      </div>
                      <hr />
                    </>
                  ))
                }
              </Box>
            </Grid>
          </Grid>
      }
      <Button to={'/userSettings'} state={{ tab: 2 }} LinkComponent={Link} sx={sharedStyles?.whiteBtn}>
        Back
      </Button>
    </Paper>
  )
}

export default NpiDetail;
