import { Box, Grid, Typography } from "@mui/material";
import { sharedStyles } from "../../../sharedStyles";

const VisitBasicInfo = ({ visit }) => {
  const contactInfo = [
    { label: "Email", value: visit?.email },
    { label: "Phone", value: visit?.phone },
    {
      label: "Address",
      value: `${visit?.address || ""}, ${visit?.city || ""}, ${visit?.state || ""} ${visit?.zipCode || ""}`.trim(),
    },
  ];

  const medicalInfo = [
    { label: "Height", value: visit?.visitType === 'weightlossfollowup' ? visit?.q3A : visit?.q4A },
    { label: "Weight", value: `${visit?.visitType === 'weightlossfollowup' ? visit?.q4A :visit?.q5A || ""} lbs`.trim() },
    { label: "BMI", value: visit?.visitType === 'weightlossfollowup' ? visit?.q5A : visit?.q6A },
    { label: "Allergies", value: visit?.knownAllergies },
    { label: "Current Medications", value: visit?.selfReportedMeds },
    { label: "Medical Conditions", value: visit?.currentOrPastMedicalConditions },
  ];

  return (
    <Box sx={sharedStyles?.mt5px}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Typography variant="subtitle2" color="text.secondary">
            Contact Information
          </Typography>
          {contactInfo.map((info, index) => (
            <Typography key={index}>
              {info?.label}: {info?.value || "Not Provided"}
            </Typography>
          ))}
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography variant="subtitle2" color="text.secondary">
            Medical Information
          </Typography>
          {medicalInfo.map((info, index) => (
            <Typography key={index}>
              {info?.label}: {info?.value || "Not Provided"}
            </Typography>
          ))}
        </Grid>
      </Grid>
    </Box>
  );
};

export default VisitBasicInfo;
