import { Autocomplete, Box, CircularProgress, FormControl, Grid, TextField } from '@mui/material'
import React, { useEffect, useState } from 'react'
import TableHeader from '../../components/TableHeader'
import { get_payersdata } from '../../services/PayerService';
import toast from 'react-hot-toast';
import { sharedStyles } from '../../sharedStyles';
import { basicPaPayerIds, defaultBasicPaDetailFields } from '../../utils/staticData';
import BasicPayerIdForm from '../../components/eligibility/paForms/BasicPayerIdForm';
import { get_GetPatientDataById } from '../../services/addPatient';
import HorizontalBox from '../../components/shared/HorizontalBox'
import dayjs from 'dayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

const PaForm = () => {
    // states
    const [patientSelected, setPatientSelected] = useState(null);
    const [allPayers, setAllPayers] = useState([])
    const [loaders, setLoaders] = useState({ payers: false,patientDetails:false })
    const [selectedPayer, setSelectedPayer] = useState(null)
    const [patientBasicDetails, setPatientBasicDetails] = useState(defaultBasicPaDetailFields)
    const [formType, setFormType] = useState(null)


    // states to be mapped
    const [patientBasicDetailsFields] = useState([
        { label: "First Name", name: "firstName", type: "text" },
        { label: "Middle Name", name: "middleName", type: "text"},
        { label: "Last Name", name: "lastName", type: "text" },
        { label: "DOB", name: "dob", type: "date" },
        { label: "Member ID", name: "memberId", type: "text" },
        { label: "DOS Start", name: "dosStart", type: "date" },
        { label: "DOS End", name: "dosEnd", type: "date" },
    
      ])

    // useEffects
    useEffect(() => {
        fetchPayersData()
    }, [])

    useEffect(()=>{
        patientSelected?.id && getPatientDetails()
    },[patientSelected])

    // functions

    // fetching payers data
    const fetchPayersData = async () => {
        setLoaders({ ...loaders, payers: true })
        try {
            const payersData = await get_payersdata()
            if (payersData?.data?.length) {
                setAllPayers(payersData?.data?.map(elem => {
                    return (JSON.parse(elem?.priorAuthorization)?.configurations?.[0])
                }))
            }
            else { toast.error('No data for payer found, please try again later') }
        }
        catch (e) {
            console.log(e)
        }
        finally {
            setLoaders({ ...loaders, payers: false })
        }
    }

    // set selected payer
    const handleSelectedPayer = (event, newValue) => {
        if(newValue){
        const matchedVal = allPayers?.find(e => e?.payerName === newValue)
        setSelectedPayer(matchedVal)
        if(basicPaPayerIds?.find(e => e === matchedVal?.payerName)){
            setFormType('basic')
        }
        else {
            setFormType(null)
        }
        }
        else {
            removePayerInfo()
        }
      };

    // handling basic details of user being changed
    const handleBasicDetailChange = (e) => {
        const { name, value } = e.target;
        setPatientBasicDetails((prevDetails) => ({
          ...prevDetails,
          [name]: value, 
        }));
      };

    // getting selected patient's complete detail
    const getPatientDetails = async()=>{
        if(patientSelected?.id){
            removePayerInfo()
           try{
            setLoaders({...loaders,patientDetails:true})
            const {data:{firstName,middleName,lastName,dob,memberId,}} = await get_GetPatientDataById(patientSelected?.id)
               setPatientBasicDetails({
                   ...patientBasicDetails,
                   firstName,
                   middleName,
                   lastName,
                   dob,
                   memberId,
               })
            setSelectedPayer({payerName:'Cigna'})
            setFormType('basic')
           }
           catch(e){
            console.log(e)
           }
           finally{
            setLoaders({...loaders,patientDetails:false})
           }
        }
    }

    // remove payer info
    const removePayerInfo = () => {
            setSelectedPayer(null)
            setPatientBasicDetails(defaultBasicPaDetailFields)
            setFormType(null)
    }

    return (
        <Box>
            <TableHeader setFilterPatient={setPatientSelected} idNeeded handleCrossValue={removePayerInfo}/>
            <Box>
                <form>
                    <Grid container spacing={2}>
                        {/* first row for insurance */}
                        <Grid item xs={12}>
                            <FormControl sx={{ marginBottom: "2em" }} fullWidth>
                                <Autocomplete
                                    loading={loaders?.payers}
                                    disabled={!allPayers?.length}
                                    options={allPayers?.map(e => e?.payerName)}
                                    getOptionLabel={(option) => option}
                                    value={selectedPayer ? selectedPayer.payerName : null} 
                                    onChange={handleSelectedPayer}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Insurance Name"
                                            variant="outlined"
                                            color="success"
                                            value={selectedPayer ? selectedPayer.payerName : null} 
                                        />
                                    )}
                                />
                            </FormControl>
                        </Grid>

                        
                       {
                       loaders?.patientDetails ? 
                       <HorizontalBox sx={{...sharedStyles?.fullWidth,...sharedStyles?.justifyCenter}} > 
                        <CircularProgress color='success' size={20}/>
                       </HorizontalBox> :
                       selectedPayer?.payerName ?
                            <>
                                {/* second row for names */}
                            {patientBasicDetailsFields?.map((field, index) => {
                                return (
                                    <Grid item xs={12} sm={4} lg={index > 2 ? 3 : 4} key={index}>
                                        <FormControl fullWidth sx={{ mb: 2 }}>
                                            {field.type === "date" ? (
                                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                    <DatePicker
                                                    label={field.label}
                                                    value={patientBasicDetails[field.name] ? dayjs(patientBasicDetails[field.name]) : null}
                                                    onChange={(newValue) => {
                                                        setPatientBasicDetails((prev) => ({
                                                            ...prev,
                                                            [field.name]: newValue ? newValue.format("YYYY-MM-DD") : "",
                                                        }));
                                                    }}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            placeholder="mm/dd/yyyy"
                                                            InputLabelProps={{ shrink: true }}
                                                        />
                                                    )}
                                                />
                                                </LocalizationProvider>
                                            ) : (
                                                <TextField
                                                    label={field.label}
                                                    variant="outlined"
                                                    name={field.name}
                                                    type={field.type}
                                                    value={patientBasicDetails[field.name]}
                                                    onChange={handleBasicDetailChange}
                                                />
                                            )}
                                        </FormControl>
                                    </Grid>
                                )
                            })}
                            </>
                             :
                            null
                       } 
                       
                    </Grid>
                    {formType === 'basic' ? 
                    <BasicPayerIdForm patientBasicDetails={{...patientBasicDetails,insuranceName:selectedPayer?.payerName}}/> 
                    :
                    null 
                    }
                </form>
            </Box>
        </Box>
    )
}

export default PaForm