import React, { useEffect, useState } from 'react'
import { getBelugaSurveyInfo, getPatientVisitTypes } from '../Services/LandingPage'
import { getLocalData } from '../../utils/localStorageFunctions'
import { useAuth0 } from '@auth0/auth0-react'
import { useNavigate } from 'react-router-dom'
import PageLoader from '../../components/loader/PageLoader'
import CompletedFormsModal from '../../components/modal/CompletedFormsModal'

const PatientLandingPage = () => {
  // constants
  const {user:{email}} = useAuth0()
  const navigate = useNavigate()

  // states
  const [showModal, setShowModal] = useState(false)

  // functions
  const checkPatientVisits = async() => {
   const allVisitTypes = await getBelugaSurveyInfo(email)
   if (!allVisitTypes){
    navigate('/questionnaire')
   }
   if (allVisitTypes?.length === 1) {
    navigate('/questionnaire-follow-up', {state:{preFilledData:allVisitTypes[0]}})
   }
   if (allVisitTypes?.length === 2) {
    setShowModal(true)
   }
  }

  // use Effects
    useEffect(()=>{
    email &&  checkPatientVisits()
    },[email])

    // main return
  return (
    <>
   {!showModal && <PageLoader/> }
    <CompletedFormsModal
    open={showModal}
    showHistoryButton
    />
    </>
  )
}

export default PatientLandingPage