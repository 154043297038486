import { get_GetAllPatientsDataAsync } from "../services/addPatient"
import { get_GetAllClaimsStatusData } from "../services/ClaimService"
import { get_GetAllInsuranceDiscoveryDataPaginated } from "../services/registration"
import { getLocalData, setLocalData } from "./localStorageFunctions"
import { areArraysEqual } from "./reusableFunctions"

export const getAllPatientsIds = async() => {
    const patientIds = getLocalData('P-ids')
    if(patientIds){
        return patientIds
    }
    else {
     const patientsData = await get_GetAllPatientsDataAsync()
     if(patientsData?.data?.length){
        setLocalData('P-ids',patientsData?.data?.map(({id})=>id))
        return(patientsData?.patientDataList?.map(({id})=>id))
    }
    else console.log('no patients list found')
    }
}

export const getAllDiscoveryIds = async() => {
    const discoveryIds = getLocalData('Discovery-ids')
    if(discoveryIds){
        return discoveryIds
    }
    else {
     const discoveryData = await get_GetAllInsuranceDiscoveryDataPaginated()
     if(discoveryData?.insuranceDiscoveryDtosList?.length){
        const data = [...new Set(discoveryData?.insuranceDiscoveryDtosList?.map(({patientData:{id}})=>id))]
        setLocalData('Discovery-ids',data)
        return(data)
    }
    else console.log('no discovery ids list found')
    }
}

export const setAllDiscoveryIds = async(data) => {
    const discoveryIds = getLocalData('Discovery-ids')
    if(!areArraysEqual(data,discoveryIds)){
        setLocalData('Discovery-ids',data)
    }
    
}

export const setAllPatientsIds = async(data) => {
    const patietnIds = getLocalData('P-ids')
    if(!areArraysEqual(data,patietnIds)){
        setLocalData('P-ids',data)
    }
}

export const getAllClaimsIds = async() => {
    const cIds = getLocalData('C-ids')
    if(cIds){
        return cIds
    }
    else {
     const claimsData = await get_GetAllClaimsStatusData()
     if(claimsData?.claimsStatusSimplifiedDtos?.length){
        setLocalData('C-ids',claimsData?.claimsStatusSimplifiedDtos?.map(({patientId})=>patientId))
        return(claimsData?.claimsStatusSimplifiedDtos?.map(({patientId})=>patientId))
    }
    else console.log('no patients list found')
    }

}

export const setAllClaimsIds = async(data) => {
    const claimIds = getLocalData('C-ids')
    if(!areArraysEqual(data,claimIds)){
        setLocalData('C-ids',data)
    }
}