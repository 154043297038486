import React, { useEffect } from 'react'
import UseCdCodes from '../../custom-hooks/UseCdCodes'
import CdTable from '../../components/table/CdTable'

const CdSearch = () => {
  const { cdCodes } = UseCdCodes()
  const { data } = cdCodes


  return (
    <CdTable data={data} />
  )
}

export default CdSearch
