import { AgGridReact } from 'ag-grid-react';
import "ag-grid-community/styles/ag-grid.css"; 
import "ag-grid-community/styles/ag-theme-quartz.css";
import React, { useEffect, useState } from 'react';
import { Button, } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { checkEligibilityDetails, checkEligibilityDetailsFromDiscovery } from '../../services/registration';
import { capitalizedFirstLetter } from '../../utils/reusableFunctions';
import PageLoader from '../../components/loader/PageLoader';
import { dayJsMdyFormat } from '../../utils/DateFunctions';

const EligibilityClaimResultGrid = ({ eligibilitycheckData }) => {
  const [rowData, setRowData] = useState([]);
  const [isLoading, setIsLoading] = useState(false)
  let navigate = useNavigate();
  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true)
        let data;
        let flattenedRows = [];
          const requestid =  eligibilitycheckData.Requestid;
          const patientid =  eligibilitycheckData.ID;
          const transactionDate = dayJsMdyFormat(eligibilitycheckData.Transactiondate);
          data = await  checkEligibilityDetailsFromDiscovery(requestid, patientid, transactionDate);
            flattenedRows = [{
              ID: data?.eligibilityDto?.patientData?.id,
              Date: data?.eligibilityDto?.transactionDate ? dayJsMdyFormat(data?.eligibilityDto?.transactionDate) : 'N/A',
              Patient: `${capitalizedFirstLetter(data?.eligibilityDto?.patientData?.firstName)} ${capitalizedFirstLetter(data?.eligibilityDto?.patientData?.lastName)}`,
              Phone: data?.eligibilityDto?.patientData?.phone || 'N/A',
              DOB: dayJsMdyFormat(data?.eligibilityDto?.patientData?.dateOfBirth) || 'N/A',
              Insurance: data?.eligibilityDto?.payerName || 'N/A',
              Copay: data?.eligibilityDto?.additionalInfo || 'N/A',
              Status: data?.eligibilityDto?.planCoverageSummary ? data?.eligibilityDto?.planCoverageSummary?.status : 'N/A',
              requestID: data?.eligibilityDto?.requestID || 'N/A',
              transactionDate: data?.eligibilityDto?.transactionDate ? formatTransactionDate(data?.eligibilityDto?.transactionDate) : 'N/A',
            }];
        setRowData(flattenedRows);
        setIsLoading(false)
      } catch (error) {
        setIsLoading(false)
      }
    };
  
    fetchData(); 
  }, []);
  


  const formatTransactionDate = (dateString) => {
    const date = new Date(dateString);
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    const year = date.getFullYear();
    return `${month}-${day}-${year}`;
  };

  const statusCellRenderer = (params) => {
    const statusValue = params.value;
    const statusStyle = {
      backgroundColor: '#F8FFF6', 
      color: '#229A16', 
    };
    return <span style={statusStyle}>{statusValue}</span>;
  };

  const EligibilityDetailsButton  = (params) => {
    return (
      <Button
        className='primary'
        style={{ backgroundColor: 'green', border: 'none', color: 'white', fontSize: 'small' }}
        onClick={() => handleeligibilityButtonClick(params.data)}
      >
        View Eligibility Details
      </Button>
    );
  };

  const handleeligibilityButtonClick = (data) => {
    navigate({
      pathname: '/claimEligibilityDetails',
      search: `?rowData=${encodeURIComponent(JSON.stringify(data))}`,
    });
  };

  const columnDefs = () => {
      return [
        // { headerName: 'ID#', field: 'ID' },
        { headerName: 'Date', field: 'Date', sortable: true, filter: true,width:201 },
        { headerName: 'Patient', field: 'Patient', filter: true },
        { headerName: 'Phone', field: 'Phone', filter: true,width:123  },
        { headerName: 'Date of Birth', field: 'DOB', width: 120 },
        { headerName: 'Insurance Carrier', field: 'Insurance', width: 175, filter: true },
        { headerName: 'Status', field: 'Status', cellRenderer: statusCellRenderer, filter: true ,width:100},
        { headerName: 'Details', field: 'Details', width: 150, filter: true, cellRenderer: EligibilityDetailsButton,flex:1,minWidth:143 },
      ];
  }

  const gridOptions = {
    pagination: true,
    paginationPageSize: 10, 
    domLayout: 'paginationPrevNext,paginationPageSize,paginationFirstLast',
    overlayNoRowsTemplate: `
    <span class="ag-overlay-loading-center">
        Loading
    </span>
`
  };

  return (
    <div className="ag-theme-quartz"
      style={{ height: 500 }}  >

    {isLoading ?
    <PageLoader/>:
    <AgGridReact
        columnDefs={columnDefs()}
        rowData={rowData}
        defaultColDef={{ 
          // width:236
          flex:1,
          minWidth:170
         }}
        gridOptions={gridOptions}
      />
       }
    </div>
  );
};

export default EligibilityClaimResultGrid;