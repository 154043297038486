import React, { useState } from 'react'
import { basicPayerIdFormDefaultData } from '../../../utils/staticData'
import { Box, CircularProgress, FormControl, Grid, TextField } from '@mui/material'
import { sharedStyles } from '../../../sharedStyles'
import GreenButton from '../../claims/GreenButton'
import { filterImportantKeys } from '../../../utils/reusableFunctions'
import toast from 'react-hot-toast'
import { submitPaForm } from '../../../services/PayerService'
import { ymdDateFormat } from '../../../utils/DateFunctions'

const BasicPayerIdForm = ({patientBasicDetails}) => {
    // states
    const [payerInfo,serPayerInfo] = useState(basicPayerIdFormDefaultData)
    const [loader, setLoader] = useState({form:false})

    // states to be mapped
    const [patientInfoFields] = useState([
        { label: "Provider First Name", name: "providerFirstName" },
        { label: "Provider Last Name", name: "providerLastName"},
        { label: "Provider NPI", name: "providerNpi", disabled:true},
        { label: "Provider Tax Id", name: "providerTaxId" },
        { label: "Provider Type", name: "providerType" },
      ])

    // functions
    const handlePayerInfoChange = (e) => {
        const { name, value } = e.target;
        serPayerInfo((prevDetails) => ({
          ...prevDetails,
          [name]: value, 
        }));
      };

    const handleSubmit = async () => {
        const keys = filterImportantKeys(patientBasicDetails, { middleName: true, })
        if (!!keys?.length) {
            toast?.error(`Please fill all important fields : ${keys?.join(' ')}`)
        }
        
        else {
            try {
                setLoader({...loader,form:true})
                const {
                    firstName: patientFirstName,
                    lastName: patientLastName,
                    middleName: patientMiddleName,
                    dob: patientDOB,
                    memberId: patientMemberId,
                    dosStart: DOS_Start,
                    dosEnd: DOS_END,
                    insuranceName
                } = patientBasicDetails
                const paSubmission = await submitPaForm({
                    patientFirstName,
                    patientLastName,
                    patientMemberId,
                    patientMiddleName: patientMiddleName ?? '',
                    insuranceName,
                    patientDOB: ymdDateFormat(patientDOB),
                    DOS_Start: ymdDateFormat(DOS_Start),
                    DOS_END: ymdDateFormat(DOS_END)

                })
            }
            catch (e) {
                console.log(e)
            }
            finally{
                setLoader({...loader,form:false})
            }
        }
    }
  return (
    <Box sx={sharedStyles?.mt10px}>
       <form>
        <Grid container spacing={2}>
       {patientInfoFields?.map((field, index) => {
                                    return(
                                    <Grid item xs={12} sm={4} lg={index < 2 ? 6 : 4} key={index}>
                                        <FormControl sx={sharedStyles?.mb2} fullWidth>
                                            <TextField
                                                label={field?.label}
                                                variant="outlined"
                                                name={field?.name}
                                                value={payerInfo[field.name]} 
                                                disabled={field?.disabled}
                                                onChange={handlePayerInfoChange}
                                            />
                                        </FormControl>
                                    </Grid>
                                )})}
                  <Grid
                      item
                      xs={12}
                      display={'flex'}
                      sx={sharedStyles?.justifyEnd}
                  >
                      <GreenButton onClick={handleSubmit} disabled={loader?.form}>
                         {loader?.form ? <CircularProgress size={17} color='inherit'/> :  'Submit'}
                      </GreenButton>
                  </Grid>

              </Grid>
       </form>
    </Box>
  )
}

export default BasicPayerIdForm