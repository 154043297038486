import { Box, CircularProgress, Grid, Tabs, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { sharedStyles } from '../../sharedStyles';
import { getClaimsDetails } from './PatientFunctions';
import PayerDetailsCard from '../../components/shared/PayerDetailsCard';
import MyTabs from '../../components/tabs/MyTabs';

const PatientClaims = () => {
    // states
    const [claimDetails, setClaimDetails] = useState(null)
    const [topCardsItems, setTopCardItems] = useState([])
    const [tabs, setTabs] = useState([])
    const [tabIndex, setTabIndex] = useState(0);
    const [isLoading, setIsLoading] = useState(false)
    // router vars
    const { id } = useParams();

    // functions
    const handleTabChange = (newTabIndex) => {
        setTabIndex(newTabIndex);
    };

    // useEffects
    useEffect(()=>{
        getClaimsDetails(id,setClaimDetails,setTopCardItems,setTabs,setIsLoading)
    },[id])

    // Top card view
    const TopCardDetails = () => {
        return(
            <Grid container rowSpacing={3} columnSpacing={3}>
            {topCardsItems?.map((elem, index) => (
                <Grid item xs={12} sm={6} lg={3} key={index}>
                    <PayerDetailsCard>
                        {elem?.icon}
                        <Box className="textAlignCenter">
                            <Typography className="boldText">{elem?.name}</Typography>
                            <Typography>{elem?.value}</Typography>
                        </Box>
                    </PayerDetailsCard>
                </Grid>
            ))}
        </Grid>
        )
    }

    // Tab view
    const TabView = () => {
        return(
            <Box style={sharedStyles.fullWidth}>
            <Tabs className="marginTopTabs">
                <MyTabs tabs={tabs} onChange={handleTabChange} activeTab={tabIndex} />
            </Tabs>
        </Box>
        )
    }

    // main return
    return (
        isLoading ? <Box className="loadingContainer">
            <CircularProgress color="success" size={22} />
        </Box>
            :
            claimDetails ?
                <>
                    <Box sx={[sharedStyles?.appHeader, sharedStyles?.mb1]}>
                        <Typography variant='h5'>Claim Details for {`${claimDetails?.subscriber?.firstName ?? ''} ${claimDetails?.subscriber?.lastName ?? ''}`}</Typography>
                    </Box>
                    <Box>
                        <Box className="marginTopBox">
                            <Box width={'100%'} display={'flex'} flexDirection={'column'} alignItems={'center'}>
                                {TopCardDetails()}
                                {!!tabs?.length && (
                                    TabView()
                                )}
                            </Box>
                        </Box>
                    </Box></>
                :
                <Box sx={[sharedStyles?.appHeader, sharedStyles?.mb1]}>
                    <Typography variant='h5'>Failed to get data for this patient's claim</Typography>
                </Box>
    )
}

export default PatientClaims