import React, { useEffect, useState } from 'react'
import { getAllCPTcodes } from '../services/pVerify';

const useCptCodes = () => {
    const [cptCodes, setCptCodes] = useState([]);



    const getCptCodes = async () => {
        try {
          const data = await getAllCPTcodes();
          if(data) {
            setCptCodes(data?.data?.data)
          } else {
            setCptCodes([])
          }
        } catch (error) {
          console.log(error)
        }
      }

    useEffect(() => {
        getCptCodes();
    }, [])

  return { cptCodes };
}

export default useCptCodes
