import React, { useState } from 'react'
import CsvHeader from '../../components/shared/Csv/CsvHeader'
import CsvUploader from '../../components/shared/Csv/CsvUploader'
import { downloadDiscoveryCsvTemplate, handleImportFileChange } from './DiscoveryFunctions'
import PageLoader from '../../components/loader/PageLoader'
import { useNavigate } from 'react-router-dom'

const ImportDiscovery = () => {
  // states
  const [loading, setLoading] = useState(false)

  //routing vars
  const navigate = useNavigate()

  return (
    <div>
      <CsvHeader onClick={() => downloadDiscoveryCsvTemplate()} />
      {loading ?
        <PageLoader />
        : <CsvUploader handleChange={(e) => handleImportFileChange(e, setLoading, navigate)} />}
    </div>

  )
}

export default ImportDiscovery