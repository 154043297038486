import React, { useState } from 'react'
import { additionalmedicalConditions, consentAcknowledgedOptions, gallBladerConsentString } from '../../../utils/staticData';
import { questionsString } from './QuestionString';
import RadioGroup from '../../../components/shared/RadioGroup';
import DisqualifiedModal from '../DisqualifiedModal';
import { sharedStyles } from '../../../sharedStyles';

const QuestionFour = ({
    handleMedicalConditionChange,
    showGallbladderConsent,
    // handleRadioChange,
    userResponse,
    showConditionDetails,
    selectedConditionDetails,
    handleConditionDetailsChange,
    setUserConsent,
    userConsent,
    setGallBladerConsentAcknowledged,
    gallBladerConsentAcknowledged,
    medicalConditions,
    inputError
}) => {

     // states
     const [showModal, setShowModal] = useState(false)

     // functions
    const handleRadioChange = (e) => {
        const {value} = e.target
        setGallBladerConsentAcknowledged(value)
        if(value === 'acknowledge'){
            setUserConsent({...userConsent,gallBlader:true})
        }
        else {
            setShowModal(true)
            setUserConsent({...userConsent,gallBlader:false})
        }
        }

    return (
        <div className="question">
            <label className="label-form"><strong>{questionsString?.four}</strong></label>
            <div className="checkbox-group">
                {additionalmedicalConditions?.map(({ value, label }) => (
                    <label className="label-form"  key={value}>
                        <input className="form-checkbox-radio"
                            type="checkbox"
                            value={value}
                            onChange={handleMedicalConditionChange}
                            checked={medicalConditions?.includes(value)}
                        />
                        {label}
                    </label>
                ))}
            </div>

            {/* Gallbladder Consent */}
            {showGallbladderConsent && (
                <div className="consent-section">
                    <p>
                        <strong>Consent (Gallbladder):</strong> <br />
                        {gallBladerConsentString}
                    </p>
                      <RadioGroup
                        options={consentAcknowledgedOptions}
                        selectedValue={gallBladerConsentAcknowledged}
                        onChange={handleRadioChange}
                    />

                </div>
            )}

            {/* Conditional Follow-up Question */}
            {showConditionDetails && (
                <div className="followup-question">
                    <label className="label-form">Please tell us more about your medical condition(s) that you selected:</label>
                    <textarea
                    style={{
                        ...sharedStyles?.fullWidth,
                        ...(inputError ? sharedStyles?.errorField : {})
                      }}
                      
                        placeholder="Provide more details..."
                        value={selectedConditionDetails}
                        onChange={handleConditionDetailsChange}
                    />
                </div>
            )}
             <DisqualifiedModal
            open={showModal}
            handleClose={()=>setShowModal(false)}
            onOk={()=>{
                setUserConsent({...userConsent,gallBlader:true})
                setGallBladerConsentAcknowledged('acknowledge')
                setShowModal(false)

            }}
            onCancel={()=>{
                setUserConsent({...userConsent,gallBlader:false})
                setShowModal(false)
            }}
            />
        </div>
    );
};

export default QuestionFour