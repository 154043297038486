import React from 'react'
import HorizontalBox from '../../shared/HorizontalBox'
import {  Button } from '@mui/material'
import { styles } from './styles'
import { useNavigate } from 'react-router-dom'

const BelugaPatientsHeader = () => {
    const navigate =  useNavigate()
  return (
    <HorizontalBox sx={styles?.container}>
         <Button
                variant="contained"
                sx={styles?.patientCardButton}
                onClick={()=>navigate('/visitsHistory')}
            >
                View History
            </Button>
    </HorizontalBox>
  )
}

export default BelugaPatientsHeader