import React, { useContext, useState } from "react";
import { Link, NavLink, useLocation, useNavigate, useSearchParams } from "react-router-dom";
import DropdownHeader from "./dropdown/DropdownHeader";
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import UseWidth from "../custom-hooks/UseWidth";
import { Badge, Button, Divider, IconButton, Menu, MenuItem, Typography } from "@mui/material";
import MenuIcon from '@mui/icons-material/Menu';
import { useAuth0 } from '@auth0/auth0-react';
import { clearAllLocalData } from "../utils/localStorageFunctions.js";
import { claimPaths, discoveryPaths, eligibilityPaths } from "../utils/staticData.js";
import { getRequestNpi, logoutRedirection } from "../utils/reusableFunctions.js";
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import { ContextProvider } from "../context/NotificationContext.js";
import { sharedStyles } from "../sharedStyles.js";
import PatientsDropdown from "./header/PatientsDropdown.jsx";
import AppointmentsDropdown from "./header/AppointmentsDropdown.jsx";
import DiscoveryDropdown from "./header/DiscoveryDropdown.jsx";
import EligibilityDropdown from "./header/EligibilityDropdown.jsx";
import ClaimsDropdown from "./header/ClaimsDropdown.jsx";


function TemporaryDrawer() {
  const [open, setOpen] = React.useState(false);
  const [routes] = useState([{ key: 'Home', to: '/home' }, { key: 'Patients', to: '/patients' }, { key: 'Appointments', to: '/appointments' }, { key: 'Discovery', to: 'Discoverytab' }, { key: 'Eligibilty', to: 'EligibilityTab' }, { key: 'Claims', to: 'ClaimTab' },
    // { key: 'Payments', to: 'payments' }
  ])

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  const verifiedUserNpi = getRequestNpi();
  const DrawerList = (
    <Box sx={{ width: 250 }} role="navigation" onClick={toggleDrawer(false)}>
      <List>
        {routes.map((text) => (
          <ListItem key={text.key} disablePadding>
            <ListItemButton component={NavLink} to={text.to}
              onClick={(e) => {
                (!verifiedUserNpi) && e?.preventDefault()
              }}
            >
              <ListItemText>
                <ListItemText primary={text.key} />
              </ListItemText>
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  return (
    <div>
      <IconButton onClick={toggleDrawer(true)} sx={{ borderRadius: '50%', background: '#5e8a75', ":hover": { background: '#5e8a75', transform: 'scale(1.1)', transition: '0.2s linear all' }, transition: '0.2s linear all' }}>
        <MenuIcon sx={{ color: '#fff' }} />
      </IconButton>
      <Drawer open={open} onClose={toggleDrawer(false)}>
        {DrawerList}
      </Drawer>
    </div>
  );
}

const Header = () => {
  const { pathname } = useLocation();
  const { width } = UseWidth()
  const { logout, } = useAuth0();
  const [searchParams] = useSearchParams();
  const [anchorEl, setAnchorEl] = useState(null);
  const throughSearchParam = searchParams.get('through');
  const { notifications } = useContext(ContextProvider);
  const navigate = useNavigate();

  const handleOptionSelect = () => {
    clearAllLocalData();
    logout({ logoutParams: { returnTo:logoutRedirection()} })
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const verifiedUserNpi = getRequestNpi();

  return (
    <section className="header-new">
      <div className="left-col" style={{ display: 'flex', alignItems: 'center' }}>
        {/* <Link> */}
          <img src="/assets/appzLogo.png" width={200} className="logo" alt="appzhealth" />
        {/* </Link> */}
      </div>

      <div className="right-col">
        <nav>
          <ul>
            <li>
              <NavLink
                className={pathname === "/home" ? "active" : ""}
                to={"/home"}
                onClick={(e) => {
                  if (!verifiedUserNpi) {
                    e.preventDefault();
                  }
                }}
              >
                Home
              </NavLink>
            </li>
            <PatientsDropdown verifiedUserNpi={verifiedUserNpi} active={pathname === "/patients" }/>
            <li style={sharedStyles?.z2}>
              <AppointmentsDropdown verifiedUserNpi={verifiedUserNpi} active={pathname === "/appointments" }/>
            </li>
            <li style={sharedStyles?.z2}>
              <DiscoveryDropdown verifiedUserNpi={verifiedUserNpi} active={discoveryPaths?.includes(pathname) || throughSearchParam === 'Discovery'}/>
            </li>
            <li style={sharedStyles?.z2}>
              <EligibilityDropdown verifiedUserNpi={verifiedUserNpi} active={eligibilityPaths?.includes(pathname) || throughSearchParam === 'Eligibility'}/>
            </li>
            <li style={sharedStyles?.z2}>
              <ClaimsDropdown verifiedUserNpi={verifiedUserNpi} active={claimPaths?.includes(pathname)}/>
            </li>
            <li>

              {/* <NavLink
                to={"payments"}
                className={pathname === "/payments" ? "active" : ""}
                onClick={(e) => {
                  if (!verifiedUserNpi) {
                    e.preventDefault();
                  }
                }}
              >
                Payments
              </NavLink> */}
            </li>
          </ul>
        </nav>
        <div
          className="header-right"
        >
          <Badge badgeContent={notifications?.length} role="button" onClick={handleClick} sx={sharedStyles?.mr2} color="primary"
          className="notifcationContainer"
          >
            <NotificationsNoneIcon color="action" />
          </Badge>
          <Menu
            open={Boolean(anchorEl)}
            transformOrigin={{
              horizontal: 'right'
            }}
            anchorEl={anchorEl}
            onClose={handleClose}
          >
            <List sx={sharedStyles?.list}>
              {
                notifications?.map((item, index) => (
                  <>
                    <ListItem key={index} onClick={() => navigate('/notifications')} sx={sharedStyles?.cursorPointer} alignItems="flex-start">
                      <ListItemText
                        primary={item?.notification}
                        secondary={
                          <React.Fragment>
                            <Typography
                              sx={{ display: 'inline' }}
                              component="span"
                              variant="body2"
                              color="text.primary"
                            >
                              {item?.description}
                            </Typography>
                          </React.Fragment>
                        }
                      />
                    </ListItem>
                    <Divider component="li" />
                  </>
                ))
              }
            </List>

          </Menu>

          <DropdownHeader handleOptionSelect={handleOptionSelect} />
          {
            width <= 1116 ?
              <TemporaryDrawer />
              : ''
          }
        </div>
      </div>
    </section>
  );
};

export default Header;
