import React, { useState } from 'react';
import HorizontalHeadingPlusText from '../shared/HorizontalHeadingPlusText';
import { update_UpdatePatientInsuranceDetails } from '../../services/registration';
import { Button, Grid, Paper, Typography } from '@mui/material';
import toast from 'react-hot-toast';
import styles from "../../css/discoveryDetails.module.css";

const EligibilityCoverageTab = ({ data, patientInsuranceTabData, tabname, patientId }) => {
  const { leftBox, header, rightBox, button, buttonGrid } = styles; 
  const coverageData = [
    { name: 'In Net HBPC Authorization Info', value: data?.inNetHBPCAuthorizationInfo },
    { name: 'Primary Care Provider Name', value: data?.primaryCareProviderName || 'N/A' },
    { name: 'Primary Care Provider Phone Number', value: data?.primaryCareProviderPhoneNumber || 'N/A' },
    { name: 'Out Net HBPC Authorization Info', value: data?.outNetHBPCAuthorizationInfo || 'N/A' },
  ];

  const [patientInsuranceDetailTabData, setPatientInsuranceDetailTabData] = useState(patientInsuranceTabData); 

  const handleSavePatientsDetails = async (tabName, patientId) => {
    try {
      const patientUpdatedData = await update_UpdatePatientInsuranceDetails(tabName, patientId);
      if (patientUpdatedData?.data?.insuranceDiscoveryEntities?.[0]?.pcpAuthInfoSummary) {
        setPatientInsuranceDetailTabData(patientUpdatedData?.data?.insuranceDiscoveryEntities?.[0]?.pcpAuthInfoSummary);
      } else {
        toast('Coverage detail is empty');
      }
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={12} md={5}>
        
          <Paper elevation={10} className={leftBox}>
            <Typography variant="h5" align="center" className={header}>
              Payer
            </Typography>
            <Typography align="center" variant="h5" className={header}>
              {tabname === "detail"
                ? "Plan Details"
                : tabname === "coverage"
                ? "Coverage Details"
                : tabname === "DemographicInfo"
                ? "DemographicInfo Details"
                : tabname === "Innetwork"
                ? "In Network"
                : tabname === "Outofnetwork"
                ? "Out of Network"
                : tabname === "Speciality"
                ? "Speciality"
                : ""}
            </Typography>
            <hr/>
            {coverageData?.length && coverageData?.map((item, index) => (
               <HorizontalHeadingPlusText heading={item?.name} text={item?.value} key={index} />
            ))}
          </Paper>
        </Grid>

        {/* Button */}
        <Grid item xs={12} md={2} className={buttonGrid}>
          <Button variant="outlined" className={button} onClick={()=>handleSavePatientsDetails(tabname, patientId)} >Sync with</Button>
        </Grid>

        <Grid item xs={12} md={5}>
          <Paper elevation={10} className={rightBox}>
            <Typography variant="h5" align="center" className={header}>
              Practice
            </Typography>
            <Typography align="center" variant="h5" className={header}>
              {tabname === "detail"
                ? "Plan Details"
                : tabname === "coverage"
                ? "Coverage Details"
                : tabname === "DemographicInfo"
                ? "DemographicInfo Details"
                : tabname === "Innetwork"
                ? "In Network"
                : tabname === "Outofnetwork"
                ? "Out of Network"
                : tabname === "Speciality"
                ? "Speciality"
                : ""}
            </Typography>
            <hr />
            <HorizontalHeadingPlusText heading={'In Net HBPC Authorization Info'} text={patientInsuranceDetailTabData?.inNetHBPCAuthorizationInfo || 'N/A'} />
            <HorizontalHeadingPlusText heading={'Primary Care Provider Name'} text={patientInsuranceDetailTabData?.primaryCareProviderName || 'N/A'} />
            <HorizontalHeadingPlusText heading={'Primary Care Provider Phone Number'} text={patientInsuranceDetailTabData?.primaryCareProviderPhoneNumber || 'N/A'} />
            <HorizontalHeadingPlusText heading={'Out Net HBPC Authorization Info'} text={patientInsuranceDetailTabData?.outNetHBPCAuthorizationInfo || 'N/A'} />
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
};

export default EligibilityCoverageTab;
