import axios from "axios";
import toast from "react-hot-toast";
import { API_BASE_URL, API_BASE_URL_NEW } from "../utils/staticData";

export const getNpiDetails = async (requestData) => {

    try {
        const response = await axios.post(API_BASE_URL_NEW + `/api/Npi/GetNpiDataByNpiNumber?npiNumber=${requestData}`, {
            headers: {
                "Content-Type": "application/json",
            },
        });
        return response;
    } catch (error) {
        toast?.error(error?.response?.data?.detail ?? 'Failed to get Npi Data')
        console.log(error)
    }

}