import axios from "axios";
import toast from "react-hot-toast";
import { API_BASE_URL } from "../utils/staticData.js"



export const getUser = async (requestData) => {
    try {
        const res = await axios
            .get(process.env.REACT_APP_API_URL_NEW + `/api/UserRegistration/GetUserByUserId?userId=${requestData}`, {
                headers: {
                    "Content-Type": "application/json",
                },
            });
        return res
    } catch (error) {
        toast?.error(error?.response?.data?.detail ?? 'Failed to get User')
        console.log(error)
    }
}