export const styles = {
    tabWrapper: {
        borderColor: 'divider', borderTopRightRadius: '12px', borderTopLeftRadius: '12px', marginLeft: 0
    }
}

export const tabStyles = {
    tabsContainer:{flexDirection:{xs:'column',lg:'row'},overflow:'hidden'},
    tabs:{width:{xs:'100%',lg:'auto'}},
    tabRightSide:{ marginLeft: { xs:'40px',lg:'0' } }
}