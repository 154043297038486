import React from 'react'
import RadioGroup from '../../../components/shared/RadioGroup';
import { acceptingOptions } from '../../../utils/staticData';
import { questionsString } from './QuestionString';

const QuestionTwo = ({ glpMedication, handleGlpMedicationChange }) => (
    <RadioGroup
        label={questionsString?.two}
        options={acceptingOptions}
        selectedValue={glpMedication ?? ''} 
        onChange={handleGlpMedicationChange}
    />
);
export default QuestionTwo
