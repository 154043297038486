import { Box, Button, CircularProgress, FormControl, Grid, InputLabel, MenuItem, OutlinedInput, Select, TextField } from '@mui/material'
import React, { useEffect, useState } from 'react'
import styles from "../../css/addpatient.module.css";
import TableHeader from '../../components/TableHeader';
import { sharedStyles } from '../../sharedStyles';
import { defaultMBIAddressState, defaultMBILookupDetails, unitedStates } from '../../utils/staticData';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { colors } from '../../utils/colors';
import { isNumber } from '../../utils/regexTestFunctions';
import toast from 'react-hot-toast';
import { postalCodeFormat } from '../../utils/valueFormatter/Formats';
import usePVerifyToken from '../../components/usePVerifyToken';
import { mbiLookup } from '../../services/pVerify';
import dayjs from 'dayjs';
import { get_GetPatientDataById } from '../../services/addPatient';

const MBILookup = () => {
    const [mbiDetails, setMbiDetails] = useState(defaultMBILookupDetails);
    const [addressDetails, setAddressDetails] = useState(defaultMBIAddressState);
    const [loading, setloading] = useState(false);
    const [patientSelected, setPatientSelected] = useState('')
    const { formWrapper } = styles;
    const { pverifyToken } = usePVerifyToken();

    const handleChange = (event) => {
        if (event && event.target) {
            const { name, value } = event.target;
            if (name === 'MRN') {
                if (!isNumber(value)) {
                    return;
                }
            } else if (name === 'PatientSSN_or_HICN') {
                if (!isNumber(value)) {
                    return;
                }
            }
            setMbiDetails((prevState) => ({
                ...prevState,
                [name]: value
            }));

        }
    };

    const handleAddressChange = (event) => {
        if (event && event.target) {
            const { name, value } = event.target;
            if (name === 'ZipCode') {
                let inputVal = postalCodeFormat(value);
                setAddressDetails((prevState) => ({
                    ...prevState,
                    [name]: inputVal
                }));
                return;
            }
            setAddressDetails((prevState) => ({
                ...prevState,
                [name]: value
            }))
        }
    }


    const postDetails = async (e) => {
        e.preventDefault()
        setloading(true)
        try {
            const response = await mbiLookup(mbiDetails, addressDetails, pverifyToken);
            if (response?.data) {
                setAddressDetails(defaultMBIAddressState);
                setMbiDetails(defaultMBILookupDetails);
            }
        } catch (error) {
            console.log(error);
        } finally {
            setloading(false)
        }
    }

    const handleSelectionChange = async () => {
        const patinetData = await get_GetPatientDataById(patientSelected?.id);
        const { data: { firstName, lastName, dob,  address: { state,addressLine1,city,zipCode } } } = patinetData;
        const codeOfState = unitedStates?.find(item => item?.name === state);
        const objToSet = {
            ProviderLastName: "",
            ProviderFirstName: "",
            ProviderNPI: "",
            PatientFirstName: firstName || "",
            PatientDOB: dayjs(dob) || "",
            PatientLastName: lastName || "",
            PatientSSN_or_HICN: "",
            PatientSSN: "",
            MRN: "",
            Location: ""
        }
        const addressToSet = {
            AddressLine1: addressLine1,
            City: city,
            State: codeOfState?.code,
            ZipCode: zipCode
        }
       setMbiDetails(objToSet);
       setAddressDetails(addressToSet)
    }

useEffect(() => {
    if(patientSelected?.id) {
        handleSelectionChange();
    } else {
        setAddressDetails(defaultMBIAddressState);
        setMbiDetails(defaultMBILookupDetails);
    }
}, [patientSelected]);

    return (
        <div>
            <TableHeader setFilterPatient={setPatientSelected} idNeeded />
            <Box>
                <Box className={formWrapper}>
                    <form onSubmit={postDetails} style={sharedStyles?.wFull}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6} lg={6}>
                                <FormControl sx={sharedStyles?.mb2} fullWidth>
                                    <TextField
                                        label="Provider First Name"
                                        variant="outlined"
                                        name="ProviderFirstName"
                                        color="success"
                                        onChange={handleChange}
                                        required
                                        value={mbiDetails?.ProviderFirstName}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6} lg={6}>
                                <FormControl sx={sharedStyles?.mb2} fullWidth>
                                    <TextField
                                        label="Provider Last Name"
                                        variant="outlined"
                                        name="ProviderLastName"
                                        color="success"
                                        onChange={handleChange}
                                        value={mbiDetails?.ProviderLastName}
                                        required
                                        inputProps={{ maxLength: 20 }}
                                    />
                                </FormControl>
                            </Grid>


                            <Grid item xs={12} sm={6} lg={6}>
                                <FormControl sx={sharedStyles?.mb2} fullWidth>
                                    <TextField
                                        label="Patient First Name"
                                        variant="outlined"
                                        name="PatientFirstName"
                                        color="success"
                                        onChange={handleChange}
                                        required
                                        inputProps={{ maxLength: 20 }}
                                        value={mbiDetails?.PatientFirstName}
                                    />
                                </FormControl>
                            </Grid>

                            <Grid item xs={12} sm={6} lg={6}>
                                <FormControl sx={sharedStyles?.mb2} fullWidth>
                                    <TextField
                                        label="Patient Last Name"
                                        variant="outlined"
                                        name="PatientLastName"
                                        onChange={handleChange}
                                        color="success"
                                        required
                                        inputProps={{ maxLength: 20 }}
                                        value={mbiDetails?.PatientLastName}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6} lg={6}>
                                <FormControl sx={sharedStyles?.mb2} fullWidth>
                                    <TextField
                                        label="Provider NPI"
                                        variant="outlined"
                                        name="ProviderNPI"
                                        color="success"
                                        onChange={handleChange}
                                        required
                                        value={mbiDetails?.ProviderNPI}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6} lg={6}>
                                <FormControl sx={sharedStyles?.mb2} fullWidth>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DatePicker
                                            label="Date of Birth"
                                            value={mbiDetails?.PatientDOB}
                                            onChange={handleChange}
                                            sx={{
                                                '& .MuiOutlinedInput-root': {
                                                    '& .MuiOutlinedInput-notchedOutline': {
                                                        borderColor: colors?.inputBorder,
                                                    },
                                                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                                        borderColor: colors?.green,
                                                    },
                                                },
                                                '& .MuiFormLabel-root': {
                                                    color: colors?.black,
                                                },
                                                '& .MuiInputLabel-root.Mui-focused': {
                                                    color: colors?.green,
                                                },
                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                />
                                            )}
                                        />
                                    </LocalizationProvider>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6} lg={6}>
                                <FormControl sx={sharedStyles?.mb2} fullWidth>
                                    <TextField
                                        label="Patient SSN or HICN"
                                        variant="outlined"
                                        name="PatientSSN_or_HICN"
                                        color="success"
                                        onChange={handleChange}
                                        required
                                        value={mbiDetails?.PatientSSN_or_HICN}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6} lg={6}>
                                <FormControl sx={sharedStyles?.mb2} fullWidth>
                                    <TextField
                                        label="Patient SSN"
                                        variant="outlined"
                                        name="PatientSSN"
                                        color="success"
                                        onChange={handleChange}
                                        required
                                        value={mbiDetails?.PatientSSN}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6} lg={6}>
                                <FormControl sx={sharedStyles?.mb2} fullWidth>
                                    <TextField
                                        label="MRN"
                                        variant="outlined"
                                        name="MRN"
                                        color="success"
                                        value={mbiDetails?.MRN}
                                        onChange={(e) => {
                                            handleChange(e)
                                        }}
                                        required
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6} lg={6}>
                                <FormControl sx={sharedStyles?.mb2} fullWidth>
                                    <TextField
                                        label="Location"
                                        variant="outlined"
                                        name="Location"
                                        color="success"
                                        onChange={handleChange}
                                        required
                                        value={mbiDetails?.Location}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6} lg={6}>
                                <FormControl sx={sharedStyles?.mb2} fullWidth>
                                    <TextField
                                        label="Address Line1"
                                        variant="outlined"
                                        name="AddressLine1"
                                        color="success"
                                        onChange={handleAddressChange}
                                        required
                                        value={addressDetails?.AddressLine1}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6} lg={6}>
                                <FormControl sx={sharedStyles?.mb2} fullWidth>
                                    <TextField
                                        label="City"
                                        variant="outlined"
                                        name="City"
                                        color="success"
                                        onChange={handleAddressChange}
                                        required
                                        value={addressDetails?.City}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6} lg={6}>
                                <FormControl sx={sharedStyles?.mb2} fullWidth>
                                    <InputLabel id="">State</InputLabel>
                                    <Select
                                        value={addressDetails?.State}
                                        id="State"
                                        onChange={(e) => setAddressDetails({...addressDetails, State: e.target.value})}
                                        color="success"
                                        name='State'
                                        input={<OutlinedInput label="State" />}
                                        sx={{
                                            '& .MuiOutlinedInput-notchedOutline ': sharedStyles?.borderRadius12,
                                            '& .MuiGrid-root': sharedStyles?.h30
                                        }}
                                    >
                                        {
                                            unitedStates?.map(item => (
                                                <MenuItem value={item?.code} key={item?.code}>
                                                    {item?.name}
                                                </MenuItem>
                                            ))
                                        }
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6} lg={6}>
                                <FormControl sx={sharedStyles?.mb2} fullWidth>
                                    <TextField
                                        label="Postal Code"
                                        variant="outlined"
                                        name="ZipCode"
                                        color="success"
                                        required
                                        value={addressDetails?.ZipCode}
                                        onChange={handleAddressChange}
                                        inputProps={{
                                            maxLength: 10
                                        }}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                <Box sx={[sharedStyles?.displayFlex, sharedStyles?.justifyCenter, sharedStyles?.mt2em]}>
                                    <Button variant="outlined" color="inherit">
                                        Cancel
                                    </Button>
                                    <Button type='submit' variant="contained" color="success" sx={sharedStyles?.ml5}>
                                        {loading ? <CircularProgress color="inherit" size={20} /> : 'Search'}
                                    </Button>
                                </Box>
                            </Grid>
                        </Grid>
                    </form>
                </Box>
            </Box>
        </div>
    )
}

export default MBILookup
