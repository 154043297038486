import React, { useState } from 'react'
import Dropdown from './Dropdown'
import { Dashboard, Healing, Schedule } from '@mui/icons-material'

const AppointmentsDropdown = ({verifiedUserNpi,active}) => {
    const [tabs] = useState([
        {label: "Dashboard",path: "/appointments", icon: <Dashboard fontSize="small" /> },
        {label: 'Appointments',path: "/appointments?preSelectedTab=1", icon: <Schedule fontSize="small" />},
        {label: 'Doctor Availability',path: "/appointments?preSelectedTab=2", icon: <Healing fontSize="small" />}
    ])
  return (
    <Dropdown
    tabs={tabs}
    verifiedUserNpi={verifiedUserNpi}
    heading={'Appointments'}
    path={'/appointments'}
    active={active}
    />
  )
}

export default AppointmentsDropdown