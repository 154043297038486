import { Box } from '@mui/material'
import React from 'react'
import { styles } from './styles'

const TabContainer = ({children,key}) => {
  return (
    <section  key={key}>
    <Box sx={styles?.tabContainerBox}>
        {children}
    </Box>
</section>
  )
}

export default TabContainer