import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { get_PatientAppointments } from '../../services/addPatient';
import { Box, Grid, Typography } from '@mui/material';
import { sharedStyles } from '../../sharedStyles';
import moment from 'moment/moment';
import PageLoader from '../../components/loader/PageLoader';




const PatientAppointments = () => {
    const [data, setData] = useState();
    const [passedAppointments, setPassedAppointments] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const { id } = useParams();

    const getAppointments = async () => {
        setIsLoading(true)
        try {
            const response = await get_PatientAppointments(id);
            response?.data?.map(item => {
                const isAhead = moment().isAfter(item?.createAt);
                if (isAhead) {
                    setPassedAppointments(response?.data);
                } else {
                    setData(response?.data)
                }
            })
        } catch (error) {
            console.log(error)
        } finally {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        getAppointments();
    }, [id]);

    const Badge = ({ item }) => {
        const daysRemaining = moment(item?.createAt).diff(moment(item?.appointmentDate), 'days');
        const isAhead = moment().isAfter(item?.createAt);

        return (
            <Box sx={[sharedStyles?.badgeStyle, isAhead && sharedStyles?.expiredBadge]}>
                <Typography variant='body1'>{
                    daysRemaining === 1 ? `Appointment in ${daysRemaining} day`
                        : isAhead ? 'Your Appointment has passed'
                            : !isAhead && daysRemaining == 0 ? `Your Appointment is ${moment().calendar()}`
                                : `Appointment in ${daysRemaining} days`}</Typography>
            </Box>
        )
    };

    return (
        <>
            <Box sx={[sharedStyles?.appHeader, sharedStyles?.mb1]}>
                <Typography variant='h6'>Upcoming Appointments</Typography>
            </Box>
            {
                isLoading ? <PageLoader />
                    :
                    <Grid container sx={sharedStyles?.responsivePad} gap={4}>
                        {
                            !data?.length ?
                                <Grid item lg={6}>
                                    <Typography variant='body1'>No Upcoming Appointments for this Patient!</Typography>
                                </Grid>
                                :
                                data?.map(item => (
                                    <Grid sx={sharedStyles?.themeCard} key={item?.id} item xs={12} sm={12} md={6} lg={4}>
                                        <Typography variant='h6'>{item?.appointmentTitle}</Typography>
                                        <Box sx={sharedStyles?.innerThemeCard}>
                                            <Typography variant='body2'>Appointment Created at: {new Date(item?.createAt).toDateString()}</Typography>
                                            <Typography variant='body2'>Appointment Date: {new Date(item?.appointmentDate).toDateString()}</Typography>
                                            <Typography variant='body2'>Type: {item?.type}</Typography>
                                            <Typography variant='body2'>Start Time: {new Date(item?.serviceStartTime).toLocaleTimeString()}</Typography>
                                            <Typography variant='body2'>End Time: {new Date(item?.serviceEndTime).toLocaleTimeString()}</Typography>
                                            <Box>
                                                <Typography variant='caption'>Notes:</Typography>
                                                <ol>
                                                    {

                                                    }
                                                    <li>{item?.notes?.notes1}</li>
                                                    <li>{item?.notes?.notes2 ? item?.notes?.notes2 : 'Not Found!'}</li>
                                                </ol>
                                            </Box>
                                        </Box>
                                        <Badge item={item} />
                                    </Grid>
                                ))
                        }
                    </Grid>
            }
            <Box sx={[sharedStyles?.appHeader, sharedStyles?.mb1]}>
                <Typography variant='h6'>Passed Appointments</Typography>
            </Box>
            {
                isLoading ? <PageLoader />
                    :
                    <Grid container sx={sharedStyles?.responsivePad} gap={4}>
                        {
                            !passedAppointments?.length ?
                                <Grid item lg={6}>
                                    <Typography variant='body1'>No Past Appointments for this Patient!</Typography>
                                </Grid>
                                :
                                passedAppointments?.map(item => (
                                    <Grid sx={sharedStyles?.themeCard} key={item?.id} item xs={12} sm={12} md={6} lg={4}>
                                        <Typography variant='h5'>{item?.appointmentTitle}</Typography>
                                        <Box sx={sharedStyles?.innerThemeCard}>
                                            <Typography variant='body2'>Appointment Date: {new Date(item?.appointmentDate).toDateString()}</Typography>
                                            <Typography variant='body2'>Appointment Created at: {new Date(item?.createAt).toDateString()}</Typography>
                                            <Typography variant='body2'>Type: {item?.type}</Typography>
                                            <Typography variant='body2'>Start Time: {new Date(item?.serviceStartTime).toLocaleTimeString()}</Typography>
                                            <Typography variant='body2'>End Time: {new Date(item?.serviceEndTime).toLocaleTimeString()}</Typography>
                                            <Box>
                                                <Typography variant='caption'>Notes:</Typography>
                                                <ol>
                                                    {

                                                    }
                                                    <li>{item?.notes?.notes1}</li>
                                                    <li>{item?.notes?.notes2 ? item?.notes?.notes2 : 'Not Found!'}</li>
                                                </ol>
                                            </Box>
                                        </Box>
                                        <Badge item={item} />
                                    </Grid>
                                ))
                        }
                    </Grid>
            }
        </>
    )
};


export default PatientAppointments;
