import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { get_GetInusuranceDiscoveryByPatientId } from '../../services/registration';
import companyLogo from './appzLogo.png'; 
import { colors } from '../../utils/colors';

export const handleExportPDF = async (patientId) => {
  try {
    console.log('Starting PDF export for patient ID:', patientId);

    const response = await get_GetInusuranceDiscoveryByPatientId(patientId);
    const data = response?.insuranceDiscoveryDto || {};

    const doc = new jsPDF();

    const addHeader = (doc) => {
      const imgWidth = 50;
      const imgHeight = 15; 
      const marginTop = 10;
      const marginLeft = 150; 
      
      doc.addImage(companyLogo, 'PNG', marginLeft, marginTop, imgWidth, imgHeight);
      doc.setFontSize(18);
      doc.text('Insurance Discovery Details', 14, 20);
    };

    addHeader(doc);

    const autoTableOptions = {
      didDrawPage: () => addHeader(doc),
      margin: { top: 40 },
    };


    const addDemographicInfoSection = (subscriber, startY) => {
      doc.setFontSize(14);
      doc.text('Demographic Information', 14, startY);

      const tableData = [
        ['Full Name', subscriber?.fullName || 'N/A'],
        ['Date of Birth', subscriber?.doB_R || 'N/A'],
        ['Gender', subscriber?.gender_R || 'N/A'],
        ['Address', `${subscriber?.address1 || ''} ${subscriber?.address2 || ''}`.trim() || 'N/A'],
        ['City', subscriber?.city || 'N/A'],
        ['State', subscriber?.state || 'N/A'],
        ['ZIP', subscriber?.zip || 'N/A'],
        ['Member ID', subscriber?.identification?.find(id => id.type === "Member ID")?.code || 'N/A'],
        ['Plan Number', subscriber?.identification?.find(id => id.type === "Plan Number")?.code || 'N/A'],
        ['Group Number', subscriber?.identification?.find(id => id.type === "Group Number")?.code || 'N/A'],
        ['Plan Begin Date', subscriber?.date?.find(d => d.type === "Plan Begin")?.date || 'N/A'],
        ['Term Date', subscriber?.date?.find(d => d.type === "Term Date")?.date || 'N/A']
      ];

      doc.autoTable({
        ...autoTableOptions,
        startY: startY + 10,
        head: [['Field', 'Value']],
        body: tableData,
        theme: 'grid',
        styles: { fontSize: 10, cellPadding: 2 },
        headStyles: { fillColor: colors?.themeGreen },
        columnStyles: { 0: { cellWidth: 80 }, 1: { cellWidth: 'auto' } },
      });

      return doc.lastAutoTable.finalY;
    };

    const addSection = (title, content, startY) => {
      doc.setFontSize(14);
      doc.text(title, 14, startY);

      const tableData = Object.entries(content || {})
        .filter(([_, value]) => value != null && value !== '')
        .map(([key, value]) => [
          key.replace(/([A-Z])/g, ' $1').trim(),
          typeof value === 'object' ? JSON.stringify(value) : String(value),
        ]) || [['No data available', '']];

      doc.autoTable({
        ...autoTableOptions,
        startY: startY + 10,
        head: [['Field', 'Value']],
        body: tableData,
        theme: 'grid',
        styles: { fontSize: 10, cellPadding: 2 },
        headStyles: { fillColor: colors?.themeGreen },
        columnStyles: { 0: { cellWidth: 80 }, 1: { cellWidth: 'auto' } },
      });

      return doc.lastAutoTable.finalY;
    };

    let yPosition = 40;

    
    const subscriber = data?.demographicInfo?.subscriber;
    if (subscriber) {
      yPosition = addDemographicInfoSection(subscriber, yPosition) + 10;
    }

   
    const sectionsToInclude = [
      { key: 'planCoverageSummary', title: 'Plan Coverage Summary' },
      { key: 'hbpC_Deductible_OOP_Summary', title: 'HBPC Deductible & OOP Summary' },
      { key: 'miscellaneousInfoSummary', title: 'Miscellaneous Info Summary' },
      { key: 'otherPayerInfo', title: 'Other Payer Info' },
      { key: 'pcpAuthInfoSummary', title: 'PCP Auth Info Summary' },
    ];

    sectionsToInclude.forEach(section => {
      const content = data?.[section.key];
      if (content) {
        yPosition = addSection(section.title, content, yPosition) + 10;
      }
    });

   
    if (Array.isArray(data?.serviceDetails)) {
      const serviceDetailsData = data.serviceDetails.reduce((acc, service, index) => {
        if (service?.serviceName) acc[`Service ${index + 1}`] = service.serviceName;
        return acc;
      }, {});

      if (Object.keys(serviceDetailsData).length > 0) {
        yPosition = addSection('Service Details', serviceDetailsData, yPosition) + 10;
      }
    }
    const patientName = subscriber?.fullName?.replace(/\s+/g, '_') || 'Unknown_Patient';
    const currentDate = new Date();
    const formattedDateTime = `${String(currentDate.getMonth() + 1).padStart(2, '0')}-${String(currentDate.getDate()).padStart(2, '0')}-${currentDate.getFullYear()}_${String(currentDate.getHours()).padStart(2, '0')}-${String(currentDate.getMinutes()).padStart(2, '0')}-${String(currentDate.getSeconds()).padStart(2, '0')}`;

    doc.save(`InsuranceDiscoveryDetails_${patientName}_${formattedDateTime}.pdf`);
    
  } catch (error) {
    console.error('Error generating PDF:', error);
  }
};
