import { Box } from '@mui/material'
import React from 'react'
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import styles from './styles.module.css'

const CsvUploader = ({handleChange}) => {
  return (
        <Box className={styles?.uploadCsv}>
        <div className={styles?.uploadCsvButton}>
          <CloudUploadIcon />
          <span>Upload CSV</span>
        </div>
        <input
          type="file"
          onChange={handleChange}
          className={styles?.inputTag}
           accept=".csv"
        />
      </Box>
  )
}

export default CsvUploader