export const questionsString = {
    one:"Q1. What was your sex assigned at birth?*",
    two:"Q2. Are you currently taking a GLP-1 agonist medication such as Ozempic, Wegovy, Zepbound, Mounjaro, Semaglutide, or Tirzepatide?*",
    three:'Q3. What is your height in feet and inches?*',
    four:'Q4. Please check all current or past medical conditions:*',
    prescriptionImage:'Do you have a picture of your current prescription? We need this photograph in order to validate your current dosage.',
    uploadPrescription:'Q:- Please upload a picture of the prescription or bottle of your current GLP-1/GIP medication',
    twelve:`Upload a photo of a valid government-issued photo ID such as a driver's license or passport which has your picture, name, and date of birth clearly visible. If applicable, upload a photo of the front and the back, especially when using a military ID as your date of birth is on the back of the card. Make sure the photo is clear and legible.`,
    thirteen:'To ensure the highest quality of care during your telemedicine consultation, we kindly request your assistance in providing a full-body clothed photo of yourself (please remain clothed but avoid wearing baggy apparel). The image should include your face to help facilitate verification of your identity. Please be assured that these images will remain private and will not be shared. The physician will use the picture solely for assessment purposes to better understand your health condition. Thank you for your cooperation and understanding in helping to provide comprehensive care remotely. (Tip: using a full-length mirror can help you capture this image)',
    followup2: 'Q. What is your height in feet and inches?*'
}