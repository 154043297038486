import React, { useEffect, useState } from 'react'
import { styles } from './styles'
import { Box } from '@mui/material'
import InnerThemeCardHeader from '../../components/InnerCardHeaders/InnerThemeCardHeader'
import BoxComponent from '../../components/BoxComponent'
import { FetchPayersData } from './dataFormatters'
import { sharedStyles } from '../../sharedStyles'
import PageLoader from '../../components/loader/PageLoader'

const PayerDetails = ({noMargin}) => {
    const [claimsData, setClaimsData] = useState([])
    const [isLoading, setIsLoading] = useState(false)

    useEffect(()=>{
        FetchPayersData(setIsLoading,setClaimsData)
    },[])


    return (
        <Box boxShadow={3} paddingY={2} paddingX={2} borderRadius={4}
            sx={styles.card} marginY={2} marginLeft={{lg:!noMargin && 4}} height={335} marginRight={{xs:!noMargin && 2}}
            overflow={'auto'}>
            <InnerThemeCardHeader heading={'Payer Details and Statuses'} />
            {
                isLoading ?
                <PageLoader height={'25vh'} /> :
                claimsData?.map((elem, index) => {
                    return (
                        <div style={index === claimsData?.length - 1 ? sharedStyles?.pv10 : styles?.payerDetailElems}>
                            {elem?.map(({ label, value }) => {
                                return (
                                <>
                                    <BoxComponent
                                        key={label}
                                        isLoading={isLoading}
                                        label={label}
                                        value={value || '----'}
                                    />
                                </>
                                )
                            })}
                        </div>
                    )
                })
            }
        </Box>
    )
}

export default PayerDetails