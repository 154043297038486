import { AgGridReact } from 'ag-grid-react';
import React, { useEffect, useState } from 'react'
import toast from 'react-hot-toast';

const CdTable = ({data}) => {
    const [rowData, setRowData] = useState(null);



    const columnDefs = () => {
        return [
            { headerName: 'ID', field: 'id', filter: true, width: 100, resizable: false },
            { headerName: 'ICD Code', field: 'icdCode', filter: true, width: 200, resizable: false },
            {
                headerName: 'Long Description', field: 'longDescription', filter: true, width: 400, resizable: true,
            },
            { headerName: 'Short Description', field: 'shortDescription', width: 400, filter: true, resizable: true },
            { headerName: 'Version', field: 'version', width: 100, filter: true, resizable: true },
        ];
    };


    const gridOptions = {
        pagination: true,
        paginationPageSize: 10,
        domLayout: 'paginationPrevNext,paginationPageSize,paginationFirstLast',
    };


    useEffect(() => {
        if (data) {
            const rowsData = data?.map(({ icdCode, fullDescription, longDescription, shortDescription, id, version }) => ({
                id: id || 'N/A',
                icdCode: icdCode || 'N/A',
                fullDescription: fullDescription || 'N/A',
                longDescription: longDescription || 'N/A',
                shortDescription: shortDescription || 'N/A',
                version: version || 'N/A'
               
            }))
            setRowData(rowsData)
        } else {
            setRowData([])
            toast.error("CD Codes Not Found!")
        }
    }, [data])

    return (
        <div className="ag-theme-quartz"
            style={{ height: 500 }}>
            <AgGridReact
                columnDefs={columnDefs()}
                rowData={rowData}
                gridOptions={gridOptions}
                overlayNoRowsTemplate='No Cpt Codes Found!'
            />
        </div>
    )
}

export default CdTable
