import { Box, Paper, Typography, Button } from '@mui/material'
import React from 'react'
import { styles } from './styles'
import { bradyNewPatientSignInSteps, bradyNewPatientSignUpSteps } from '../../utils/staticData'

const PatientMainPage = ({handleLogin}) => {
  return (
    <Box
    sx={styles?.patientContainer}
>
    <img
        src={"https://r2.appzhealth.com/Green%20Illustrative%20Wellness%20Health%20Logo%20FOR%20PRINT%201.png"}
        alt={`Logo`}
        style={styles?.patientImage}
    />

    <Typography
        variant="h4"
        component="h1"
        sx={styles?.patientHeading}
    >
         Welcome to Brady's Wellness Patient Portal
    </Typography>

    <Typography
        variant="h6"
        sx={styles?.patientSubHeading}
    >
        The best place for all weight and wellness needs!
    </Typography>

    <Box
      sx={styles?.patientCardsContainer}
    >
        <Paper
            elevation={0}
            sx={styles?.patientCard}
        >
            <Typography variant="h5" sx={styles?.patientCardHeading}>
                New Patients
            </Typography>
            <Typography sx={styles?.patientCardListing}>
                {bradyNewPatientSignInSteps?.map((elem, index) => {
                    return(
                        <div key={index + 1}>{index + 1}. {elem}</div>
                    )
                })}
            </Typography>
            <Button
                variant="contained"
                onClick={handleLogin}
                sx={styles?.patientCardButton}
            >
                Create Account
            </Button>
        </Paper>

        <Paper
            elevation={0}
            sx={styles?.patientCard}
        >

            <Typography variant="h5" sx={styles?.patientCardHeading}>
                Returning Patients
            </Typography>
            <Typography sx={styles?.patientCardListing}>
                {bradyNewPatientSignUpSteps?.map((elem, index) => {
                    return(
                        <div key={index + 1}>{index + 1}. {elem}</div>
                    )
                })}
            </Typography>
            <Button
                variant="contained"
                onClick={handleLogin}
                sx={styles?.patientCardButton}
            >
                Login
            </Button>
        </Paper>
    </Box>
</Box>
  )
}

export default PatientMainPage