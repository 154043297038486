import React from 'react';
import { Box, Button, CircularProgress } from '@mui/material';
import ErrorIcon from '@mui/icons-material/Error';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import { useNavigate } from 'react-router-dom';
import { sharedStyles } from '../../sharedStyles';

const PatientInsuranceStatus = ({ data },rowData) => {
  const navigate = useNavigate()
  return (
    <Box display={'flex'} justifyContent={'start'} alignItems={'center'} gap={2}>
      {data?.processStatus !== 'Completed' ? data?.processStatus ?? '--' : ''}
      {
      data?.processStatus === 'Processing' ? <CircularProgress size={17} color='success' /> :
       data?.processStatus === 'Queued' ? <HourglassEmptyIcon size={17} color='success' /> :
       data?.processStatus === 'Completed' &&  data?.processStatus !== 'Failed'?  <CircularProgress size={17} color='success' sx={sharedStyles?.mt10px} />
    //    <Button
    //    variant='contained'
    //    color='success'
    //    size='small'
    //   //  onClick={()=>navigate("/Searchresults?through=BulkInsurance", {
    //   //   state: { searchData: data?.postsearchdata },
    //   // })}
    //    sx={{marginTop:'4px'}}
    //    disabled={rowData?.some(elem => elem?.processStatus === 'Queued' || elem?.processStatus === 'Processing')}
    //  >
    //    View Details
    //  </Button>
     :
       data?.processStatus === 'Failed' ? <ErrorIcon color="error" /> :
       null
       }
    </Box>
  );
}

export default PatientInsuranceStatus;
