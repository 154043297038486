import { Box, Button, CircularProgress, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { colors } from '../../utils/colors'
import HorizontalBox from './HorizontalBox'
import GreenButton from '../claims/GreenButton'
import { getAllClaimsIds, getAllDiscoveryIds } from '../../utils/globalFunctions'
import { getAdjacentElements } from '../../utils/reusableFunctions'
import { sharedStyles } from '../../sharedStyles'
import { FaChevronCircleLeft, FaChevronCircleRight } from 'react-icons/fa'
import { getDiscoveryDetails } from '../../Pages/Discovery/DiscoveryFunctions'

const DetailsHeader = ({ topRightButtons, name, dob, message,pageName,id,insurance }) => {
    const [adjacentELems, setAdjacentElems] = useState({ before: null, after: null })
    const [isLoading, setIsLoading] = useState(false)
    
    // handling adjacent elem for discovery details1
    const getDiscoveryList = async () =>{
        const list = await getAllDiscoveryIds()
        
        const adjacentIds = getAdjacentElements(list, id)
        setAdjacentElems(adjacentIds)
    }

    // handling adjacent elem for claim details1
    const getClaimsList = async() => {
        const list = await getAllClaimsIds()
        const adjacentIds = getAdjacentElements(list,id)
        setAdjacentElems(adjacentIds)
    }

    const handleArrowClick = (elem) => {
        if (pageName === 'Discoverydetails1') {
            getDiscoveryDetails(elem,setIsLoading)
        }
        else if(pageName === 'ClaimResults1'){
            const newUrl = `/Claimdetails1?id=${elem}`;
            window.location.href = newUrl; 
        }
    }

    useEffect(() => {
        if (pageName === 'Discoverydetails1') {
            getDiscoveryList()
        }
        else if(pageName === 'ClaimResults1'){
            getClaimsList()
        }
    }, [pageName])

    return (
        <Box sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' }, alignItems: 'center', justifyContent: 'space-between', bgcolor: '#f4f6f8', p: 2, borderRadius: 2, gap: 2 }}>
           {
            isLoading ? <CircularProgress color='success' size={17}/>
            :
            <HorizontalBox sx={sharedStyles?.gap2}>
            {adjacentELems?.before && <FaChevronCircleLeft
                         style={sharedStyles?.cursorPointerOnly}
                         color={colors?.themeGreen}
                         onClick={()=>handleArrowClick(adjacentELems?.before)}
                     />}
             <Typography sx={{ color: colors?.themeGreen, fontWeight: '700', wordSpacing: 2, textTransform: 'uppercase' }}>
                 {name ?? ''} {dob ? `(${dob})` : ''} {message ?? ''}
             </Typography>
             {adjacentELems?.after && <FaChevronCircleRight
                             style={sharedStyles?.cursorPointerOnly}
                             color={colors?.themeGreen}
                             onClick={()=>handleArrowClick(adjacentELems?.after)}
                         />}
             </HorizontalBox>
           }
            <HorizontalBox sx={{ justifyContent: 'flex-end', gap: 2 }}>
                {topRightButtons?.map(({ name, action }, index) => {
                    return (
                        <Button variant="contained" color='success' onClick={action} disabled={(insurance?.replace(/\s+/g, '')==="KAISERPERMANENTE-MAS") && name === "Check Eligibility" ? true : false} key={index}>{name}</Button>
                    )
                })}
            </HorizontalBox>
        </Box>
    )
}

export default DetailsHeader