import { Button, Grid } from '@mui/material'
import React from 'react'
import styles from './styles.module.css'

const CsvHeader = ({onClick}) => {
  return (
    <Grid container spacing={2}>
        <Grid item xs={12} sm={6} lg={6}>
          <p>* Upload a new CSV file</p>
        </Grid>
        <Grid item xs={12} sm={6} lg={6} textAlign="right">
          <Button
            variant='contained'
            color='success'
            onClick={onClick}
            className={styles?.downloadCsvTemplate}
          >
            Download CSV Template
          </Button>
        </Grid>
      </Grid>
  )
}

export default CsvHeader