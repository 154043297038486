import React, { useEffect, useState } from 'react'
import MyTabs from '../../components/tabs/MyTabs'
import { Box, Button } from '@mui/material'
import { GridComp } from '../../css/GridComponent';
import ClaimSearch from './ClaimSearch';
import { useLocation, useSearchParams } from 'react-router-dom';
import ClaimDashboard from './ClaimDashboard';
import  "../../css/ClaimSearchStyles.css";
import ImportClaims from './ImportClaims';
import ClaimBatchSearchresultdata from './ClaimBatchSearchresultdata';
import ClaimTables from './ClaimTables';

const Claimtab = () => {
    const [searchParams] = useSearchParams();
    const preSelectedTabParam = searchParams.get('preSelectedTab');
    const [preSelectedTab,setPreSelectedTab] = useState(preSelectedTabParam ? Number(preSelectedTabParam) : 0);
    const location = useLocation()

    const tabs =
        [
            {
                label: "Dashboard ", value: <ClaimDashboard />,
                button: 'New Search', changeTab: 2
            },
            {
                label: "Claim ", value: <ClaimTables />,
                button: 'New Search', changeTab: 2
            },
            {
                label: "Search", value: <ClaimSearch />, changeTab: 0, button: 'Back'
            },
            {
                label: "Import Claims", value: <ImportClaims />, changeTab: 1, button: 'Back'
            },
        ]

        useEffect(() => {
            setPreSelectedTab(preSelectedTabParam ? Number(preSelectedTabParam) : 0);
        }, [preSelectedTabParam]);

    return (
        <section className='claim' key={location?.key}>
        <Box className='customBox'>
            <MyTabs tabs={tabs} preSelectedTab={preSelectedTab} />
            {/* <Button variant='text'>Add Patient</Button> */}
        </Box>
    </section>
    )
}
export default Claimtab