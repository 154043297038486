import React, { useState } from 'react'
import PregnancyConsentModal from '../../Questionnaire/PregnancyConsentModal';
import { genderOptions, unAcceptedConsentStr } from '../../../utils/staticData';
import DisqualifiedModal from '../../Questionnaire/DisqualifiedModal';
import RadioGroup from '../../../components/shared/RadioGroup';
import ErrorrText from '../../../components/shared/ErrorrText';

const QuestionOne = ({
    sex,
    isPregnancyQuestionVisible,
    pregnant,
    handlePregnancyChange,
    showConsent,
    setUserConsent,
    userConsent,
    setShowConsent,
    setPregnancyQuestionVisible,
    setSex,
    failedValidations
}) => {
    const [showModal, setShowModal] = useState(false)

    const handleSexChange = ({ target: { value } }) => {
        if (value) {
            setSex(value);
            setPregnancyQuestionVisible(value.toLowerCase() === "female");
        }
    };

    return (
        <div>
            {/* Sex Assigned at Birth */}
            <RadioGroup
                label="Q. What was your sex assigned at birth?*"
                options={genderOptions}
                selectedValue={sex}
                onChange={handleSexChange}

            />

            {/* Pregnancy Question */}
            {isPregnancyQuestionVisible && (
                <RadioGroup
                    label="Q. Are you currently pregnant, breastfeeding, or planning to become pregnant?"
                    options={[
                        { value: "Yes", label: "Yes" },
                        { value: "No", label: "No" },
                    ]}
                    selectedValue={pregnant ?? ''}
                    onChange={handlePregnancyChange}
                />
            )}

            {/* Modal Popup for Consent */}
            {showConsent && (
                <PregnancyConsentModal
                    setUserConsent={setUserConsent}
                    setShowConsent={setShowConsent}
                    userConsent={userConsent}
                    setShowModal={setShowModal}
                />
            )}

            <DisqualifiedModal
                open={showModal}
                handleClose={() => setShowModal(false)}
                onOk={() => {
                    setUserConsent({ ...userConsent, pregnancy: true })
                    setShowModal(false)
                    setShowConsent(false)

                }}
                onCancel={() => {
                    setUserConsent({ ...userConsent, pregnancy: false })
                    setShowModal(false)
                    setShowConsent(false)
                }}
            />
            {failedValidations?.includes('pregnancyConsent') && <ErrorrText text={unAcceptedConsentStr}/>}
            {failedValidations?.includes('pregnant') && <ErrorrText text={'We dont accept pregnant patients as of now'}/>}
            {failedValidations?.includes('pregnancy') && <ErrorrText importantField/>}
        </div>
    );
};

export default QuestionOne