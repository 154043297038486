import dayjs from "dayjs";
import { failedToGetProviderOrganizationStr, noValidFilterSelectedStr, timeAdjustments } from "../../utils/staticData";
import { getNpiDataByNpiNumber } from "../../services/NpiFinderInquiry/getNpiNumberById";
import { getLocalData } from "../../utils/localStorageFunctions";
import toast from "react-hot-toast";
import { failedApiReq } from "../../utils/reusableFunctions";

// being used in every where we have same patient detials dashboard as in home page, 
export const handleRightAction = (lastSelectedFilter,startDate,setStartDate,dateFilterAction,setDateRecord,dateRecord) => {
    const adjustment = timeAdjustments[lastSelectedFilter];
    if (adjustment) {
        const potentialNextDate = dayjs(startDate).add(adjustment.value, adjustment.unit);
        
        const today = dayjs(); 
        const isFutureDate = potentialNextDate.isAfter(today, 'day'); 

        const newDate = isFutureDate ? today : potentialNextDate;

        setStartDate(newDate);
        setDateRecord({...dateRecord,startDate:newDate})
        dateFilterAction(newDate)
    } else {
        console.log(noValidFilterSelectedStr);
    }
};

// being used in every where we have same patient detials dashboard as in home page,
export   const handleLeftAction = (lastSelectedFilter,startDate,setStartDate,dateFilterAction,setDateRecord,dateRecord) => {
  const adjustment = timeAdjustments[lastSelectedFilter];
  
    if (adjustment) {
        const prevDate = dayjs(startDate).subtract(adjustment.value, adjustment.unit);
        setStartDate(dayjs(new Date(prevDate)));
        setDateRecord({...dateRecord,startDate:dayjs(new Date(prevDate))})
        dateFilterAction(dayjs(new Date(prevDate)))
    } else {
        console.log(noValidFilterSelectedStr);
    }
};

export const getProviderName = async(setProviderOrganizationName) => {
    try{
        const npiNumber = getLocalData('selectedNpi')?.npiNumber
        const providerData = await getNpiDataByNpiNumber(npiNumber)
        if(providerData?.data?.pG_NpiData?.providerOrganizationName){
            setProviderOrganizationName(providerData?.data?.pG_NpiData?.providerOrganizationName)
        }
        else{
            console.log(failedToGetProviderOrganizationStr)
        }
    }
    catch(e){
        failedApiReq(e)
    }
}