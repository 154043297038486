
import { Box, CircularProgress, FormControl, Grid, Autocomplete, InputAdornment, InputLabel, MenuItem, OutlinedInput, Select, TextField, Typography } from "@mui/material";
import { isAlphabetOnly, isAlphaNumeric } from "../../utils/regexTestFunctions";
import { alphaNumericValidationStr, defaultPatientData, MenuProps, onlyAlphabetsValidationStr, policyHolderRelationship } from "../../utils/staticData";
import React, { useState, useEffect } from "react";
import styles from "../../css/addpatient.module.css";
import TableHeader from "../../components/TableHeader";
import Button from "@mui/material/Button";
import { post_Patient } from "../../services/addPatient";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { get_payersdata } from "../../services/PayerService";
import { getLocalData } from "../../utils/localStorageFunctions";
import { phoneNumberFormat } from "../../utils/valueFormatter/Formats";
import toast from "react-hot-toast";
import { failedApiReq, getRequestNpi } from "../../utils/reusableFunctions";
import { clear, fetchPatientDetails, fieldsErrCheck, optionalFieldsProvidedCheck, requiredFieldsProvidedCheck } from "./PatientFunctions";
import ReusableConfirmationModal from "../../components/modal/ReusableConfirmationModal";
import { useNavigate } from "react-router-dom";
import { mdyFormatDate } from "../../utils/DateFunctions";
import { colors } from "../../utils/colors";
import { sharedStyles } from "../../sharedStyles";

const AddPatient = () => {
  const [payersData, setpayersData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [fieldsErr, setFieldsErr] = useState(false);
  const [emailError, setEmailError] = useState('');
  const [patientDetails, setPatientDetails] = useState(defaultPatientData);
  const [selectedPatient, setSelectedPatient] = useState('')
  const [phoneNumberErr, setPhoneNumberErr] = useState(false)
  const { userId } = getLocalData('user')
  const [refetchAllPatients, setRefetchAllPatients] = useState(0)
  const [showModal, setShowModal] = useState(false)

  const { patientFormWrapper } = styles;
  const navigate = useNavigate()


  const fetchData = async () => {
    try {
      const payersdata = await get_payersdata();
      var payers_array = [];
      for (let i = 0; i < payersdata?.data?.length; i++) {
        payers_array.push(payersdata?.data?.[i].payerName)
      }
      setpayersData(payers_array)
    } catch (error) {
      console.error('Error fetching patient data:', error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleChange = (e) => {
    if (e.target && e.target.name) {
      const { name, value } = e.target;
      // Validations
      if ((name === 'memberId' || name === 'groupNumber')) {
        if (!isAlphaNumeric(value)) {
          toast.error(alphaNumericValidationStr);
          return;
        }
      } else if ((name === 'patientFirstName' || name === 'patientLastName')) {
        if (!isAlphabetOnly(value)) {
          toast.error(onlyAlphabetsValidationStr);
          return;
        }
      } else if (name === 'phoneNumber') {
        let formattedValue = phoneNumberFormat(value);
        setPatientDetails((prevDetails) => ({
          ...prevDetails,
          [name]: formattedValue,
        }));
        return;
      }
      setPatientDetails((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };


  const handleInsuranceChange = (event, newValue) => {
    setPatientDetails((prevState) => ({
      ...prevState,
      insurancename: newValue,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const {
      patientFirstName, patientLastName, email, patientDOB, memberId, groupNumber, appointmentDate, insurancename, phoneNumber, policyHolderRelationship, patientMiddleName
    } = patientDetails;

    if (fieldsErrCheck(patientFirstName, patientLastName, patientDOB)) {
      setFieldsErr(true);
      return;
    }

    const requiredFieldsProvided = requiredFieldsProvidedCheck(patientFirstName, patientLastName, patientDOB);
    const optionalFieldsProvided = optionalFieldsProvidedCheck(memberId, groupNumber, appointmentDate, insurancename, policyHolderRelationship, phoneNumber, email, patientMiddleName);
    let payload = {};

    if (requiredFieldsProvided) {
      // If required fields are provided only then process this payload
      payload = {
        patientFirstName,
        patientLastName,
        patientDOB,
        requestNpi: getRequestNpi(),
      };

      // If any optional field is provided then process this payload.
      if (optionalFieldsProvided) {
        if (phoneNumber?.length < 12 && phoneNumber?.length !== 0) {
          setPhoneNumberErr(true);
          toast.error("Phone Number should not be less than 10 digits");
          return;
        }
        payload = {
          ...payload,
          patientDOB,
          memberId,
          groupNumber,
          appointmentDate,
          insurancename: insurancename?.length ? insurancename.join(", ") : null,
          phoneNumber,
          policyHolderRelationship,
          email,
          phoneNumber,
          createdBy: userId,
          updatedBy: userId,
          patientMiddleName
        };
      }
    }
    setIsLoading(true);
    try {
      const addPatient = await post_Patient(payload);
      if (addPatient?.data?.message) {
        toast.success(addPatient?.data?.message);
        setRefetchAllPatients(refetchAllPatients + 1)
        setFieldsErr(false);
        setShowModal(true);
        setPatientDetails({
          patientLastName: "",
          patientMiddleName: "",
          patientFirstName: "",
          patientDOB: null,
          phoneNumber: "",
          email: null,
          appointmentDate: null,
          insurancename: [],
          memberId: "",
          groupNumber: "",
          policyHolderRelationship: "",
          requestNpi: ""
        })
      }
    } catch (err) {
      failedApiReq(err)
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (selectedPatient?.id) {
      fetchPatientDetails(setPatientDetails, selectedPatient)
    }
    else clear(setPatientDetails, setFieldsErr)
  }, [selectedPatient])

  const handleModal = () => {
    const { patientFirstName, patientLastName, patientDOB } = patientDetails
    navigate(`/discoveryTab?preSelectedTab=2&firstName=${patientFirstName}&lastName=${patientLastName}&dob=${mdyFormatDate(patientDOB)}`)
    setShowModal(false)
    setPatientDetails(defaultPatientData);
  }


  return (
    <div>
      <TableHeader setFilterPatient={setSelectedPatient} idNeeded refetch={refetchAllPatients} />
      <Box className={patientFormWrapper}>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} lg={6}>
              <FormControl
                sx={{ marginBottom: "2em", color: "purple" }}
                fullWidth
              >
                <TextField
                  label="First Name"
                  id="outlined-start-adornment"
                  sx={{
                    '& .MuiOutlinedInput-notchedOutline ': {
                      borderRadius: '12px',
                    }
                  }}
                  inputProps={{
                    maxLength: 20,
                    minLength: 2,
                    style: { textTransform: 'capitalize' }
                  }}
                  InputProps={{
                    startAdornment: <InputAdornment position="start"></InputAdornment>,
                  }}
                  variant="outlined"
                  onChange={handleChange}
                  value={patientDetails.patientFirstName}
                  name="patientFirstName"
                  color="success"
                  helperText={fieldsErr && !patientDetails?.patientFirstName ? 'This field is required' : 'Required*'}
                  error={fieldsErr && !patientDetails?.patientFirstName}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} lg={6}>
              <FormControl
                sx={{ marginBottom: "2em", color: "purple" }}
                fullWidth
              >
                <TextField
                  label="Middle Name"
                  id="outlined-start-adornment"
                  sx={{
                    '& .MuiOutlinedInput-notchedOutline ': {
                      borderRadius: '12px',
                    }
                  }}
                  inputProps={{
                    maxLength: 20,
                    minLength: 2,
                    style: { textTransform: 'capitalize' }
                  }}
                  InputProps={{
                    startAdornment: <InputAdornment position="start"></InputAdornment>,
                  }}
                  variant="outlined"
                  onChange={handleChange}
                  value={patientDetails?.patientMiddleName}
                  name="patientMiddleName"
                  color="success"
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} lg={6}>
              <FormControl sx={{ marginBottom: "2em" }} fullWidth>
                <TextField
                  InputProps={{
                    startAdornment: <InputAdornment position="start"></InputAdornment>,
                  }}
                  inputProps={{
                    maxLenght: 20,
                    minLength: 2,
                    style: { textTransform: 'capitalize' }
                  }}
                  sx={{
                    '& .MuiOutlinedInput-notchedOutline ': {
                      borderRadius: '12px'
                    }
                  }}
                  onChange={handleChange}
                  value={patientDetails.patientLastName}
                  name="patientLastName"
                  label="Last Name"
                  variant="outlined"
                  color="success"
                  helperText={fieldsErr && !patientDetails?.patientLastName ? 'This field is required' : 'Required*'}
                  error={fieldsErr && !patientDetails?.patientLastName}
                />
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={6} lg={6}>
              <FormControl sx={{ marginBottom: "2em" }} fullWidth>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        '& .MuiOutlinedInput-notchedOutline': {
                          borderRadius: '12px',
                          borderColor: (!patientDetails.patientDOB && fieldsErr) ? colors?.errorRed : undefined,
                        },
                        '&:hover .MuiOutlinedInput-notchedOutline': {
                          borderColor: (!patientDetails.patientDOB && fieldsErr) ? colors?.errorRed : undefined,
                        },
                        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                          borderColor: (!patientDetails.patientDOB && fieldsErr) ? colors?.errorRed : undefined,
                        },
                      },
                      '& .MuiFormLabel-root': {
                        color: (!patientDetails.patientDOB && fieldsErr) ? colors?.errorRed : undefined,
                      },
                      '& .MuiInputLabel-root.Mui-focused': {
                        color: (!patientDetails.patientDOB && fieldsErr) ? colors?.errorRed : undefined,
                      },
                    }}
                    label="Date of Birth"
                    onChange={(val) =>
                      setPatientDetails({
                        ...patientDetails,
                        patientDOB: val,
                      })
                    }
                    name={"patientDOB"}
                    value={patientDetails?.patientDOB}
                    variant="outlined"
                    color="success"
                  />
                </LocalizationProvider>
                <Typography fontSize={'12px'} color={!patientDetails.patientDOB && fieldsErr ? colors?.errorRed : colors?.fieldGray} paddingLeft={"1em"} paddingTop={"0.2em"}>{!patientDetails.patientDOB && fieldsErr ? 'The field is required' : 'Required*'} </Typography>

              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} lg={6}>
              <FormControl sx={{ marginBottom: "2em" }} fullWidth>
                <TextField
                  InputProps={{
                    startAdornment: <InputAdornment position="start"></InputAdornment>,
                  }}
                  sx={{
                    '& .MuiOutlinedInput-notchedOutline ': {
                      borderRadius: '12px',
                    }
                  }}
                  inputProps={{
                    inputMode: 'numeric'
                  }}
                  onChange={handleChange}
                  value={patientDetails?.phoneNumber}
                  name="phoneNumber"
                  label="Phone Number"
                  variant="outlined"
                  type="tel"
                  error={phoneNumberErr && patientDetails?.phoneNumber?.length < 12}
                  helperText={phoneNumberErr && patientDetails?.phoneNumber?.length < 12 ? 'Phone Number should not be less than 10 digits' : ''}
                  color="success"
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} lg={6}>
              <FormControl sx={{ marginBottom: "2em" }} fullWidth>
                <TextField
                  InputProps={{
                    startAdornment: <InputAdornment position="start"></InputAdornment>,
                  }}
                  sx={{
                    '& .MuiOutlinedInput-notchedOutline ': {
                      borderRadius: '12px',
                    }
                  }}
                  onChange={handleChange}
                  value={patientDetails?.email}
                  name="email"
                  label="Email"
                  type="email"
                  variant="outlined"
                  color="success"
                  error={emailError ? true : false}
                  helperText={emailError}
                />
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={6} lg={6}>
              <FormControl sx={{ marginBottom: "2em" }} fullWidth>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    sx={{
                      '& .MuiOutlinedInput-notchedOutline ': {
                        borderRadius: '12px',
                      }
                    }}
                    label="Appointment Date"
                    onChange={(val) =>
                      setPatientDetails({
                        ...patientDetails,
                        appointmentDate: val,
                      })
                    }
                    name={"appointmentDate"}
                    value={patientDetails?.appointmentDate}
                    variant="outlined"
                    color="success"
                  />
                </LocalizationProvider>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} lg={6}>
              <FormControl sx={{ marginBottom: "2em" }} fullWidth>
                <Autocomplete
                  multiple
                  options={payersData}
                  getOptionLabel={(option) => option}
                  filterSelectedOptions
                  value={patientDetails?.insurancename}
                  onChange={handleInsuranceChange}
                  sx={{
                    '& .MuiOutlinedInput-notchedOutline ': {
                      borderRadius: '12px',
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Insurance Name"
                      variant="outlined"
                      color="success"
                      helperText="Select insurance names"
                    />
                  )}
                />
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={6} lg={6}>
              <FormControl sx={{ marginBottom: "2em" }} fullWidth>
                <TextField
                  InputProps={{
                    startAdornment: <InputAdornment position="start"></InputAdornment>
                  }}
                  inputProps={{
                    maxLength: 30,
                    minLength: 2,
                    pattern: '^[a-zA-Z0-9]*$'
                  }}
                  sx={{
                    '& .MuiOutlinedInput-notchedOutline ': {
                      borderRadius: '12px',
                    }
                  }}
                  onChange={handleChange}
                  value={patientDetails?.memberId}
                  name="memberId"
                  label="Member ID"
                  variant="outlined"
                  color="success"
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} lg={6}>
              <FormControl sx={{ marginBottom: "2em" }} fullWidth>
                <TextField
                  InputProps={{
                    startAdornment: <InputAdornment position="start"></InputAdornment>,
                  }}
                  sx={{
                    '& .MuiOutlinedInput-notchedOutline ': {
                      borderRadius: '12px',
                    }
                  }}
                  onChange={handleChange}
                  value={patientDetails.groupNumber}
                  name="groupNumber"
                  label="Group Number"
                  variant="outlined"
                  color="success"
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} lg={6}>
              <FormControl sx={{ marginBottom: "2em" }} fullWidth>
                <InputLabel id="payer-collection">Policy Holder Relationship</InputLabel>
                <Select
                  id="policy-holders"
                  onChange={(e) => setPatientDetails({ ...patientDetails, policyHolderRelationship: e.target.value })}
                  value={patientDetails?.policyHolderRelationship}
                  input={<OutlinedInput label="Policy Holder Relationship" />}
                  MenuProps={MenuProps}
                  sx={{
                    '& .MuiOutlinedInput-notchedOutline ': {
                      borderRadius: '12px',
                    },
                    '& .MuiGrid-root': {
                      height: '30px'
                    }
                  }}
                >
                  {
                    policyHolderRelationship?.length && policyHolderRelationship?.map((item, index) => (
                      <MenuItem value={item} key={index}>
                        {item}
                      </MenuItem>
                    ))
                  }
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} lg={12}>
              <Box sx={{ display: "flex", justifyContent: "center", gap: '2em' }}>
                <Button sx={{
                  background: 'white', color: 'black', border: '1px solid #0000003b', boxShadow: 0, '&:hover': {
                    backgroundColor: 'lightgray',
                    color: 'black',
                  },
                }} onClick={() => {
                  setPatientDetails({
                    patientLastName: "",
                    patientMiddleName: "",
                    patientFirstName: "",
                    patientDOB: null,
                    phoneNumber: "",
                    email: null,
                    appointmentDate: null,
                    insurancename: [],
                    memberId: "",
                    groupNumber: "",
                    policyHolderRelationship: "",
                    requestNpi: ""
                  })
                  setFieldsErr(false)
                  setPhoneNumberErr(false)
                }} variant="contained" disabled={isLoading} style={{ cursor: isLoading ? 'not-allowed' : 'pointer' }} size="large">
                  Clear
                </Button>
                <Button type="submit" disabled={isLoading} sx={sharedStyles?.themeBtn} variant="contained" size="small">
                  {isLoading ? <CircularProgress /> : 'Save'}
                </Button>
              </Box>
            </Grid>

          </Grid>
        </form>
      </Box>
      <ReusableConfirmationModal

        open={showModal}
        onClick={handleModal}
        onClose={() => {
          setShowModal(false)
          setPatientDetails(defaultPatientData);
        }}
        heading={'Do you want to run an insurance discovery search ?'}
      />
    </div>
  );
};

export default AddPatient;
