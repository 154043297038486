import toast from "react-hot-toast"
import { getClaimDetails } from "../../services/addPatient"
import { errorInGettingPayerDetailsStr, noDataForPayerDetailsStr } from "../../utils/staticData"
import { colors } from "../../utils/colors"

export const patientTabData = ({newPatientsAdded,patientsHasInsurance,patientsWithoutInsurance}) => {
    return [{data:newPatientsAdded,label:'New Patients Added'},
            {data:patientsHasInsurance,label:'Patients With Insurance'},
            {data:patientsWithoutInsurance,label:'Patients Without Insurance'}]
}

export const payerDetailsFormat = (claimsData) => {
  const formattedClaimsData = claimsData?.map(({payerName,statusCode,checkStatus,statusDescription,count}) => {
    return(
       [ {
            label: 'Payer Name',
            value:payerName
        },
        {
            label: 'Status Code',
            value:statusCode
        },
        {
            label: 'Check Status', 
            value:checkStatus
        },
        {
            label: 'Status Description with Count',
            value:`${statusDescription} ${count}`
            
        },
    ]
    )
  })
  return formattedClaimsData
}

export const FetchPayersData = async (setIsLoading,setClaimsData) => {
    setIsLoading(true)
    try {
        const dataForClaims = await getClaimDetails()
        if (dataForClaims?.data?.length) {
            setClaimsData(payerDetailsFormat(dataForClaims?.data))
        }
        else {
            // toast.error(noDataForPayerDetailsStr)
            console.log(noDataForPayerDetailsStr)
        }
    }
    catch (e) {
        console.log(errorInGettingPayerDetailsStr, e)
    }
    finally {
        setIsLoading(false)
    }
}

export const selectedCardHeading = (cardIndex) => {
    const heading = ['New Patients Added','Patients With Insurance','Patients Without Insurance']?.find((_,index) => index + 1 === cardIndex)
    return heading
}


export const selectedCardGraphData = (cardIndex,data) => {
    const {labels,datasets} = data
    let arrayLabels = []
    let arrayDataSets = []
    // checking if graph has repeated data added to handle the line graph
    if(labels?.length === 2 && labels?.[0] === labels?.[1]){
        arrayLabels = [labels?.[0]]
        if(cardIndex === 1){
            const matchedVal = datasets?.find(elem => elem?.label === 'New Patients Added')
            arrayDataSets = [{...matchedVal,data:[matchedVal?.data?.[0]]}]
        }
        if(cardIndex === 2){
            const matchedVal = datasets?.find(elem => elem?.label === "Patients having insurance")
            arrayDataSets = [{...matchedVal,data:[matchedVal?.data?.[0]]}]
        }
        if(cardIndex === 3){
            const matchedVal = datasets?.find(elem => elem?.label === "Patients missing insurance")
            arrayDataSets = [{...matchedVal,data:[matchedVal?.data?.[0]]}]
        }

        return(
            {
                labels: arrayLabels,
                datasets: [{
                    ...arrayDataSets?.[0],
                    borderRadius: 5,
                    barThickness: 8,
                    backgroundColor: cardIndex === 3 ? colors?.red : cardIndex === 2 ? colors?.yellow : colors?.themeGreen,
                }]
            }
        )
    }

    // else 
    else{
        if(cardIndex === 1){
            arrayDataSets = [datasets?.find(elem => elem?.label === 'New Patients Added')]
        }
        if(cardIndex === 2){
            arrayDataSets = [datasets?.find(elem => elem?.label === "Patients having insurance")]
        }
        if(cardIndex === 3){
            arrayDataSets = [datasets?.find(elem => elem?.label === "Patients missing insurance")]
        }
        return(
            {
                labels,
                datasets:arrayDataSets?.map((elem) => {return(
                    {
                        ...elem,
                        borderRadius: 5,
                        barThickness: 8,
                        backgroundColor: cardIndex === 3 ? colors?.red : cardIndex === 2 ? colors?.yellow : colors?.themeGreen,
                    }
                )})
            }
        )
    }
}
