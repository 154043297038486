import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
} from "@mui/material";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import GridComponentPatients from "../../components/table/GridComponentPatients";
import { get_ExportPatientsRecords, get_GetAllPatientsDataAsync } from "../../services/addPatient";
import toast from "react-hot-toast";
import styles from "../../css/patientManagement.module.css"
import { base64ToByteArray, failedGetApi, isValidBase64 } from "../../utils/reusableFunctions";
import { sharedStyles } from "../../sharedStyles";
import { getLocalData } from "../../utils/localStorageFunctions";

const PatientManagement = () => {
  const [data, setData] = useState();
  const [isLoading, setIsLoading] = useState();
  const [input, setInput] = useState("");
  const [filterPatient, setFilterPatient] = useState("");
  const selectedNpi = getLocalData("selectedNpi");
  const { id } = selectedNpi;

  const fetchData = () => {
    setIsLoading(true);
    get_GetAllPatientsDataAsync(id).then(res => {
      const data = res?.data;
      const reversedARR = data?.reverse();
      setData(reversedARR)
      setIsLoading(false)
      handlePatientExport(reversedARR)
    }).catch(() => {
      failedGetApi()
      setIsLoading(false)
    })
  }

  const handlePatientExport = async (data) => {
    try {
      const payload = data && data?.length > 0 ? data?.map(patientData => ({
        id: patientData?.id,
        patientName: patientData?.firstName + ' ' + patientData?.lastName,
        appointmentDate: patientData?.appointments?.[0],
        status: patientData?.patientInsuranceDetails?.length ?  patientData?.patientInsuranceDetails?.map(insuranceDetail => insuranceDetail).join(', ') : '---',
        insuranceName: patientData?.patientInsuranceDetails ? patientData?.patientInsuranceDetails?.map(insuranceDetail => insuranceDetail).join(', ') : '---',
        phone: patientData?.phone,
        deductible: '',
        copay: ''
      })) : [];

      const res = await get_ExportPatientsRecords(payload);
      const base64String = res?.csvFileByteArray;

      if (!isValidBase64(base64String)) {
        toast?.error('Invalid base64 string received')
      }

      const byteArray = base64ToByteArray(base64String);
      const blob = new Blob([byteArray], { type: 'text/csv' });
      const curl = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = curl;
      a.download = `Export_Patient_Data (${new Date().getUTCDate()}-${new Date().getUTCMonth() + 1}-${new Date().getUTCFullYear()}).csv`;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);

    } catch (error) {
      const base64String = error?.response?.data?.csvFileByteArray;

      if (!isValidBase64(base64String)) {
        toast?.error('Invalid base64 string received')
      }

      const byteArray = base64ToByteArray(base64String);
      const blob = new Blob([byteArray], { type: 'text/csv' });
      const curl = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = curl;
      a.download = `Export_Patient_Error_Response (${new Date().getUTCDate()}-${new Date().getUTCMonth() + 1}-${new Date().getUTCFullYear()}).csv`;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);

      toast?.error(error?.response?.data?.errors ?? 'Failed to upload CSV');
      console.log('Error while export file', error);
    }
  }


  // useEffect(() => {
  //   fetchData()
  // }, [id])
  return (
    <Box>
      <Box className={styles?.mainBox}>
        <GridComponentPatients />
      </Box>
      <Box className={styles?.rowDataBox}>
        <Button
          disableUnderline={false}
          size="small"
          color="success"
          sx={sharedStyles?.mt2em}
          startIcon={<CloudDownloadIcon />}
          variant="contained"
          onClick={() => fetchData()}
          disabled={isLoading}
        >
          {isLoading ? <CircularProgress color="inherit" size={20} /> : 'Export'}
        </Button>
      </Box>
    </Box>
  );
};

export default PatientManagement;
