import React, { useState } from "react";
import { PersonAdd, Group, Sync, ImportExport, Dashboard } from "@mui/icons-material";
import Dropdown from "./Dropdown";

const PatientsDropdown = ({ verifiedUserNpi,active }) => {
    // states
    const [tabs] = useState([
        { label: "Dashboard", path: "/patients", icon: <Dashboard fontSize="small" /> },
        { label: "Patient Management", path: "/patients?preSelectedTab=1", icon: <Group fontSize="small" /> },
        { label: "Add Patient", path: "/patients?preSelectedTab=2", icon: <PersonAdd fontSize="small" /> },
        { label: "Import Patient", path: "/patients?preSelectedTab=3", icon: <ImportExport fontSize="small" /> },
        { label: "Patient Sync", path: "/patients?preSelectedTab=4", icon: <Sync fontSize="small" /> },
    ]);

    // main return
    return (
        <Dropdown
        tabs={tabs}
        verifiedUserNpi={verifiedUserNpi}
        heading={'Patients'}
        path={'/patients'}
        active={active}
        />
    );
};

export default PatientsDropdown;
