
import { AgGridReact } from 'ag-grid-react';
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import React, { useEffect, useState } from 'react';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { capitalizedFirstLetter, failedApiReq, getLastElementFromArray } from '../../utils/reusableFunctions';
import { get_GetAllClaimsStatusData } from '../../services/ClaimService';
import { styles } from './styles';
import { sharedStyles } from '../../sharedStyles';
import { dayJsMdyFormat } from '../../utils/DateFunctions';

const ClaimsPaginatedTable = () => {
    const [gridApi, setGridApi] = useState(null);
  const perPage = 10;
    let navigate = useNavigate();

    const onGridReady = (params) => {
        setGridApi(params.api);
      };

    useEffect(() => {
        if (gridApi) {
          const dataSource = {
            getRows: async(params) => {
    
              const page = params.endRow / perPage;
              try{
                gridApi.showLoadingOverlay();
              const data = await get_GetAllClaimsStatusData(page,perPage);
                  const dataArray = data?.claimsStatusSimplifiedDtos?.map(({ patientInfoData: { id: ID, dob: DOB, firstName, lastName, phone: Phone, patientInsuranceDetails }, category, claimStatuses }) => {
                      return {
                          ID,
                          Patient: capitalizedFirstLetter(firstName) + " " + capitalizedFirstLetter(lastName),
                          Phone,
                          DOB:dayJsMdyFormat(DOB) ?? '---',
                          Insurance:patientInsuranceDetails && patientInsuranceDetails?.length ? patientInsuranceDetails?.[0] : null,
                          Status: category,
                          Date: claimStatuses?.length ? getLastElementFromArray(getLastElementFromArray(claimStatuses)?.dates)?.value : ''
                      }
                  })
              params.successCallback(dataArray, data.count);
              }
              catch(e){
                params.successCallback([], 0);
                failedApiReq(e)
              }
              finally{
                gridApi.hideOverlay();
              }
            }
          }
    
          gridApi.setDatasource(dataSource);
        }
      }, [gridApi]);


    const statusCellRenderer = (params) => {
        const statusValue = params.value;
        return <span style={styles?.statusStyle}>{statusValue}</span>;
    };

    const DetailsButton = ({ data }) => {
        const handleClick = () => {
            navigate({
                pathname: '/Claimdetails1',
                search: `?id=${encodeURIComponent(data?.ID)}`,
            });

        };
       if(data){ return (
            <Button className='primary' style={styles?.viewDetails} onClick={handleClick} >
                View Details
            </Button>
        )}
        else <div></div>
    };
    const columnDefs = () => {
            return [
                // { headerName: 'ID#', field: 'ID',  },
                { headerName: 'Date', field: 'Date', sortable: true, filter: true,width:201,resizable:false },
                { headerName: 'Patient', field: 'Patient', filter: true,resizable:false },
                { headerName: 'Phone', field: 'Phone', filter: true ,width:123,resizable:false},
                { headerName: 'Date of Birth', field: 'DOB', width: 120,resizable:false },
                { headerName: 'Insurance Carrier', field: 'Insurance', width: 165, filter: true,resizable:false },
                { headerName: 'Status', field: 'Status', cellRenderer: statusCellRenderer, width: 226, filter: true,resizable:false },
                { headerName: 'Details', field: 'Details', width: 150, filter: true, cellRenderer: DetailsButton, flex:1,minWidth:143,resizable:false},
            ];
        }

    return (
        <div className="ag-theme-quartz"
            style={sharedStyles?.h500}  >

            
                <AgGridReact
                pagination={true}
                rowModelType={'infinite'}
                paginationPageSize={perPage}
                cacheBlockSize={perPage}
                onGridReady={onGridReady}
                rowHeight={60}
                defaultColDef={{ 
                  // width:236
                  flex:1,
                  minWidth:170
                 }}
                columnDefs={columnDefs()}
                overlayLoadingTemplate={'<span class="ag-overlay-loading-center">Loading...</span>'}
              />
        </div>
    );
};

export default ClaimsPaginatedTable;

