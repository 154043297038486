import { AgGridReact } from 'ag-grid-react';
import React, { useEffect, useState } from 'react'
import toast from 'react-hot-toast';

const CptTable = ({ data }) => {
    const [rowData, setRowData] = useState(null);



    const columnDefs = () => {
        return [
            { headerName: 'ID', field: 'id', filter: true, width: 100, resizable: true },
            { headerName: 'CPT Code', field: 'CptCode', filter: true, width: 200, resizable: true },
            {
                headerName: 'Full Description', field: 'fullDescription', filter: true, width: 400, resizable: true,
            },
            { headerName: 'Long Description', field: 'longDescription', width: 400, filter: true, resizable: true },
            { headerName: 'Short Description', field: 'shortDescription', width: 400, filter: true, resizable: true },
        ];
    };


    const gridOptions = {
        pagination: true,
        paginationPageSize: 10,
        domLayout: 'paginationPrevNext,paginationPageSize,paginationFirstLast',
    };


    useEffect(() => {
        if (data) {
            const rowsData = data?.map(({ cptCode, fullDescription, longDescription, shortDescription, id }) => ({
                id: id || 'N/A',
                CptCode: cptCode,
                fullDescription: fullDescription || 'N/A',
                longDescription: longDescription || 'N/A',
                shortDescription: shortDescription || 'N/A'
            }))
            setRowData(rowsData)
        } else {
            setRowData([])
            toast.error("Cpt Not Found!")
        }
    }, [data])


    return (
        <div className="ag-theme-quartz"
            style={{ height: 500 }}>
            <AgGridReact
                columnDefs={columnDefs()}
                rowData={rowData}
                gridOptions={gridOptions}
                overlayNoRowsTemplate='No Cpt Codes Found!'
            />
        </div>
    )
}

export default CptTable
