import axios from 'axios';
const API_BASE_URL = process.env.REACT_APP_API_URL;

export const getPatientVisitTypes = async (email) => {
    const url = `${API_BASE_URL}/api/BelguaHealth/GetVisitTypesByPatientEmail/${email}`;
    try {
        const response = await axios.get(url);
        return response?.data?.visitTypes ?? false
    } catch (error) {
        return false
        console.log(error)
    }
};


export const getBelugaSurveyInfo = async (email) => {
    const url = `${API_BASE_URL}/api/BelguaHealth/GetBelguaHealthSurveyDataByPatientEmail/${email}`;
    try {
        const response = await axios.get(url);
        return response?.data?.data ?? false
    } catch (error) {
        return false
        console.log(error)
    }
};