import React, {useState} from 'react'
import { sharedStyles } from '../../sharedStyles'
import { useNavigate } from 'react-router-dom';
import { styles } from './styles';
import { Box, ListItemIcon, ListItemText, MenuItem } from '@mui/material';

const Dropdown = ({tabs,verifiedUserNpi,heading,path,active}) => {
    // states
    const [showMenu, setShowMenu] = useState(false)

    // router params
    const navigate = useNavigate();

    // functions
    const handleMenuSelect = (path) => {
        if (verifiedUserNpi) {
            
            navigate(path,{ state: { timeStamp: Date.now() } })
            setShowMenu(false)
        }
    }

    const handleShowMenu = () => {
        verifiedUserNpi && setShowMenu(true)
    }

    const handleCloseMenu = () => {
        verifiedUserNpi && setShowMenu(false)
    }
    
    // main return
    return (
        <div
            style={sharedStyles?.relativePosition}
            onMouseOver={handleShowMenu}
            onMouseLeave={handleCloseMenu}
        >
            <div style={active ? styles?.headerButtonActive : styles?.headerButtonNonActive}
                onClick={() => handleMenuSelect(path)}
            >
                {heading}
            </div>

            {showMenu &&
                <div style={styles?.menuContainer} >
                    {tabs?.length && tabs?.map(({ label, path, icon },index) => (
                        <MenuItem key={label} onClick={() => handleMenuSelect(path)} 
                        sx={{boxShadow: index === 0 &&  sharedStyles?.topShadow}}
                        >
                            <ListItemIcon>{icon}</ListItemIcon>
                            <ListItemText>{label}</ListItemText>
                        </MenuItem>
                    ))}
                </div>
            }

        </div>
    )
}

export default Dropdown