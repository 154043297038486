import React, { useState, useEffect, useMemo } from 'react';
import { Modal, Box, Typography, TextField, Button, IconButton, Divider, ButtonGroup } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import moment from 'moment';
import { createEventModalStyles } from './styles';
import { getLocalData, setLocalData } from '../../utils/localStorageFunctions';
import { PRESET_DURATIONS } from '../../utils/staticData';
import TableHeader from "../../components/TableHeader";

const CreateEventModal = ({ open, onClose, onCreateEvent, selectedDate }) => {
  const [title, setTitle] = useState('');
  const [start, setStart] = useState(selectedDate ?? new Date());
  const [duration, setDuration] = useState(() => {
    const savedDuration = getLocalData('appointmentDuration');
    return savedDuration ?? 30;
  });
  const [patientName, setPatientName] = useState('');
  const [selectedPatient, setSelectedPatient] = useState(null);
  const [notes, setNotes] = useState('');
  const [showCustomDuration, setShowCustomDuration] = useState(false);
  const [customHours, setCustomHours] = useState(0);
  const [customMinutes, setCustomMinutes] = useState(0);

  useEffect(() => {
    if (selectedDate) {
      setStart(new Date(selectedDate));
    }
  }, [selectedDate]);

  useEffect(() => {
    setLocalData('appointmentDuration', duration);
  }, [duration]);

  const endTime = useMemo(() => {
    return new Date(start?.getTime() + duration * 60000);
  }, [start, duration]);

  const handlePatientSelect = (patient) => {
    setSelectedPatient(patient);
    setPatientName(patient ? `${patient?.firstName} ${patient?.lastName}` : '');
  };
  const handleCreateEvent = () => {
    onCreateEvent?.({
      title: `${title} - ${patientName}`,
      start,
      end: endTime,
      patientName,
      patientId: selectedPatient?.id,
      notes,
    });
    resetForm();
  };

  const resetForm = () => {
    setTitle('');
    setStart(new Date());
    setPatientName('');
    setSelectedPatient(null);
    setNotes('');
    setShowCustomDuration(false);
    setCustomHours(0);
    setCustomMinutes(0);
  };

  const handlePresetDuration = (minutes) => {
    setDuration(minutes);
    setShowCustomDuration(false);
  };

  const handleCustomDuration = () => {
    setShowCustomDuration(true);
    setCustomHours(Math.floor(duration / 60));
    setCustomMinutes(duration % 60);
  };

  const applyCustomDuration = () => {
    const totalMinutes = customHours * 60 + customMinutes;
    setDuration(totalMinutes);
    setShowCustomDuration(false);
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box sx={createEventModalStyles?.modalBox}>
        <Box sx={createEventModalStyles?.headerBox}>
          <Typography variant="h5" component="h2">
            Create Appointment
          </Typography>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Divider sx={createEventModalStyles?.divider} />

        <TableHeader
          setFilterPatient={handlePatientSelect}
          idNeeded={true}
        />

        <TextField
          label="Patient Name"
          value={patientName}
          onChange={(e) => setPatientName(e?.target?.value ?? '')}
          sx={createEventModalStyles?.formField}
        />

        <TextField
          label="Appointment Title"
          value={title ?? ''}
          onChange={(e) => setTitle(e?.target?.value ?? '')}
          sx={createEventModalStyles?.formField}
        />
        <TextField
          label="Start Time"
          type="datetime-local"
          value={moment(start).format('YYYY-MM-DDTHH:mm')}
          onChange={(e) => setStart(new Date(e?.target?.value ?? ''))}
          sx={createEventModalStyles?.formField}
          InputLabelProps={{ shrink: true }}
        />
        <TextField
          label="End Time"
          type="datetime-local"
          value={moment(endTime).format('YYYY-MM-DDTHH:mm')}
          InputProps={{
            readOnly: true,
          }}
          sx={createEventModalStyles?.formField}
          InputLabelProps={{ shrink: true }}
        />
        {PRESET_DURATIONS?.length > 0 && (
          <ButtonGroup sx={createEventModalStyles?.buttonGroup}>
            {PRESET_DURATIONS.map((preset) => (
              <Button
                key={preset?.value}
                onClick={() => handlePresetDuration(preset?.value)}
                variant={duration === preset?.value && !showCustomDuration ? 'contained' : 'outlined'}
                sx={createEventModalStyles?.durationButton}
                className={duration === preset?.value && !showCustomDuration ? 'Mui-selected' : ''}
              >
                {preset?.label}
              </Button>
            ))}
            <Button
              onClick={handleCustomDuration}
              variant={showCustomDuration ? 'contained' : 'outlined'}
              sx={createEventModalStyles?.durationButton}
              className={showCustomDuration ? 'Mui-selected' : ''}
            >
              Custom
            </Button>
          </ButtonGroup>
        )}
        {showCustomDuration && (
          <Box sx={createEventModalStyles?.customDurationBox}>
            <TextField
              label="Hours"
              type="number"
              value={customHours}
              onChange={(e) => setCustomHours(Math.max(0, parseInt(e?.target?.value) || 0))}
              InputProps={{ 
                inputProps: { min: 0 },
                sx: createEventModalStyles?.durationInput
              }}
              sx={createEventModalStyles?.durationField}
            />
            <TextField
              label="Minutes"
              type="number"
              value={customMinutes}
              onChange={(e) => setCustomMinutes(Math.max(0, Math.min(59, parseInt(e?.target?.value) || 0)))}
              InputProps={{ 
                inputProps: { min: 0, max: 59 },
                sx: createEventModalStyles?.durationInput
              }}
              sx={createEventModalStyles?.durationField}
            />
            <Button 
              variant="contained" 
              onClick={applyCustomDuration}
              sx={createEventModalStyles?.applyButton}
            >
              Apply
            </Button>
          </Box>
        )}
        <TextField
          label="Notes"
          value={notes}
          onChange={(e) => setNotes(e?.target?.value)}
          multiline
          rows={3}
          sx={createEventModalStyles?.notesField}
        />
        <Button 
          variant="contained" 
          onClick={handleCreateEvent}
          sx={createEventModalStyles?.createButton}
        >
          Create Appointment
        </Button>
      </Box>
    </Modal>
  );
};

export default CreateEventModal;