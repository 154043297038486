import axios from 'axios';
import toast from "react-hot-toast";
const API_BASE_URL = process.env.REACT_APP_API_URL;

export const belugaWeightLossFormSubmit = async (requestData) => {
    const url = `${API_BASE_URL}/api/BelguaHealth/AddBelguaHealth`;
    try {
        const response = await axios.post(url,requestData);
        if (response?.data?.isSuccessful){
            toast.success('Submitted')
            return true
        }
        else return false
    } catch (error) {
        toast?.error(error?.response?.data ?? 'Request Failed')
        console.log(error)
    }
};


export const belugaNewWeightLossFormSubmit = async (requestData) => {
    const url = `${API_BASE_URL}/api/BelguaHealth/AddBelguaHealthResponse`;
    try {
        const response = await axios.post(url,requestData);
        if (response?.data?.isSuccessful){
            toast.success('Submitted')
            return response?.data
        }
        else return false
    } catch (error) {
        toast?.error(error?.response?.data ?? 'Request Failed')
        console.log(error)
    }
};

export const belugaImageUpload = async (data,patientId,visitId,userId, imageType) => {
    const url = `${API_BASE_URL}/api/PatientDocuments/AddPatientDocument/${patientId}/${userId}?visitId=${visitId}`;
    const formData = new FormData();
    
    formData.append('file', data);
    try {
        const response = await axios.post(url, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        if (response?.data?.isSuccessful){
            toast.success(`${imageType} Submitted`)
            return response?.data
        }
        else return false
    } catch (error) {
        toast?.error(error?.response?.data ?? 'Request Failed')
        console.log(error)
    }
}