import React from 'react'

const PregnancyConsentModal = ({setUserConsent,setShowConsent,userConsent,setShowModal}) => {
  return (
    <>
    <div className="modal-overlay" />
    <div className="consent-popup">
        <h2>Consent (Pregnancy)</h2>
        <p>
            Read the following for more information about this product and its potential side effects:
        </p>
        <p>
            It is not safe to take these medications while pregnant or breastfeeding. The FDA advises that these medications may pose a risk to a developing fetus...
        </p>
        {/* Consent Options */}
        <button onClick={() => {
            setUserConsent({...userConsent,pregnancy:true})
            setShowConsent(false)
        }}>
            I acknowledge that I have read and understood the above information
        </button>
        <button 
        onClick={()=>setShowModal(true)}
        >
            I have read the above information and I do not wish to continue
        </button>
    </div>
</>
  )
}

export default PregnancyConsentModal