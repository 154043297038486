import React from "react";
import PatientMainPage from "./PatientMainPage";
import UserMainPage from "./UserMainPage";

const MainPage = ({ userType, handleLogin }) => {
    return (userType === 'patient' ?

        <PatientMainPage handleLogin={handleLogin} />

        :
        <UserMainPage handleLogin={handleLogin} />
    )
};

export default MainPage;