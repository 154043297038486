import { Box, Button, Grid } from '@mui/material'
import React from 'react'
import styles from '../../../components/shared/Csv/styles.module.css'
import { sharedStyles } from '../../../sharedStyles'
import { AgGridReact } from 'ag-grid-react'
import { defGridOptions } from '../../../utils/staticData'
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import PatientInsuranceStatus from '../../../components/cellRenderers/PatientInsuranceStatus'

const ImportedDiscoveryTable = ({ onClick,rowData,handleFirstButton,disabledProcess }) => {
  
  const columnDefs = () => {
    return [
      { headerName: 'First Name', field: 'patientFirstName', width: 120 },
      { headerName: 'Last Name', field: 'patientLastName'},
      { headerName: 'Middle Name', field: 'patientMiddleName'},
      { headerName: 'DOB', field: 'patientDOB' },
      { headerName: 'State', field: 'patientState' },
      { headerName: 'Status', field: '', cellRenderer: (params) => params?.data && PatientInsuranceStatus(params,rowData), flex: 1, minWidth: 140, resizable: false },
    ];
  };

  return (
    <div>
      <div className="ag-theme-quartz" style={sharedStyles?.h500}>
        <AgGridReact
          columnDefs={columnDefs()}
          rowData={rowData}
          gridOptions={defGridOptions}
        />
      </div>

      <Box className={styles?.previewButtonContainer}>
        <Box sx={sharedStyles?.justifyEnd} display={'flex'} gap={2}>
        <Button
            variant='contained'
            color='success'
            className={styles?.downloadCsvTemplate}
            onClick={handleFirstButton} 
          >
            IMPORT ANOTHER FILE
          </Button>
          {
            rowData?.length ?
            <Button
            variant='contained'
            color='success'
            className={styles?.downloadCsvTemplate}
            onClick={onClick}
            disabled={disabledProcess}
          >
            PROCESS
          </Button>
          :
          null
          }
        </Box>
      </Box>
    </div>
  )
}

export default ImportedDiscoveryTable