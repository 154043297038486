// apiService.js
import axios from 'axios';
import toast from 'react-hot-toast';
import {userGetAxios, userPostAxios, userPutAxios} from './userApis'
import { getLocalData } from '../utils/localStorageFunctions';
import { setAllDiscoveryIds } from '../utils/globalFunctions';
import { API_BASE_URL_NEW } from '../utils/staticData';

const API_BASE_URL = process.env.REACT_APP_API_URL;

export const get_discoverydata = async () => {
  const url = `${API_BASE_URL}/api/GetInusuranceDiscoverySummaryResponse/1816`;

  try {
    const response = await axios.get(url);
    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
  }
};
export const post_registrationdata = async (requestData) => {
  const url = `${process.env.REACT_APP_API_URL_NEW}/api/UserRegistration/CreateUserRegistration/`
  try {
    const response = await axios.post(url, requestData, {
      headers: {
        'Content-Type': 'application/json',
        // Add any other headers as needed
      },
    });
    return response.data;
  } catch (error) {
    console.log('Error fetching data:', error);
    toast.error(error?.response?.data ?? 'Failed to register user')
  }
};

export const get_registrationUserByUserId = async (userid) => {
  const url = `${process.env.REACT_APP_API_URL_NEW}/api/UserRegistration/GetUserByUserId?userId=${userid}`;

  try {
    const response = await axios.get(url);
    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    return null;
  }
};

export const post_discoverysearchdata = async (requestData) => {
  const url = `${API_BASE_URL_NEW}/api/InsuranceDiscovery/pVerify/PostInsuranceDiscoveryBySearchParameters`

  try {
    const response = await axios.post(url, requestData, {
      headers: {
        'Content-Type': 'application/json',
        // Add any other headers as needed
      },
    });
    return response.data;
  } catch (error) {
    toast?.error('NPI is not registered. Please contact support to register NPI')
    console.error('Error fetching data:', error);

  }
};

export const get_GetAllInsuranceDiscoveryData = async () => {
  const url = `${API_BASE_URL}/api/InsuranceDiscovery/GetAllInsuranceDiscoveryData`;

  try {
    const response = await axios.get(url);
    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
  }
};

export const exportDiscoveryData = async (startDate, endDate) => {
  const url = `${API_BASE_URL}/api/InsuranceDiscovery/ExportRecords`;

  try {
    const response = await axios.post(url, {
      startDate: startDate.format('YYYY-MM-DD'),
      endDate: endDate.format('YYYY-MM-DD'),
    }, {
      responseType: 'blob'
    });

   
    if (response && response.data) {
      return response.data; 
    } else {
      throw new Error('Invalid response from server');
    }
  } catch (error) {
    console.error('Error exporting discovery data:', error);
    throw error; 
  }
};

export const get_GetInusuranceDiscoveryByPatientId = async (Patiend_id) => {
  const url = `${API_BASE_URL_NEW}/api/InsuranceDiscovery/GetInusuranceDiscoveryByPatientId/${Patiend_id}`;

  try {
    const response = await axios.get(url, {
      headers: {
        'Content-Type': 'application/json',
        // Add any other headers as needed
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
  }
};
//Eligibility summary api
export const get_GetInusuranceDiscoveryByRequestId = async (requestId,patientid,transactionDate) => {
  const url = `${API_BASE_URL}/api/InsuranceDiscovery/pVerify/GetInsuranceDiscoverySummaryResponse/${requestId}/${patientid}/${transactionDate}`;

  try {
    const response = await axios.get(url, {
      headers: {
        'Content-Type': 'application/json',
        // Add any other headers as needed
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    toast.error('Failed to get data')
  }
};

//Eligibility Post api

export const post_eligibilitysearchdata = async (requestData) => {
  const url = `${API_BASE_URL}/api/Eligibility/pVerify/PostEligibilityBySearchParameters`

  try {
    const response = await axios.post(url, requestData, {
      headers: {
        'Content-Type': 'application/json',
        // Add any other headers as needed
      },
    });
    return response.data;
  } catch (error) {
    toast?.error('Error fetching data')
    console.error('Error fetching data:', error);

  }
};

export const get_GetAllEligibilityData = async (pageNumber,pageSize) => {
  const url = `${API_BASE_URL}/api/Eligibility/GetAllEligibilityData${pageNumber ? `?pageNumber=${pageNumber}` : ''}${pageSize ? `&pageSize=${pageSize}` : ''}`;

  try {
    const response = await axios.get(url);
    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
  }
};

export const get_GetAllEligibilityDataByNpiNumber = async (pageNumber, pageSize) => {
  const url = new URL('/api/Eligibility/GetAllEligibilityData', API_BASE_URL_NEW);
  const id =  getLocalData('selectedNpi')?.id
  // query Params
   const params = new URLSearchParams();
   pageNumber && params?.append('pageNumber', pageNumber);
   pageSize && params?.append('pageSize', pageSize);
   id && params?.append('npiNumber',Number(id))
   url.search = params.toString();
  try {
    const response = await axios.get(url);
    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
  }
};

export const get_GeteligibilitydetailsByrequestId = async (requestId,patientid,transactionDate) => {
  const url = `${API_BASE_URL_NEW}/api/Eligibility/pVerify/GetEligibilitySummaryResponse/${requestId}/${patientid}/${transactionDate}`;

  try {
    const response = await axios.get(url, {
      headers: {
        'Content-Type': 'application/json',
        // Add any other headers as needed
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
  }
};

export const get_GetPatientInsuranceDetailsById = async (Patiend_id) => {
  const url = `${API_BASE_URL_NEW}/api/Patient/GetPatientInsuranceDetails/${Patiend_id}`;

  try {
    const response = await axios.get(url, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    return response?.data;
  } catch (error) {
    console.error('Error fetching data:', error);
  }
};

export const update_UpdatePatientInsuranceDetails = async (tabName, Patiend_id) => {
  const url = `${API_BASE_URL_NEW}/api/Patient/UpdatePatientInsuranceDetails/${tabName}/${Patiend_id}`;

  try {
    const response = await axios.post(url, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    return response?.data;
  } catch (error) {
    console.error('Error fetching data:', error);
  }
};


export const AddNpiId = async (requestData) => {
  const url = `${API_BASE_URL_NEW}/api/UserRegistration/AddNpiId/${requestData?.userId}/${requestData?.npiId}`
  try {
    const response = await axios.post(url, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    return response;
  } catch (error) {
    toast?.error(error?.response?.data?.detail ?? 'NpiId already exist.')
  }
};

export const getAllScheduledTransactionsDetails = async (pageNumber,pageSize) => {
  const url = `${API_BASE_URL_NEW}/api/ScheduleTask/GetAllScheduleTasks${pageNumber ? `?pageNumber=${pageNumber}` : ''}${pageSize ? `&pageSize=${pageSize}` : ''}`
  const payload = {
    userId:JSON.parse(localStorage?.getItem('user'))?.id,
    scheduleType:0
  }
    const response = await userPostAxios(url,payload)
    return response?.data;
  
}

export const editScheduleDate = async(id,date) => {
  const url = `${API_BASE_URL_NEW}/api/ScheduleTask/UpdateScheduleTaskDate/${id}/${date}` 
  const response = await userPutAxios(url)
  return response?.data
}

export const checkScheduleTask = async(id) => {
  const url = `${API_BASE_URL_NEW}/api/ScheduleTask/AddScheduleTask/${id}` 
  const payload = {
    userId:JSON.parse(localStorage?.getItem('user'))?.id,
    ScheduleType: 0,
    ScheduleDate: new Date()?.toISOString()?.split('T')[0]
    
}
  const response = await userPostAxios(url,payload)
  return response?.data
}

export const cancelScheduledTask = async(id)=>{
  const url = `${API_BASE_URL_NEW}/api/ScheduleTask/CancelScheduleTask/${id}` 
  const response = await userPutAxios(url)
  return response?.data
}

export const getDashboardCardDetails = async () => {
  const url = `${API_BASE_URL_NEW}/api/Patient/GetMissingInsuranceOrCoverageCounts/${getLocalData('selectedNpi')?.id ?? ''}` 
  const response = await userGetAxios(url)
  return response
}

export const getInsuranceSearchesDetails = async () => {
  const url = `${API_BASE_URL_NEW}/api/ApiCallLog/GetApiCallCounts?endpoint=Post,https://api.pverify.com/API/InsuranceDiscovery` 
  const response = await userGetAxios(url)
  return response
}

export const getInsuranceProvidersDetails = async () => {
  const url = `${API_BASE_URL_NEW}/api/InsuranceDiscovery/GetCommonInsuranceProviders/${getLocalData('selectedNpi')?.id ?? ''}` 
  const response = await userGetAxios(url)
  return response
}

export const getDiscoveryInsuranceTrends = async (selectedFilter) => {
const url = `${API_BASE_URL_NEW}/api/InsuranceDiscovery/GetInsuranceDiscoveryCounts/${getLocalData('selectedNpi')?.id ?? ''}?${selectedFilter ?? ''}` 
const response = await userGetAxios(url)
return response
}

export const getTrendsInInsuranceOverview = async() => {
  const url = `${API_BASE_URL}/api/InsuranceDiscovery/GetInsuranceDiscoveryCounts/${getLocalData('selectedNpi')?.id ?? ''}`
  const response = await userGetAxios(url)
  return response
}

export const checkEligibilityDetails = async (reqId, patientId, transactionDate) => {
  const url = `${API_BASE_URL_NEW}/api/Eligibility/pVerify/GetEligibilitySummaryResponse/${reqId}/${patientId}/${transactionDate}`
  const response = await userGetAxios(url)
  return response
}

export const getNpiState = async () => {
  const url = `${API_BASE_URL}/api/Npi/GetNpiDataByNpiNumber?npiNumber=${getLocalData('selectedNpi')?.npiNumber ?? ''}`
  const response = await userPostAxios(url)
  return response?.data
}

export const get_GetAllInsuranceDiscoveryDataPaginated = async (perPage,page) => {
  const url = `${API_BASE_URL_NEW}/api/InsuranceDiscovery/GetAllInsuranceDiscoveryData?npiId=${getLocalData('selectedNpi')?.id}${page ? `&pageNumber=${page}` : ''}${perPage ? `&pageSize=${perPage}` : ''}`;
  const response = await userGetAxios(url)
  if(response){
    const responseIds = [...new Set(response?.insuranceDiscoveryDtosList?.map(({patientData:{id}})=>id))]
    setAllDiscoveryIds(responseIds)
  }
  return response
};

export const checkEligibilityDetailsFromDiscovery = async (reqId, patientId, transactionDate) => {
  const url = `${API_BASE_URL_NEW}/api/InsuranceDiscovery/pVerify/GetInsuranceDiscoverySummeryResponseFromEligibility/${reqId}/${patientId}/${transactionDate}`
  const response = await userGetAxios(url)
  return response
}

export const downloadCsvTemplate = async()=>{
  const url = `${API_BASE_URL_NEW}/api/InsuranceDiscovery/GenerateEmptyInsuranceDiscoveryCsvTemplateAsync`
  const response = await userPostAxios(url)
  return response?.data
}

export const validateDiscoveryCsvFile = async(file) => {
  const formData = new FormData();
  formData.append("csvFile", file);
  const url = `${API_BASE_URL_NEW}/api/InsuranceDiscovery/ValidateCsvFile`
  const response = await userPostAxios(url,formData,'multipart/form-data')
  return response?.data
}

export const readDiscoveryCsv = async(file) => {
  const formData = new FormData();
  formData.append("csvFile", file);
  const url = `${API_BASE_URL_NEW}/api/InsuranceDiscovery/ReadCsvRecords/${JSON.parse(localStorage?.getItem('user'))?.id}`
  const response = await userPostAxios(url,formData,'multipart/form-data')
  return response?.data
}

export const processInsuranceRecord = async(payload) => {
  const url = `${API_BASE_URL_NEW}/api/InsuranceDiscovery/AddInsuranceDiscovery`
  const data = userPostAxios(url,payload)
  return data
}