import axios from "axios";
import toast from "react-hot-toast";
import { failedRequestStr } from "../utils/staticData";

export const userPostAxios = async (url, payload,contentType) =>{
   try{
    const response = await axios.post(url, payload,{
        headers: {
          'Content-Type': contentType ?? 'application/json',
        },
      });
      return response
   }
  catch(e){
    toast.error(failedRequestStr)
    console.log(e)
  }
}

export const userPutAxios = async (url, payload) =>{
  try{
   const response = await axios.put(url, payload,{
       headers: {
         'Content-Type': 'application/json',
       },
     });
     return response
  }
 catch(e){
   toast.error(failedRequestStr)
   console.log(e)
 }
}

export const userGetAxios = async (url) => {
  try {
    const response = await axios.get(url, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    return response?.data;
  } catch (error) {
    console.error('Error fetching data:', error);
  }
}