import { useState } from "react";
import { 
  Box,
  Typography,
  Card,
  CardContent,
  Chip,
  Grid,
  Divider,
  IconButton,
  Collapse,
  useTheme
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import PersonIcon from "@mui/icons-material/Person";
import LocalHospitalIcon from "@mui/icons-material/LocalHospital";
import EventNoteIcon from "@mui/icons-material/EventNote";
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";
import VisitBasicInfo from "./VisitBasicInfo";
import VisitQA from "./VisitQa";
import { styles } from "./styles";
import { sharedStyles } from "../../../sharedStyles";

const VisitCard = ({ visit, index }) => {
  const [expanded, setExpanded] = useState(false);
  const [showQA, setShowQA] = useState(false);
  const theme = useTheme();
  return (
    <Card 
      sx={styles?.visitCard}
    >
      <CardContent>
        <Box sx={styles?.visitHeading}>
          <PersonIcon sx={styles?.visitCardIcon} />
          <Typography variant="h5" component="div">
            Visit #{index + 1}: {visit?.firstName} {visit?.lastName}
          </Typography>
        </Box>
        <Grid container spacing={2} sx={sharedStyles?.mb2}>
          <Grid item xs={12} sm={6} md={4}>
            <Chip 
              icon={<EventNoteIcon />}
              label={`DOB: ${new Date(visit?.dateOfBirth).toLocaleDateString()}`}
              sx={styles?.cardChip}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Chip 
              icon={<LocalHospitalIcon />}
              label={`Current Dose: ${visit?.currentDose}`}
              color="primary"
              sx={styles?.cardChip}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Chip 
              label={`Visit Type: ${visit?.visitType}`}
              variant="outlined"
              sx={styles?.cardChip}
            />
          </Grid>
        </Grid>
        <Divider sx={sharedStyles?.my2} />
        <Box sx={styles?.cardBasicInfo}>
          <Typography variant="subtitle1" color="text.secondary">
            Basic Information
          </Typography>
          <Box>
            <IconButton onClick={() => setShowQA(!showQA)} sx={sharedStyles?.mr1}>
              <QuestionAnswerIcon />
            </IconButton>
            <IconButton onClick={() => setExpanded(!expanded)}>
              {expanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </Box>
        </Box>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <VisitBasicInfo visit={visit} />
        </Collapse>
        <Collapse in={showQA} timeout="auto" unmountOnExit>
          <VisitQA visit={visit} />
        </Collapse>
      </CardContent>
    </Card>
  );
};
export default VisitCard;