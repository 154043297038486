import React, { useEffect, useState } from 'react'
import DashboardCard from '../../components/DashboardCard/DashboardCard'
import { getInsuranceProviders, insuranceProviderString } from './DashboardFunctions'
import PageLoader from '../../components/loader/PageLoader'

const InsuranceProviders = () => {
    const [data, setData] = useState({payerName:'',count:''})
    const [loading, setLoading] = useState(false)
    useEffect(()=>{
        getInsuranceProviders(setData,setLoading)
    },[])
  return (
    
      loading ? <PageLoader height={'20vh'}/> :
      <DashboardCard heading={'Most Common Insurance Providers '} 
    count={insuranceProviderString(data)} 
    index={0} /> 
  
  )
}

export default InsuranceProviders