import React from 'react'
import ResponsiveHorizontalBox from '../../components/shared/ResponsiveHorizontalBox'
import { Box, CircularProgress, Typography } from '@mui/material'
import CircleWrapper from '../../components/shared/CircleWrapper'
import { MdOutlineCreateNewFolder, MdOutlineNewLabel } from 'react-icons/md'
import { colors } from '../../utils/colors'
import { GrDocumentMissing } from 'react-icons/gr'
import { styles } from './styles'
import { getCountFromArray } from '../../utils/DataFormatters/PatientsOverviewGraphDataFormatters'
import { sharedStyles } from '../../sharedStyles'

const PatientDashboardCards = ({data,isLoading,setSelectedCard}) => {
    return (
        <ResponsiveHorizontalBox>
            {data?.map(({label,data}, index) => {
                return (
                    <Box item xs={12} md={4}
                        width={'100%'}
                        key={index} paddingX={{ xs: 1, sm: 2 }}
                    >
                        <Box boxShadow={3} paddingY={2} paddingX={2} borderRadius={4}
                            marginY={2}
                            onClick={() => setSelectedCard(index + 1)}
                            sx={sharedStyles?.cursorPointerOnly}
                        >
                            <CircleWrapper >
                                {index === 0 ? <MdOutlineCreateNewFolder fill={colors?.themeGreen} /> : index === 1 ? <GrDocumentMissing stroke={colors?.themeGreen} /> : <MdOutlineNewLabel fill={colors?.themeGreen} />}
                            </CircleWrapper>
                            <Typography variant="body1" sx={styles.cardText}>
                                {label}
                            </Typography>
                            <Typography variant="body1" sx={styles.cardValue} color={colors?.themeGreen}>
                            {isLoading ? <CircularProgress color="inherit" size={22}/> :  getCountFromArray(data)}
                            </Typography>
                        </Box>
                    </Box>
                )
            })}
        </ResponsiveHorizontalBox>
    )
}

export default PatientDashboardCards