import React from 'react'
import ErrorrText from '../../../components/shared/ErrorrText';
import { sharedStyles } from '../../../sharedStyles';

const InputGroup = ({ heightFeet, setHeightFeet, heightInches, setHeightInches, weight, setWeight,missingFields }) => (
    <div className="input-group">
        <input className="forms-inputs-one"
            type="text"
            placeholder="Height (feet)"
            value={heightFeet ?? ''} 
            onChange={(e) => setHeightFeet(e.target.value)}
            style={missingFields?.includes("heightFeet") ? sharedStyles?.errorField : null}
        />
        {missingFields?.includes("heightFeet") && <ErrorrText importantField/>}
        <input className="forms-inputs-one"
            type="text"
            placeholder="Height (inches)"
            value={heightInches ?? ''} 
            onChange={(e) => setHeightInches(e.target.value)}
            style={missingFields?.includes("heightInches") ? sharedStyles?.errorField : null}
        />
        {missingFields?.includes("heightInches") && <ErrorrText importantField/>}
            <label className="label-form"><strong>What is your weight in pounds?</strong></label>
            <input className="forms-inputs-one"
                type="text"
                placeholder="Weight (pounds)"
                value={weight ?? ''}
                onChange={(e) => setWeight(e.target.value)}
                style={missingFields?.includes("weight") ? sharedStyles?.errorField : null}
            />
       { missingFields?.includes("weight")  && <ErrorrText importantField/>}
       
    </div>
    
);

export default InputGroup