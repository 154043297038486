import React, { useEffect, useState } from 'react'
import { getAllCDcodes } from '../services/pVerify';

const UseCdCodes = () => {
    const [cdCodes, setCdCodes] = useState([]);


    const getCDCodes = async () => {
        try {
            const data = await getAllCDcodes();
            if (data) {
                setCdCodes(data?.data);
            } else {
                setCdCodes([])
            }
        } catch (error) {
            console.log(error)
        }
    }


    useEffect(() => {
        getCDCodes();
    }, [])

    return { cdCodes }
}

export default UseCdCodes
