import React, { useState } from 'react'
import Dropdown from './Dropdown'
import { Dashboard, ImportExport, Search, TravelExplore } from '@mui/icons-material'

const DiscoveryDropdown = ({verifiedUserNpi,active}) => {
    const [tabs] = useState([
        {label: "Dashboard",path: "/Discoverytab", icon: <Dashboard fontSize="small" /> },
        {label: "Discovery ",path: "/Discoverytab?preSelectedTab=1", icon: <TravelExplore fontSize="small" />},
        {label: "Search", path: "/Discoverytab?preSelectedTab=2",icon:<Search fontSize='small'/>},
        {label: "Import Discovery", path: "/Discoverytab?preSelectedTab=3",icon:<ImportExport fontSize='small'/>},
    ])
  return (
    <Dropdown
    tabs={tabs}
    verifiedUserNpi={verifiedUserNpi}
    heading={'Discovery'}
    path={'/Discoverytab'}
    active={active}
    />
  )
}

export default DiscoveryDropdown