import { CircularProgress, Container, Typography } from "@mui/material";
import VisitCard from "./VisitCard";
import { useEffect, useState } from "react";
import { getBelugaSurveyInfo } from "../../../patientPortal/Services/LandingPage";
import { useAuth0 } from "@auth0/auth0-react";
import PageLoader from "../../../components/loader/PageLoader";
import { styles } from "./styles";

const PatientHistory = () => {
    // constants
    const { user: { email } } = useAuth0()
    const [isLoading, setIsLoading] = useState(false)

    // states
    const [patientHistory, setPatientHistory] = useState([])

    const getHistory = async () => {
        try {
            setIsLoading(true)
            const allHistoryRecord = await getBelugaSurveyInfo(email)
            !!allHistoryRecord?.length && setPatientHistory(allHistoryRecord)
        }
        catch (e) {
            console.log(e)
        }
        finally {
            setIsLoading(false)
        }

    }

    useEffect(() => {
        getHistory()
    }, [])
    return (
        <Container maxWidth="lg" sx={{ py: 4 }}>
            <Typography
                variant="h4"
                component="h1"
                gutterBottom
                sx={styles?.patientHistoryTypography}
            >
                Patient Visit History
            </Typography>
            {
                isLoading ? <PageLoader/>:
                    !!patientHistory?.length && patientHistory?.map((visit, index) => (
                        <VisitCard key={index} visit={visit} index={index} />
                    ))}
        </Container>
    );
};
export default PatientHistory;