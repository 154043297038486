import { Box, Typography, List, ListItem, ListItemText, Divider } from "@mui/material";
const VisitQA = ({ visit }) => {
  const getQuestionsAndAnswers = (visit) => {
    const qaItems = [];
    for (let i = 1; i <= 30; i++) {
      const questionKey = `q${i}`;
      const answerKey = `q${i}A`;
      if (visit[questionKey] && visit[answerKey] !== undefined) {
        qaItems.push({
          question: visit[questionKey],
          answer: visit[answerKey] || "Not answered"
        });
      }
    }
    return qaItems;
  };
  return (
    <Box sx={{ mt: 2 }}>
      <Typography variant="h6" gutterBottom>
        Questions & Answers
      </Typography>
      <List>
        {getQuestionsAndAnswers(visit)?.filter((e) => e?.answer !== 'Not answered')?.map((qa, index) => (
          <ListItem key={index} sx={{ flexDirection: "column", alignItems: "flex-start" }}>
            <ListItemText 
              primary={qa?.question?.split("POSSIBLE ANSWERS:")[0]}
              secondary={qa?.answer}
              primaryTypographyProps={{
                color: "primary",
                fontWeight: "medium",
                sx: { mb: 0.5 }
              }}
              secondaryTypographyProps={{
                color: "text.primary"
              }}
            />
            <Divider sx={{ width: "100%", my: 1 }} />
          </ListItem>
        ))}
      </List>
    </Box>
  );
};
export default VisitQA;