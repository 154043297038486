import React, { useState } from 'react';
import HorizontalHeadingPlusText from '../shared/HorizontalHeadingPlusText';
import { update_UpdatePatientInsuranceDetails } from '../../services/registration';
import { Button, Grid, Paper, Typography } from '@mui/material';
import toast from 'react-hot-toast';
import styles from "../../css/discoveryDetails.module.css";

const EligibilityInnetwork = ({ data, patientInsuranceTabData, tabname, patientId }) => {
  const { leftBox, header, rightBox, button, buttonGrid } = styles; 
  const inNetworkDetails = [
    { name: 'Individual Deductible In-Net', value: data?.hbpC_Deductible_OOP_Summary?.individualDeductibleInNet?.value || data?.hbpC_Deductible_OOP_Summary?.individualDeductibleInNet || 'N/A' },
    { name: 'Individual Deductible Remaining In-Net', value: data?.hbpC_Deductible_OOP_Summary?.individualDeductibleRemainingInNet?.value || data?.hbpC_Deductible_OOP_Summary?.individualDeductibleRemainingInNet || 'N/A' },
    { name: 'Family Deductible In-Net', value: data?.hbpC_Deductible_OOP_Summary?.familyDeductibleInNet?.value || data?.hbpC_Deductible_OOP_Summary?.familyDeductibleInNet || 'N/A' },
    { name: 'Family Deductible Remaining In-Net', value: data?.hbpC_Deductible_OOP_Summary?.familyDeductibleRemainingInNet?.value || data?.hbpC_Deductible_OOP_Summary?.familyDeductibleRemainingInNet || 'N/A' },
    { name: 'Individual OOP In-Net', value: data?.hbpC_Deductible_OOP_Summary?.individualOOP_InNet?.value || data?.hbpC_Deductible_OOP_Summary?.individualOOP_InNet || 'N/A' },
    { name: 'Individual OOP Remaining In-Net', value: data?.hbpC_Deductible_OOP_Summary?.individualOOPRemainingInNet?.value || data?.hbpC_Deductible_OOP_Summary?.individualOOPRemainingInNet || 'N/A' },
    { name: 'Family OOP In-Net', value: data?.hbpC_Deductible_OOP_Summary?.familyOOPInNet?.value || data?.hbpC_Deductible_OOP_Summary?.familyOOPInNet || 'N/A' },
    { name: 'Family OOP Remaining In-Net', value: data?.hbpC_Deductible_OOP_Summary?.familyOOPRemainingInNet?.value || data?.hbpC_Deductible_OOP_Summary?.familyOOPRemainingInNet || 'N/A' }
  ];

  const [patientInsuranceDetailTabData, setPatientInsuranceDetailTabData] = useState(patientInsuranceTabData); 

  const handleSavePatientsDetails = async (tabName, patientId) => {
    try {
      const patientUpdatedData = await update_UpdatePatientInsuranceDetails(tabName, patientId);
      if (patientUpdatedData?.data?.insuranceDiscoveryEntities?.[0]) {
        setPatientInsuranceDetailTabData(patientUpdatedData?.data?.insuranceDiscoveryEntities?.[0]);
      } else {
        toast('Innetwork detail is empty');
      }
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={12} md={5}>
          <Paper elevation={10} className={leftBox}>
            <Typography variant="h5" align="center" className={header}>
              Payer
            </Typography>
            <Typography align="center" variant="h5" className={header}>
              {tabname === "detail"
                ? "Plan Details"
                : tabname === "coverage"
                ? "Coverage Details"
                : tabname === "DemographicInfo"
                ? "DemographicInfo Details"
                : tabname === "Innetwork"
                ? "In Network"
                : tabname === "Outofnetwork"
                ? "Out of Network"
                : tabname === "Speciality"
                ? "Speciality"
                : ""}
            </Typography>
            <hr/>
            {inNetworkDetails?.length && inNetworkDetails?.map((detail, index) => (
              <HorizontalHeadingPlusText heading={detail?.name} text={detail?.value} key={index} />
            ))}
          </Paper>
        </Grid>

        {/* Button */}
        <Grid item xs={12} md={2} className={buttonGrid}>
          <Button variant="outlined" className={button} onClick={()=>handleSavePatientsDetails(tabname, patientId)} >Sync with</Button>
        </Grid>

        <Grid item xs={12} md={5}>
          <Paper elevation={10} className={rightBox}>
            <Typography variant="h5" align="center" className={header}>
              Practice
            </Typography>
            <Typography align="center" variant="h5" className={header}>
              {tabname === "detail"
                ? "Plan Details"
                : tabname === "coverage"
                ? "Coverage Details"
                : tabname === "DemographicInfo"
                ? "DemographicInfo Details"
                : tabname === "Innetwork"
                ? "In Network"
                : tabname === "Outofnetwork"
                ? "Out of Network"
                : tabname === "Speciality"
                ? "Speciality"
                : ""}
            </Typography>
            <hr />
            <HorizontalHeadingPlusText heading={'Individual Deductible In-Net'} text={patientInsuranceDetailTabData?.hbpC_Deductible_OOP_Summary
                      ?.individualDeductibleInNet?.value || patientInsuranceDetailTabData?.hbpC_Deductible_OOP_Summary
                      ?.individualDeductibleInNet || 'N/A'} />
            <HorizontalHeadingPlusText heading={'Individual Deductible Remaining In-Net'} text={patientInsuranceDetailTabData?.hbpC_Deductible_OOP_Summary
                      ?.individualDeductibleRemainingInNet?.value || patientInsuranceDetailTabData?.hbpC_Deductible_OOP_Summary
                      ?.individualDeductibleRemainingInNet || 'N/A'} />
            <HorizontalHeadingPlusText heading={'Family Deductible In-Net'} text={patientInsuranceDetailTabData?.hbpC_Deductible_OOP_Summary?.familyDeductibleInNet?.value || patientInsuranceDetailTabData?.hbpC_Deductible_OOP_Summary?.familyDeductibleInNet || 'N/A'} />
            <HorizontalHeadingPlusText heading={'Family Deductible Remaining In-Net'} text={patientInsuranceDetailTabData?.hbpC_Deductible_OOP_Summary
                      ?.familyDeductibleRemainingInNet?.value || patientInsuranceDetailTabData?.hbpC_Deductible_OOP_Summary
                      ?.familyDeductibleRemainingInNet || 'N/A'} />
            <HorizontalHeadingPlusText heading={'Individual OOP In-Net'} text={patientInsuranceDetailTabData?.hbpC_Deductible_OOP_Summary
                      ?.individualOOP_InNet?.value || patientInsuranceDetailTabData?.hbpC_Deductible_OOP_Summary
                      ?.individualOOP_InNet || 'N/A'} />
            <HorizontalHeadingPlusText heading={'Individual OOP Remaining In-Net'} text={patientInsuranceDetailTabData?.hbpC_Deductible_OOP_Summary
                      ?.individualOOPRemainingInNet?.value || patientInsuranceDetailTabData?.hbpC_Deductible_OOP_Summary
                      ?.individualOOPRemainingInNet || 'N/A'} />
            <HorizontalHeadingPlusText heading={'Family OOP In-Net'} text={patientInsuranceDetailTabData?.hbpC_Deductible_OOP_Summary
                      ?.familyOOPInNet?.value || patientInsuranceDetailTabData?.hbpC_Deductible_OOP_Summary
                      ?.familyOOPInNet || 'N/A'} />
            <HorizontalHeadingPlusText heading={'Family OOP Remaining In-Net'} text={patientInsuranceDetailTabData?.hbpC_Deductible_OOP_Summary
                      ?.familyOOPRemainingInNet?.value || patientInsuranceDetailTabData?.hbpC_Deductible_OOP_Summary
                        ?.familyOOPRemainingInNet || 'N/A'} />
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
};

export default EligibilityInnetwork;
