import React from 'react'
import useCptCodes from '../../custom-hooks/useCptCodes'
import CptTable from '../../components/table/CptTable';

const CptSearch = () => {
const { cptCodes } = useCptCodes();

  return (
    <div>
      <CptTable data={cptCodes} />
    </div>
  )
}

export default CptSearch
