import React, { useEffect, useState } from 'react'
import ImportedDiscoveryTable from './Tables/ImportedDiscoveryTable'
import { useLocation, useNavigate } from 'react-router-dom';
import MyTabs from '../../components/tabs/MyTabs';
import { processPatientInsuranceRecords } from './DiscoveryFunctions';
import TabContainer from '../../components/containers/TabContainer';
import { getLocalData, setLocalData } from '../../utils/localStorageFunctions';

const ImportDiscoveryPreview = () => {
    // router params
    const location = useLocation();
    const navigate = useNavigate()

    // states
    const [rowDataCopy, setRowDataCopy] = useState(getLocalData('bulkInsuranceData'));
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        setLocalData('bulkInsuranceData', rowDataCopy)
    }, [rowDataCopy])
    const tabs = [
        {
            label: "Results",
            value:
                <ImportedDiscoveryTable
                    onClick={() => processPatientInsuranceRecords(rowDataCopy, setRowDataCopy,setIsLoading)}
                    rowData={rowDataCopy}
                    handleFirstButton={() => navigate('/Discoverytab?preSelectedTab=3')}
                    disabledProcess={isLoading}
                    />,
            button: 'Back', goBack: true
        }]

    // main return
    return (
        <TabContainer key={location?.key}>
            <MyTabs tabs={tabs} />
        </TabContainer>

    )
}

export default ImportDiscoveryPreview
