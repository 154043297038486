import React, { useState, useEffect, useCallback } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import EventDetailsModal from './EventDetailsModal';
import CreateEventModal from './CreateEventModal';
import EditEventModal from './EditEventModal';
import { appointmentCalendarStyles } from './styles';
import { appointmentConstants } from '../../utils/staticData';
import { getAllAppointments, addAppointment, updateAppointment, getAppointmentById, deleteAppointment } from '../../services/appointmentService';
import toast from 'react-hot-toast';
import TableHeader from '../../components/TableHeader';
import { get_PatientAppointments } from '../../services/addPatient';
import { Box, Grid, Typography } from '@mui/material';
import { sharedStyles } from '../../sharedStyles';
import PageLoader from '../../components/loader/PageLoader';

const localizer = momentLocalizer(moment);

const AppointmentCalendar = () => {
  const [events, setEvents] = useState([]);
  const [showEventDetailsModal, setShowEventDetailsModal] = useState(false);
  const [showCreateEventModal, setShowCreateEventModal] = useState(false);
  const [showEditEventModal, setShowEditEventModal] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);
  const [refetchAllPatients, setRefetchAllPatients] = useState(0)
  const [selectedPatient, setSelectedPatient] = useState('')
  const [selectedPatientId, setSelectedPatientId] = useState('');
  const [showAppointments, setShowAppointments] = useState(false);
  const [appointmentsOfSearchedPatient, setShowAppointmentsOfSearchedPatient] = useState([])
  const [passedAppointments, setPassedAppointments] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const formatEvent = useCallback((event) => ({
    id: event?.id ?? event?._id,
    title: event?.appointmentTitle,
    start: new Date(event?.serviceStartTime),
    end: new Date(event?.serviceEndTime),
    patientName: event?.patientName,
    notes: event?.notes1 ?? ''
  }), []);



  const fetchAppointments = useCallback(async () => {
    try {
      const response = await getAllAppointments();
      if (Array.isArray(response)) {
        const formattedEvents = response.map(formatEvent);
        setEvents(formattedEvents);
       
      } else if (response && typeof response === 'object') {
        const appointmentsArray = response?.appointments ?? response?.data ?? [];
        if (Array.isArray(appointmentsArray)) {
          const formattedEvents = appointmentsArray.map(formatEvent);
          setEvents(formattedEvents);
        } else {
          toast.error('Received unexpected data format from server');
        }
      } else {
        toast.error('Received unexpected data format from server');
      }
    } catch (error) {
      console.error('Error fetching appointments:', error);
      toast.error('Failed to fetch appointments');
    }
  }, [formatEvent]);
  useEffect(() => {
    fetchAppointments();

    const handleFocus = () => {
      fetchAppointments();
    };

    window.addEventListener('focus', handleFocus);

    const intervalId = setInterval(fetchAppointments, 5 * 60 * 1000);

    return () => {
      window.removeEventListener('focus', handleFocus);
      clearInterval(intervalId);
    };
  }, [fetchAppointments]);

  const eventStyleGetter = useCallback(() => ({
    style: appointmentCalendarStyles?.event
  }), []);

  const handleSelectEvent = useCallback((event) => {
    setSelectedEvent(event);
    setShowEventDetailsModal(true);
  }, []);

  const handleCloseEventDetailsModal = useCallback(() => {
    setShowEventDetailsModal(false);
    setSelectedEvent(null);
  }, []);

  const handleSelectSlot = useCallback(({ start }) => {
    const currentDate = new Date();
    if (start < currentDate.setHours(0, 0, 0, 0)) {
      toast.error(appointmentConstants?.pastAppointmentMessage);
    } else {
      setShowCreateEventModal(true);
      setSelectedDate(new Date(start));
    }
  }, []);

  const handleCloseCreateEventModal = useCallback(() => {
    setShowCreateEventModal(false);
    setSelectedDate(null);
  }, []);

  const handleCloseEditModal = useCallback(() => {
    setShowEditEventModal(false);
    setSelectedEvent(null);
  }, []);

  const handleCreateEvent = useCallback(async (newEvent) => {
    try {
      const payload = {
        appointmentTitle: newEvent?.title,
        patientId: appointmentConstants.patientId,
        providerId: appointmentConstants?.providerId,
        appointmentDate: newEvent?.start?.toISOString(),
        serviceStartTime: newEvent?.start?.toISOString(),
        serviceEndTime: newEvent?.end?.toISOString(),
        createBy: "admin",
        notes: {
          notes1: newEvent?.notes
        }
      };

      const response = await addAppointment(payload);

      if (response && Object.keys(response).length > 0) {
        const createdEvent = formatEvent(response);
        setEvents(prevEvents => [...prevEvents, createdEvent]);
        await fetchAppointments();
        handleCloseCreateEventModal();
        toast.success(appointmentConstants?.successMessage);
      } else {
        console.log("Empty or invalid response received")
      }
    } catch (error) {
      console.error('Error creating appointment:', error);
      toast.error('Failed to create appointment: ' + (error.message || 'Unknown error'));
    }
  }, [formatEvent, fetchAppointments, handleCloseCreateEventModal]);

  const handleEditEvent = useCallback(async (editedEvent) => {
    try {
      if (!editedEvent?.id) {
        return null;
      }

      const existingAppointmentResponse = await getAppointmentById(editedEvent.id);

      if (!existingAppointmentResponse) {
        return null;
      }

      const existingAppointment = existingAppointmentResponse;

      const payload = {
        appointmentTitle: editedEvent?.title ?? existingAppointment?.appointmentTitle,
        patientId: appointmentConstants.patientId,
        providerId: appointmentConstants?.providerId,
        appointmentDate: editedEvent?.start?.toISOString() ?? existingAppointment?.appointmentDate,
        serviceStartTime: editedEvent?.start?.toISOString() ?? existingAppointment?.serviceStartTime,
        serviceEndTime: editedEvent?.end?.toISOString() ?? existingAppointment?.serviceEndTime,
        createBy: existingAppointment?.createBy,
        createAt: existingAppointment?.createAt,
        notes: {
          notes1: editedEvent?.notes ?? existingAppointment?.notes?.notes1,
          notes2: existingAppointment?.notes?.notes2
        }
      };

      const response = await updateAppointment(editedEvent?.id, payload);

      if (!response) {
        console.log("Failed to update appointment")
      }

      const updatedEvent = formatEvent(response);

      if (!updatedEvent?.id) {
        console.log('Invalid updated event data')
       }    

      setEvents(prevEvents => prevEvents?.map(event =>
        event?.id === updatedEvent?.id ? updatedEvent : event
      ));

      await fetchAppointments();

      handleCloseEditModal();
      toast.success(appointmentConstants?.updateMessage);
    } catch (error) {
      console.error('Error updating appointment:', error);
      toast.error(`Failed to update appointment: ${error.message}`);
    }
  }, [formatEvent, fetchAppointments, handleCloseEditModal]);

  const handleDeleteEvent = useCallback(async (eventId) => {
    try {
      if (!eventId) {
        console.log('Invalid event ID')
      }

      await deleteAppointment(eventId);

      setEvents(prevEvents => prevEvents?.filter(event => event?.id !== eventId));
      await fetchAppointments();

      handleCloseEventDetailsModal();
      toast.success(appointmentConstants.deleteMessage);
    } catch (error) {
      console.error('Error deleting appointment:', error);
      toast.error(`Failed to delete appointment: ${error?.message || 'Unknown error'}`);
    }
  }, [fetchAppointments, handleCloseEventDetailsModal]);


  const get_patient_appointments = async () => {
    setIsLoading(true)
    try {
      const response = await get_PatientAppointments(selectedPatientId);
      response?.data?.map(item => {
        const isAhead = moment().isAfter(item?.createAt);
        if (isAhead) {
          setPassedAppointments(response?.data);
        } else {
          setShowAppointmentsOfSearchedPatient(response?.data)
        }
      })
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoading(false)
    }
  };


  const Badge = ({ item }) => {
    const daysRemaining = moment(item?.createAt).diff(moment(item?.appointmentDate), 'days');
    const isAhead = moment().isAfter(item?.createAt);

    return (
      <Box sx={[sharedStyles?.badgeStyle, isAhead && sharedStyles?.expiredBadge]}>
        <Typography variant='body1'>{
          daysRemaining === 1 ? `Appointment in ${daysRemaining} day`
            : isAhead ? 'Your Appointment has passed'
              : !isAhead && daysRemaining == 0 ? `Your Appointment is ${moment().calendar()}`
                : `Appointment in ${daysRemaining} days`}</Typography>
      </Box>
    )
  };


  const handleOpenEditModal = useCallback(() => {
    const currentDate = new Date();
    if (selectedEvent?.start < currentDate.setHours(0, 0, 0, 0)) {
      toast.error(appointmentConstants?.pastEditMessage);
    } else {
      setShowEditEventModal(true);
      setShowEventDetailsModal(false);
    }
  }, [selectedEvent]);


  useEffect(() => {
    if (selectedPatientId) {
      get_patient_appointments()
      setShowAppointments(true);
    } else {
      setShowAppointments(false);
      setPassedAppointments([]);
      setShowAppointmentsOfSearchedPatient([]);
    }
  }, [selectedPatientId])



  return (
    <>
      <TableHeader idNeeded setFilterPatient={setSelectedPatient} refetch={refetchAllPatients} setSelectedPatientId={setSelectedPatientId} />
      {
        showAppointments ?
          <>
            <Box sx={[sharedStyles?.appHeader, sharedStyles?.mb1]}>
              <Typography variant='h6'>Upcoming Appointments</Typography>
            </Box>
            {
              isLoading ? <PageLoader />
                :
                <Grid container sx={sharedStyles?.responsivePad} gap={4}>
                  {
                    !appointmentsOfSearchedPatient?.length ?
                      <Grid item lg={6}>
                        <Typography variant='body1'>No upcoming appointments for this patient!</Typography>
                      </Grid>
                      :
                      appointmentsOfSearchedPatient?.map(item => (
                        <Grid sx={sharedStyles?.themeCard} key={item?.id} item xs={12} sm={12} md={6} lg={4}>
                          <Typography variant='h5'>{item?.appointmentTitle}</Typography>
                          <Box sx={sharedStyles?.innerThemeCard}>
                            <Typography variant='body2'>Type: {item?.type}</Typography>
                            <Typography variant='body2'>Start Time: {new Date(item?.serviceStartTime).toLocaleTimeString()}</Typography>
                            <Typography variant='body2'>End Time: {new Date(item?.serviceEndTime).toLocaleTimeString()}</Typography>
                            <Typography variant='body2'>Appointment Date: {new Date(item?.appointmentDate).toDateString()}</Typography>
                            <Typography variant='body2'>Appointment Created at: {new Date(item?.createAt).toDateString()}</Typography>
                            <Box>
                              <Typography variant='caption'>Notes:</Typography>
                              <ol>
                                {

                                }
                                <li>{item?.notes?.notes1}</li>
                                <li>{item?.notes?.notes2 ? item?.notes?.notes2 : 'Not Found!'}</li>
                              </ol>
                            </Box>
                          </Box>
                          <Badge item={item} />
                        </Grid>
                      ))
                  }
                </Grid>
            }
            <Box sx={[sharedStyles?.appHeader, sharedStyles?.mb1]}>
              <Typography variant='h6'>Past Appointments</Typography>
            </Box>
            {
              isLoading ? <PageLoader />
                :
                <Grid container sx={sharedStyles?.responsivePad} gap={4}>
                  {
                    !passedAppointments?.length ?
                      <Grid item lg={6}>
                        <Typography variant='body1'>No past appointments for this patient!</Typography>
                      </Grid>
                      :
                      passedAppointments?.map(item => (
                        <Grid sx={sharedStyles?.themeCard} key={item?.id} item xs={12} sm={12} md={6} lg={4}>
                          <Typography variant='h5'>{item?.appointmentTitle}</Typography>
                          <Box sx={sharedStyles?.innerThemeCard}>
                            <Typography variant='body2'>Type: {item?.type}</Typography>
                            <Typography variant='body2'>Start Time: {new Date(item?.serviceStartTime).toLocaleTimeString()}</Typography>
                            <Typography variant='body2'>End Time: {new Date(item?.serviceEndTime).toLocaleTimeString()}</Typography>
                            <Typography variant='body2'>Appointment Date: {new Date(item?.appointmentDate).toDateString()}</Typography>
                            <Typography variant='body2'>Appointment Created at: {new Date(item?.createAt).toDateString()}</Typography>
                            <Box>
                              <Typography variant='caption'>Notes:</Typography>
                              <ol>
                                {

                                }
                                <li>{item?.notes?.notes1}</li>
                                <li>{item?.notes?.notes2 ? item?.notes?.notes2 : 'Not Found!'}</li>
                              </ol>
                            </Box>
                          </Box>
                          <Badge item={item} />
                        </Grid>
                      ))
                  }
                </Grid>
            }
          </>
          :
          <div style={appointmentCalendarStyles?.calendarContainer}>
            <Calendar
              key={events.length}
              localizer={localizer}
              events={events}
              startAccessor="start"
              endAccessor="end"
              style={appointmentCalendarStyles?.calendar}
              onSelectEvent={handleSelectEvent}
              onSelectSlot={handleSelectSlot}
              selectable
              eventPropGetter={eventStyleGetter}

            />
            <EventDetailsModal
              open={showEventDetailsModal}
              onClose={handleCloseEventDetailsModal}
              event={selectedEvent}
              onEdit={handleOpenEditModal}
              onDelete={handleDeleteEvent}
            />
            <CreateEventModal
              open={showCreateEventModal}
              onClose={handleCloseCreateEventModal}
              onCreateEvent={handleCreateEvent}
              selectedDate={selectedDate}
            />
           <EditEventModal
              open={showEditEventModal}
              onClose={handleCloseEditModal}
              onEditEvent={handleEditEvent}
              event={selectedEvent}
            />
          </div>
      }
    </>
  );
};

export default AppointmentCalendar;