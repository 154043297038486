import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import '@aws-amplify/ui-react/styles.css';
import { Box, CircularProgress, Input } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { post_registrationdata } from '../../services/registration';
import { get_registrationUserByUserId } from '../../services/registration';
import '@aws-amplify/ui-react/styles.css';
import { useAuth0 } from '@auth0/auth0-react';
import { sharedStyles } from "../../sharedStyles";
import { clearAllLocalData } from "../../utils/localStorageFunctions";
import { logoutRedirection } from "../../utils/reusableFunctions";


const Registration = () => {
  const [userDetails, setUserDetails] = useState({
    userId: null,
    userName: null,
    cognitoUserId: null,
    firstName: null,
    lastName: null,
    doctorNpi: null,
    practiceNpi: null,
    practiceAddress: null,
    practiceCity: null,
    practiceState: null,
    jobTitle: null
  })
  const { user, isAuthenticated, logout, getIdTokenClaims, isLoading, getAccessTokenSilently, } = useAuth0();
  const [emailError, setEmailError] = useState("");
  const [loading, setLoading] = useState(false)

  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserDetails(prevState => ({
      ...prevState,
      [name]: value
    }));
    // Validate email format
    if (name === 'email') {
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailPattern.test(value)) {
        setEmailError('Please enter a valid email address.');
      } else {
        setEmailError('');
      }
    }
  };

  const fetchUserAttributes = async () => {
    if (isAuthenticated) {
      try {
        // Get ID token claims
        const idTokenClaims = await getIdTokenClaims();
        const userId = idTokenClaims['sub']; // user ID
        const email = idTokenClaims['email'];
        const firstName = idTokenClaims['nickname'];
        const lastName = idTokenClaims['family_name'];
        setUserDetails(prevState => ({
          ...prevState,
          email: email,
          firstName: firstName,
          lastName: lastName
        })); // Set user details in state
      }
      catch (error) {
        console.error('Error fetching user attributes:', error);
      }
    }
  };

  useEffect(() => {
    fetchUserAttributes();
  }, [isAuthenticated]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true)

    // Get ID token claims
    const idTokenClaims = await getIdTokenClaims();

    if (idTokenClaims?.sub && idTokenClaims?.email) {
      const userData = {
        ...userDetails, userId: idTokenClaims.sub,
        userName: idTokenClaims?.email,
        doctorNpi:Number(userDetails?.doctorNpi),
        practiceNpi: userDetails?.practiceNpi ? Number(userDetails?.practiceNpi) : null
      }

      try {
        const postsearchdata = await post_registrationdata(userData);
        if (postsearchdata) {
          try {
            const data = await get_registrationUserByUserId(idTokenClaims?.sub);
            if (data?.isSuccessful) {
              localStorage.setItem('user', JSON.stringify(data?.data))
              localStorage.setItem('selectedNpi', JSON.stringify(data?.data?.npiInfoList[0]));
              navigate('/home');
            }


          }
          catch (error) {
            console.log('Error fetching user attributes:', error);
          }
        }

      } catch (error) {
        console.log('Error fetching data:', error);
        alert(error.response.data.errorMessage);
      }
      finally{
        setLoading(false)
      }
    }



  }
  const handleLogin = () => {
    clearAllLocalData()
    logout({ logoutParams: { returnTo:logoutRedirection() } })
  };
  return (
    <>
      <div>
        <>
          <div className="registeration-header">
            {/* <img src="/logos/AppZ-logo.png" alt="Appz-logo" className="" /> */}
            <h4>Profile Registration</h4>

          </div>
          <Box sx={{
            width: '80%',
            boxShadow: '-3px -2px 24px 13px #eee',
            margin: '2em auto',
            height: '100%',
            paddingBottom: '2em'
          }}>
            <section className='form'>
              <div className='form-header'>
                <div className='form-header-inner'>
                  <h1>Welcome to App Z Health</h1>
                  <p>
                    In order to continue, we need you to verify and complete the below information.
                    Not all fields are required. Additional fields help narrow your search results.
                  </p>

                </div>

              </div>
              <form action="" onSubmit={handleSubmit} style={{ textAlign: 'center' }} className='registration'>
                <div className='grid-container'>
                  <Input type='text' onChange={handleChange} name='email' placeholder='Email*' value={userDetails.email} required />
                  <Input type='text' onChange={handleChange} name='phone' placeholder='Phone*' inputProps={{ maxLength: 10 }} required />
                  <Input type='text' onChange={handleChange} name='firstName' value={userDetails.firstName}
                    placeholder='First Name*' required />
                  <Input type='text' onChange={handleChange} name='lastName' placeholder='Last Name*' value={userDetails.lastName} required />
                  <Input type="number" onChange={handleChange} name='doctorNpi' inputProps={{ maxLength: 10 }} placeholder='Doctor NPI*' required />
                  <Input type='number' onChange={handleChange} name='practiceNpi' inputProps={{ maxLength: 10 }} placeholder='Practice NPI' />
                  <Input type='text' onChange={handleChange} name='practiceAddress' placeholder='Practice Address' />
                  <Input type='text' onChange={handleChange} name='practiceCity' placeholder='Practice City' />
                  <Input type='text' onChange={handleChange} name='practiceState' placeholder='Practice State' />
                  <Input type='text' onChange={handleChange} name='jobTitle' placeholder='Job Title' />
                </div>
                {/* <button type='submit' style={{ marginTop: "4em" }}>Register Now</button> */}
                <Button type='submit'  variant='contained'  color='success' sx={sharedStyles?.mt2em} disabled={loading}>
                {loading ? <CircularProgress color="inherit" size={17}/> :'Register Now'}
                </Button>
                <Box>
                <Button variant='contained'  sx={{...sharedStyles?.mt5px,...sharedStyles?.resetButtonOnly}} onClick={handleLogin}>
                Log Out
                </Button>
                </Box>
                {/* <button color="inherit" style={{ marginTop: "4em", }} onClick={handleLogin}>X</button> */}

              </form>
            </section>
          </Box>

        </>


      </div>



    </>

  )


}
export default Registration; 
