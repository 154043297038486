import React from 'react'
import { sharedStyles } from '../../sharedStyles'
import { styles } from './styles'
import { Box, Button, Typography } from '@mui/material'

const UserMainPage = ({handleLogin}) => {
  return (
    <Box
    sx={styles?.userMainPageContainer}
>
    <img
        src={require('../../../src/assets/images/appzLogo.png')}
        alt={`Logo`}
        style={styles?.userLogo}
    />

    <Typography variant="h4" component="h1" sx={sharedStyles?.mb2}>
        {"Welcome to AppzHealth"}
    </Typography>

    <Button
        variant="contained"
        color="primary"
        size="large"
        onClick={handleLogin}
        sx={styles?.userLoginButton}
    >
        Login
    </Button>
</Box>
  )
}

export default UserMainPage