import React, { useEffect, useState } from 'react'
import MyTabs from '../../components/tabs/MyTabs'
import { Box } from '@mui/material'
import { useLocation, useSearchParams } from 'react-router-dom'
import PatientDashboard from './PatientDashboard'
import AppointmentCalendar from './AppointmentCalendar'
import { sharedStyles } from '../../sharedStyles';
import Schedule from './DoctorTimings'

const Appointments = () => {
    const [searchParams] = useSearchParams();
    const preSelectedTabParam = searchParams.get('preSelectedTab');
    const [preSelectedTab, setPreSelectedTab] = useState(preSelectedTabParam ? Number(preSelectedTabParam) : 0);
    const location = useLocation()
    const tabs = [
        {
            label: "Dashboard",
            value: <PatientDashboard />,
            button: 'Add Patient',
            link: true,
            gotToTab: 2
        },
        {
            label: 'Appointments',
            value: <AppointmentCalendar />,
            changeTab: 2,
            button: 'Add Patient',
            link: true,
            gotToTab: 2
        },
        {
            label: 'Doctor Availability',
            value: <Schedule />,
            changeTab: 2,
        }
    ]

    useEffect(() => {
        setPreSelectedTab(preSelectedTabParam ? Number(preSelectedTabParam) : 0);
    }, [preSelectedTabParam]);

    return (
        <section className='patients' key={location?.key}>
            <Box style={sharedStyles?.tabWrapper}>
                <MyTabs tabs={tabs} preSelectedTab={preSelectedTab} />
            </Box>
        </section>
    )
}

export default Appointments;