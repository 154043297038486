import React, { useEffect, useState } from 'react';

import "../Questionnaire/FullQuestionnaire.css"
import "../Questionnaire/PersonalInfo.css";
import { VERIFICATION_TEXT, CONSENT_TEXT, CONSENT_DECLINE_TEXT, unAcceptedConsentStr } from "../../utils/staticData"
import DisqualifiedModal from '../Questionnaire/DisqualifiedModal';
import ErrorrText from '../../components/shared/ErrorrText';



const ConsentSection = ({ consentsSigned, setConsentsSigned, setUserConsent,
    userConsent,failedValidations }) => {
    const [truthfulnessConsent, setTruthfulnessConsent] = useState("");
    const [showModal, setShowModal] = useState(false)

    const handleTruthfulnessConsentChange = (value) => {
        setTruthfulnessConsent(value)
        if (value === 'yes') {
            setUserConsent({ ...userConsent, truthFull: true })
        }
        else {
            setShowModal(true)
            setUserConsent({ ...userConsent, truthFull: false })
        }
    };

    useEffect(()=>{
const {truthFull} = userConsent
truthFull ? setTruthfulnessConsent('yes') : setTruthfulnessConsent('no')
    },[userConsent])

    return (
        <div>
            {/* Consent (Truthfulness) */}
            <div className="consent-section">
                <h3 className="form1-h3">Consent (Truthfulness)</h3>
                <p>
                    {VERIFICATION_TEXT}
                </p>
                <label className="label-form">
                    <input className="form-checkbox-radio"
                        type="radio"
                        name="truthfulnessConsent"
                        value="yes"
                        checked={truthfulnessConsent === "yes"}
                        onChange={(e) => handleTruthfulnessConsentChange('yes')}
                    />
                    <span>{CONSENT_TEXT}</span>
                </label>
                <label className="label-form">
                    <input className="form-checkbox-radio"
                        type="radio"
                        name="truthfulnessConsent"
                        value="no"
                        checked={truthfulnessConsent === "no"}
                        onChange={(e) => handleTruthfulnessConsentChange('no')}
                    />
                    <span>{CONSENT_DECLINE_TEXT}</span>
                </label>
            </div>

            <DisqualifiedModal
                open={showModal}
                handleClose={() => setShowModal(false)}
                onOk={() => {
                    setUserConsent({ ...userConsent, truthFull: true })
                    setTruthfulnessConsent('yes')
                    setShowModal(false)

                }}
                onCancel={() => {
                    setUserConsent({ ...userConsent, truthFull: false })
                    setShowModal(false)
                }}
            />
            {failedValidations?.includes('truthfulnessConsent') && <ErrorrText text={unAcceptedConsentStr}/>}
        </div>
    );
};

export default ConsentSection;