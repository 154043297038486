import React, { useEffect, useRef, useState } from 'react';
import './Weightlossfollowup.css';
import QuestionTwo from './Questions/QuestionTwo';
import QuestionOne from './Questions/QuestionOne';
import {
    QuestionFive, QuestionSix, QuestionSeven,
    QuestionEight,
    QuestionEleven,
    Question14,
    QuestionED,
    QuestionPrescribed
} from '../QuestionnaireFollowUp/Questions/Questions';
import GlpConsent from "../Questionnaire/Questions/GlpConsent";
import { CircularProgress } from "@mui/material";
import { belugaFollowUpAnswers, formattedQnA, isQualified, reqFollowupFieldsCheck, validateFollowupAnswers } from "../Questionnaire/Functions";
import ConsentSection from './ConsentSection'
import SuccessModal from "../Questionnaire/SuccessModal";
import { belugaAllFollowUpQuestions, belugaAllQuestions, BelugaCommonQuestions, belugaMedications, belugaPremierMedications, minorSemaglitudeDose, minorSemaglitudeDosePremier, minorTripaztideDose, minorTripaztideDosePremier, missingFiedlsStr, onlyFollowUpQuestions, unAcceptedConsentStr } from "../../utils/staticData";
import toast from "react-hot-toast";
import { capitalizeFirtLetter, logoutRedirection } from "../../utils/reusableFunctions";
import PersonalInfoForm from './PersonalInfo';
import { belugaNewWeightLossFormSubmit, belugaWeightLossFormSubmit } from '../../services/BelugaService';
import { useAuth0 } from '@auth0/auth0-react';
import { useLocation } from 'react-router-dom';
import { formatWeightLossFollowUpAnswers, prefillPersonalInfo } from './functions';
import ErrorrText from '../../components/shared/ErrorrText';
import HorizontalBox from '../../components/shared/HorizontalBox';
import { clearAllLocalData } from '../../utils/localStorageFunctions';
import BelugaPatientsHeader from '../../components/Headers/BelugaPatientsHeader/BelugaPatientsHeader';

const WeightLossFollowUp = () => {
    //auth0 handler
    const {user:{email},logout} = useAuth0()

    // states
    const [sex, setSex] = useState("");
    const [showConsent, setShowConsent] = useState(false);
    const [isPregnancyQuestionVisible, setPregnancyQuestionVisible] = useState(false);
    const [pregnant, setIsPregnant] = useState("");
    const [heightFeet, setHeightFeet] = useState("");
    const [heightInches, setHeightInches] = useState("");
    const [weight, setWeight] = useState("");
    const [bmiConsentAcknowledged, setBmiConsentAcknowledged] = useState("");
    const [bmi, setBmi] = useState(null);
    const [bmiMessage, setBmiMessage] = useState("");
    const [medicalConditions, setmedicalConditions] = useState("");
    const [selfReportedMeds, setSelfReportedMeds] = useState("none");
    const [allergies, setAllergies] = useState("");
    const [gastricBypass, setGastricBypass] = useState(""); 
    const [medsLastTaken, setMedsLastTaken] = useState(""); // Q8  
    const [experiencedSideEffects, setExperiencedSideEffects] = useState(''); 
    const [sideEffectsDescription, setSideEffectsDescription] = useState('');
    const [treatmentContinuation, setTreatmentContinuation] = useState(''); 
    const [additionalQuestions, setAdditionalQuestions] = useState('');
    const [doseSelection, setDoseSelection] = useState('');
    const [consentsSigned, setConsentsSigned] = useState(false)
    const [showModal, setShowModal] = useState(false)
    const [userConsent, setUserConsent] = useState({ pregnancy: false, bmi: false, gallBlader: false, truthFull: false, glp: false })
    const [loading, setLoading] = useState(false)
    const [EDvisit, setEDvisit] = useState('');
    const [EDvisitDescription, setEDvisitDescription] = useState('');
    const [personalInfo, setPersonalInfo] = useState({
        firstName:"",
        lastName:"",
        dob:"",
        phone:'',
        email,
        address:"",
        city:'',
        state:'AL',
        zip:''
    })
    const [failedValidations, setFailedValidations] = useState([]) 
    const [missingFields, setMissingFields] = useState([])

     //refs
  const sectionRefs = useRef({
    ed: null,
    medication: null,
    questionOne:null,
    questionTwo:null,
    questionFourTeen:null,
    truthFullConsent: null,
    glpConsnet: null,
    sideEffectsDescription:null,
    gastricBypass:null,
    questionFive:null,
    questionSix:null,
    questionSeven:null

  });
    // router params
    const {state:locationState} = useLocation()

    //use Effects
    useEffect(()=>{
        locationState?.preFilledData &&  prefillPersonalInfo(locationState?.preFilledData,personalInfo, setPersonalInfo,setHeightFeet,setHeightInches,setWeight,setGastricBypass,setExperiencedSideEffects,setSideEffectsDescription,setDoseSelection,setTreatmentContinuation,setAdditionalQuestions,setUserConsent,setmedicalConditions,setSelfReportedMeds,setAllergies,setSex,setPregnancyQuestionVisible,setIsPregnant,setShowConsent,userConsent)
    },[locationState?.preFilledData])

    const handlePregnancyChange = (event) => {
        const pregnant = event.target?.value;
        setShowConsent(true);
        if (pregnant) {
            setIsPregnant(pregnant);
           
        }
    };
    const handleSubmit = async () => {
        if (reqFollowupFieldsCheck({
            heightInches,
            heightFeet,
            weight,
            gastricBypass,
            additionalQuestions,
            sex,
            medicalConditions,
            selfReportedMeds,
            allergies,
            EDvisit,
            medsLastTaken,
            personalInfo,
            setMissingFields,
            sectionRefs,
            treatmentContinuation,
            doseSelection
        }))
        {
            if (validateFollowupAnswers(userConsent, sex, pregnant, bmi, gastricBypass,setFailedValidations, failedValidations,sectionRefs,sideEffectsDescription,experiencedSideEffects,EDvisitDescription,EDvisit))
                
               
                {
                //define payload

                const { 
                    firstName,
                    lastName,
                    dob,
                    phone,
                    email,
                    address,
                    city,
                    state,
                    zip
                  } = personalInfo 


                const {q3A,q8A,q9A,q10A,q12A,q13A,q14A,q19A,q21A,q22A} = locationState?.preFilledData
                 const allAnswers = belugaFollowUpAnswers(sex,pregnant,q3A,heightFeet,heightInches,weight,bmi,q8A,q9A,q10A,q12A,q13A,q14A,experiencedSideEffects,sideEffectsDescription,doseSelection,treatmentContinuation,q19A,additionalQuestions,q21A,q22A,EDvisit,EDvisitDescription,medsLastTaken)
                // const selectedDose = medsLastTaken !== 'It has been over 2 months' && ['FL', 'NY', 'NJ'].includes(state) ? 
                // belugaMedications?.find(elem => elem?.name === doseSelection) : 
                // medsLastTaken !== 'It has been over 2 months' && ['FL', 'NY', 'NJ'].includes(state) ? 
                // belugaPremierMedications?.find(elem => elem?.name === doseSelection) :
                // !['FL', 'NY', 'NJ'].includes(state) ? 
                // doseSelection?.toLowerCase()?.includes('semaglutide') ? minorSemaglitudeDosePremier : minorTripaztideDosePremier :
                // doseSelection?.toLowerCase()?.includes('semaglutide') ? minorSemaglitudeDose : minorTripaztideDose

                let selectedDose;

                if (medsLastTaken !== 'It has been over 2 months' ) {
                    selectedDose = ['FL', 'NY', 'NJ'].includes(state) ? belugaMedications?.find(elem => elem?.name === doseSelection) :
                        belugaPremierMedications?.find(elem => elem?.name === doseSelection);
                } else if (!['FL', 'NY', 'NJ'].includes(state)) {
                    selectedDose = doseSelection?.toLowerCase()?.includes('semaglutide')
                        ? minorSemaglitudeDosePremier
                        : minorTripaztideDosePremier;
                } else {
                    selectedDose = doseSelection?.toLowerCase()?.includes('semaglutide')
                        ? minorSemaglitudeDose
                        : minorTripaztideDose;
                }

                const preferenceList = [
                    {
                        "name": selectedDose?.name?.split(' ')?.[0] || "Semaglutide 0.25mg/mL Injection - Dose 1",
                        "strength": selectedDose?.name?.split(' ')?.slice(1)?.join(' ') || '0',
                        "quantity": selectedDose?.quantity?.toString() ?? '1',
                        "refills": "0",
                        "medId": selectedDose?.medId ?? "3tXoVGqJp6F9cTSCj1DNE9UMaOznFCJC"
                    }
        ]
                const belugaQuestionAnswers = belugaAllFollowUpQuestions?.map((elem,index)=>{
                    return(
                        {
                            question:elem,
                            answer: allAnswers[index]
                        }
                    )
                })?.filter(elem => {
                    return elem?.answer !== null
                  })
                
                try {
                    setLoading(true)
                    const payload = {
                        consentsSigned: true,
                        sex: capitalizeFirtLetter(sex),
                        medicalConditions,
                        selfReportedMeds,
                        knownAllergies: allergies,
                        weightlossPreference: treatmentContinuation?.includes('same') ? 'same' : treatmentContinuation?.includes('Increase') ? 'increase' :treatmentContinuation?.includes('Decrease') ? 'decrease' : 'N/A',
                        currentDose: selectedDose?.category  || 'N/A',
                        visitType: "weightlossfollowup",
                        questions:belugaAllFollowUpQuestions,
                        answers:allAnswers,
                        preferenceList,
                        pharmacyId:selectedDose?.pharmacyId?.toString() ?? "pharmacy789",
                        company: "bradysWellnessRx",
                        npiNumber: 1285170167,
                        weightlossfollowup: "N/A",
                        currentOrPastMedicalConditions: medicalConditions ?? 'None',
                        finalConsent: true,
                        firstName,
                        lastName,
                        dateOfBirth:dob && new Date(dob)?.toISOString(),
                        phone,
                        email,
                        address,
                        city,
                        state,
                        zip,
                        currentWeightloss:locationState?.preFilledData?.currentWeightloss ?? 'neither',
                        belguaAnswers:belugaQuestionAnswers?.map(elem => elem?.answer),
                        belguaQuestions: belugaQuestionAnswers?.map(elem => elem?.question)
                        
                    }
                    const formSubmitted = await belugaNewWeightLossFormSubmit(payload);
                    if(formSubmitted){
                      setShowModal(true)
                    }
                   } catch (error) {
                    console.log(error)
                   }
                   finally{
                    setLoading(false)
                   }
                }           
               
        }
        else{
            toast.error(missingFiedlsStr);
        }
    }

      // handle logout
  const handleLogout = () => {
    clearAllLocalData()
    logout({ logoutParams: { returnTo: logoutRedirection() } })
}

    return (
        <div className="weight-loss-form">
                 <BelugaPatientsHeader/>
                <PersonalInfoForm
                personalInfo = {personalInfo}
                setPersonalInfo = {setPersonalInfo}
                />
               <div ref={(el) => sectionRefs.current.questionOne = el}>
               <QuestionOne
                    sex={sex}
                    setSex={setSex}
                    isPregnancyQuestionVisible={isPregnancyQuestionVisible}
                    // handleSexChange={handleSexChange}
                    pregnant={pregnant}
                    handlePregnancyChange={handlePregnancyChange}
                    showConsent={showConsent}
                    // handleConsentAcknowledge={handleConsentAcknowledge}
                    setUserConsent={setUserConsent}
                    userConsent={userConsent}
                    setShowConsent={setShowConsent}
                    setPregnancyQuestionVisible={setPregnancyQuestionVisible}
                    failedValidations={failedValidations}

                />
               </div>

            <div ref={(el) => sectionRefs.current.questionTwo = el}>
                <QuestionTwo
                    heightFeet={heightFeet}
                    setHeightFeet={setHeightFeet}
                    heightInches={heightInches}
                    setHeightInches={setHeightInches}
                    weight={weight}
                    setWeight={setWeight}
                    bmi={bmi}
                    bmiConsentAcknowledged={bmiConsentAcknowledged}
                    setBmiConsentAcknowledged={setBmiConsentAcknowledged}
                    setBmi={setBmi}
                    setBmiMessage={setBmiMessage}
                    setUserConsent={setUserConsent}
                    userConsent={userConsent}
                    failedValidations={failedValidations}
                    missingFields={missingFields}
                />
            </div>
            <div ref={(el) => sectionRefs.current.ed = el}>
                <QuestionED

                    EDvisit={EDvisit}
                    EDvisitDescription={EDvisitDescription}
                    setEDvisit={setEDvisit}
                    setEDvisitDescription={setEDvisitDescription}
                />
            </div>
            {failedValidations?.includes('edVisit') && <ErrorrText text={'Please add some details for your visit'} />}
            {missingFields?.includes('EDvisit') && <ErrorrText importantField />}

            <div ref={(el) => sectionRefs.current.questionFive = el}>
                <QuestionFive
                    medicalConditions={medicalConditions}
                    setmedicalConditions={setmedicalConditions}
                    inputError={missingFields?.includes("medicalConditions")}
                />
            </div>
            {missingFields?.includes("medicalConditions") && <ErrorrText importantField />}
            <div ref={(el) => sectionRefs.current.questionSix = el}>
                <QuestionSix
                    selfReportedMeds={selfReportedMeds}
                    setSelfReportedMeds={setSelfReportedMeds}
                    inputError={missingFields?.includes("selfReportedMeds")}
                />
            </div>
            {missingFields?.includes("selfReportedMeds") && <ErrorrText importantField />}
            <div ref={(el) => sectionRefs.current.questionSeven = el}>
                <QuestionSeven
                    allergies={allergies}
                    setAllergies={setAllergies}
                    failedValidations={failedValidations}
                    inputError={missingFields?.includes("allergies")}
                />
            </div>
            {missingFields?.includes("allergies") && <ErrorrText importantField />}
<div ref={(el) => sectionRefs.current.gastricBypass = el}>
                <QuestionEight
                    gastricBypass={gastricBypass}
                    setGastricBypass={setGastricBypass}
                    failedValidations={failedValidations}
                />
                </div>
                <div ref={(el) => sectionRefs.current.medication = el}>
                <QuestionPrescribed
                    medsLastTaken={medsLastTaken}
                    setMedsLastTaken={setMedsLastTaken}
                />
                </div>
{missingFields?.includes("medsLastTaken") && <ErrorrText importantField/>}
            <div ref={(el) => sectionRefs.current.sideEffectsDescription = el}>
                <QuestionEleven
                    experiencedSideEffects={experiencedSideEffects}
                    handleSideEffectsChange={(e) => setExperiencedSideEffects(e?.target?.value)}
                    sideEffectsDescription={sideEffectsDescription}
                    setSideEffectsDescription={setSideEffectsDescription}
                    doseSelection={doseSelection}
                    handleDoseChange={(e) => setDoseSelection(e?.target?.value)}
                    treatmentContinuation={treatmentContinuation}
                    handleTreatmentContinuationChange={(e) => setTreatmentContinuation(e?.target?.value)}
                    missingFields={missingFields}
                    failedValidations={failedValidations}
                    preFilledData={locationState?.preFilledData}
                />
            </div>

               <div ref={(el) => sectionRefs.current.questionFourTeen = el}>
               <Question14
                    additionalQuestions={additionalQuestions}
                    handleAdditionalQuestionsChange={(e) => setAdditionalQuestions(e?.target?.value)}
                    inputError = {missingFields?.includes("additionalQuestions") }
                />
               </div>

{missingFields?.includes('additionalQuestions') && <ErrorrText importantField/>}
         
                <div ref={(el) => sectionRefs.current.truthFullConsent = el}>
                <ConsentSection
                    consentsSigned={consentsSigned}
                    setConsentsSigned={setConsentsSigned}
                    setUserConsent={setUserConsent}
                    userConsent={userConsent}
                    failedValidations={failedValidations}
                />
                </div>
                

               <div ref={(el) => sectionRefs.current.glpConsnet = el}>
               <GlpConsent
                    setUserConsent={setUserConsent}
                    userConsent={userConsent}
                    failedValidations={failedValidations}
                />
               </div>
 {failedValidations?.includes('glpConsent') && <ErrorrText text={unAcceptedConsentStr}/>}

 <HorizontalBox gap={2}>
                <button onClick={handleSubmit} disabled={loading}> {loading ? <CircularProgress color="inherit" size={15} /> : 'Submit'} </button>
                <button onClick={handleLogout} color="danger">Logout</button>
                </HorizontalBox>

                <SuccessModal
                    open={showModal}
                    handleClose={() => setShowModal(false)}
                />
        </div>
    );
};

export default WeightLossFollowUp;

