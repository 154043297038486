import { AgGridReact } from 'ag-grid-react'
import React, { useEffect, useState }  from 'react'
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import { gridOptions } from '../../utils/gridOptions';
import { sharedStyles } from '../../sharedStyles';
import ScheduledActions from './GridCellsRenderers/ScheduledActions';
import PageLoader from '../../components/loader/PageLoader';
import { getAllScheduledTransactionsDetailsClaims } from '../../services/ClaimService';
import toast from 'react-hot-toast';
import { noDataInTableStr } from '../../utils/staticData';
import { failedApiReq } from '../../utils/reusableFunctions';
import { dayJsMdyFormat } from '../../utils/DateFunctions';

const ScheduledTransactionsTable = ({ action,cancelTask,isChecking,checkTaskNow,taskToBeHandled, setTaskToBeHandled,isCancelling,refetchApi,setNoDataInTable}) => {

const [gridApi, setGridApi] = useState(null);
const perPage = 10;

  const onGridReady = (params) => {
    setGridApi(params.api);
  };

  useEffect(() => {
    if (gridApi) {
      const dataSource = {
        getRows: async(params) => {

          const page = params.endRow / perPage;
          try{
            gridApi.showLoadingOverlay();
          const data = await getAllScheduledTransactionsDetailsClaims(page,perPage);
            if (data?.data?.length) {
                const dataArray = data?.data?.map(({
                    scheduleTaskData: { scheduleDate, status, id, requestParameters },
                    patientData: { firstName, lastName, phone,id:patientId }
                  }) => {
                    return (
                      {
                        scheduleDate: dayJsMdyFormat(scheduleDate) ?? '---',
                        patient: firstName + ' ' + lastName,
                        phone,
                        status,
                        id,
                        patientId,
                        requestParameters
                      }
                    )
                  })
            params.successCallback(dataArray, data.count);
            setNoDataInTable(false)
        }
    else {
      params.successCallback([], 0); 
      setNoDataInTable(true)}
    }
    catch(e){
            params.successCallback([], 0);
            failedApiReq(e)
          }
          finally{
            gridApi.hideOverlay();
          }
        }
      }

      gridApi.setDatasource(dataSource);
    }
  }, [gridApi,refetchApi]);

    const columnDefs = () => {
        return [
            { headerName: 'Schedule Date', field: 'scheduleDate', width: 130,resizable:false },
            { headerName: 'Patient', field: 'patient', sortable: true, filter: true, width:236,resizable:false },
            { headerName: 'Phone', field: 'phone', sortable: true, filter: true,width:200,resizable:false },
            { headerName: 'Status', field: 'status', sortable: true, filter: true,width:102,resizable:false  },
            { headerName: 'Actions', field: 'actions',resizable:false,
              // headerClass: "ag-right-aligned-header",
               flex:1,minWidth:143, cellRenderer:(params)=> 
                {
                    if (params?.data) {
                        return (
                            ScheduledActions(params,()=>action(params),() => cancelTask(params),isChecking,()=>checkTaskNow(params),setTaskToBeHandled,taskToBeHandled,isCancelling )
                        )

                    }
                    else return <div></div>
                }},
        ]
    }
    return (
        <div className="ag-theme-quartz" style={sharedStyles?.h500}  >
         <AgGridReact
                pagination={true}
                rowModelType={'infinite'}
                paginationPageSize={perPage}
                cacheBlockSize={perPage}
                onGridReady={onGridReady}
                rowHeight={60}
                defaultColDef={{ flex: 1,minWidth:170 }}
                columnDefs={columnDefs()}
                overlayLoadingTemplate={'<span class="ag-overlay-loading-center">Loading...</span>'}
            />
        </div>
    )
}

export default ScheduledTransactionsTable