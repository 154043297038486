import { colors } from "../../../utils/colors";

export const styles = {
    container:{
        background:colors?.white,
        justifyContent:'flex-end',
      
    },
    patientCardButton: {
        px: 3,
        fontSize: "16px",
        textTransform: "none",
        borderRadius: "8px",
        backgroundColor: colors?.themeGreen,
        "&:hover": {
            backgroundColor: colors?.green
        }
    },
}