import { AgGridReact } from 'ag-grid-react'
import React, { useEffect, useState } from 'react'
import { IconCellRenderer, IconCellRendererTwo, gridOptions } from './configuration/NpiConfiguration'
import { npiDefault } from '../../utils/staticData';
import { npiSync } from '../../services/NpiFinderInquiry/npiFinder';
import { failedApiReq } from '../../utils/reusableFunctions';
import { dayJsMdyFormat } from '../../utils/DateFunctions';






const NpiResultsTable = () => {

  const [gridApi, setGridApi] = useState(null);
  const perPage = 10;

  const onGridReady = (params) => {
    setGridApi(params.api);
  };


  const columnDefs = () => {
    return [
      { headerName: 'First Name', field: 'First Name', filter: true, width: 130, resizable: false },
      { headerName: 'Last Name', field: 'Last Name', filter: true, width: 120, resizable: false },
      { headerName: 'Organization Name', field: 'Organization Name', filter: true, width: 251, resizable: false },
      { headerName: 'NPI', field: 'NPI', width: 120, filter: true, resizable: false },
      { headerName: 'Gender', field: 'Gender', width: 98, filter: true, resizable: false },
      { headerName: 'Npi Type', field: 'Npi Type', filter: true, width: 146, resizable: false },
      { headerName: 'Status', field: 'Status', filter: true, width: 100, resizable: false},
      { headerName: 'Certification Date', field: 'Certification Date', width: 151, resizable: false },
      { headerName: 'Action', field: 'Action', width: 150, cellRenderer: IconCellRenderer, resizable: false },
      { headerName: 'Action', field: 'Action', minWidth: 97, cellRenderer: IconCellRendererTwo, flex: 1, resizable: false },
    ];
  };


  useEffect(() => {
    if (gridApi) {
      const dataSource = {
        getRows: async (params) => {
          const pageNumber = params.endRow / perPage;
          try {
            gridApi.showLoadingOverlay();
            const data = await npiSync({ ...npiDefault, pageNumber, pageSize: perPage });
            const dataArray = data?.data?.data?.length && data?.data?.data?.map(({ npi, id, gender, certificationDate, npiType, enumerationDate, status, providerOrganizationName, providerFirstName, providerLastName }) => ({
              'First Name': providerFirstName,
              'Last Name': providerLastName,
              "Organization Name": providerOrganizationName,
              NPI: npi || '----',
              Gender: gender || '----',
              "Certification Date":  certificationDate ? dayJsMdyFormat(certificationDate) : '----',
              "Npi Type": npiType || '----',
              EnumerationDate: enumerationDate || '----',
              Status: status || '----',
              Action: '',
              Action: '',
              id
            }))
            params.successCallback(dataArray, data?.data?.count);
          }
          catch (e) {
            params.successCallback([], 0);
            failedApiReq(e)
          }
          finally {
            gridApi.hideOverlay();
          }
        }
      }

      gridApi.setDatasource(dataSource);
    }
  }, [gridApi]);

  return (
    <div className="ag-theme-quartz"
      style={{ height: 500 }}  >
      <AgGridReact
        pagination={true}
        rowModelType={'infinite'}
        paginationPageSize={perPage}
        cacheBlockSize={perPage}
        onGridReady={onGridReady}
        rowHeight={60}
        columnDefs={columnDefs()}
        overlayLoadingTemplate={'<span class="ag-overlay-loading-center">Loading...</span>'}
      />
    </div>
  )
}

export default NpiResultsTable;

