export const prefillPersonalInfo = (formPersonalInfo,personalInfo, setPersonalInfo,setHeightFeet,setHeightInches,setWeight,setGastricBypass,setExperiencedSideEffects,setSideEffectsDescription,setDoseSelection,setTreatmentContinuation,setAdditionalQuestions,setUserConsent,setmedicalConditions,setSelfReportedMeds,setAllergies,setSex,setPregnancyQuestionVisible,setIsPregnant,setShowConsent,userConsent) => {
const { 
    firstName:prefilledFirstName = '',
    lastName:preFilledLastName = '',
    dateOfBirth:preFilledDob = '',
    phone:preFilledPhone = '',
    email:preFilledEmail = '',
    address:preFilledAddress = '',
    city:preFilledCity = '',
    state:preFilledState = 'AL',
    zipCode:preFilledZip = '',
    q4A = '',
    sex = '',
    q1A = '',
    q15A = '',
    q16A = '',
    q17A = '',
    q20A = '',
    q18A = '',
    q5A = '',
    q11A = '',
    selfReportedMeds = '',
    knownAllergies = '',
    currentOrPastMedicalConditions = ''
} = formPersonalInfo
const {
    firstName ,
    lastName ,
    dob ,
    phone ,
    email ,
    address ,
    city ,
    state ,
    zip 
} = personalInfo
setPersonalInfo({
    firstName: prefilledFirstName ?? firstName ,
    lastName:preFilledLastName ?? lastName ,
    dob:preFilledDob ?? dob ,
    phone: preFilledPhone ?? phone ,
    email: preFilledEmail ?? email ,
    address: preFilledAddress ?? address ,
    city: preFilledCity ?? city ,
    state: preFilledState ?? state ,
    zip: preFilledZip ?? zip
 }
)
setHeightFeet(q4A ? q4A?.split("'")?.[0] : '')
setHeightInches(q4A ? q4A?.split("'")?.[1] : '')
setWeight(q5A ?? '')
setExperiencedSideEffects(q15A ?? '')
setSideEffectsDescription(q16A ?? '')
setDoseSelection(q17A ?? '')
setGastricBypass(q11A ?? '')
setAdditionalQuestions(q20A ?? '')
setTreatmentContinuation(q18A ?? '')
setSelfReportedMeds(selfReportedMeds ?? 'none')
setAllergies(knownAllergies ?? 'none')
setmedicalConditions(currentOrPastMedicalConditions??'none')

setSex(sex?.toString()?.toLowerCase())
if(sex?.toString()?.toLowerCase() === 'female'){
    setPregnancyQuestionVisible('female')
}
setIsPregnant(q1A)
if(sex?.toLowerCase() === 'female'){
    setShowConsent(true)
}
setUserConsent({
    bmi: true,
    gallBlader: true,
    truthFull: true,
    glp: true,
    pregnancy: sex?.toLowerCase() === 'female'
});

}

export const formatWeightLossFollowUpAnswers = (sex,pregnant,heightFeet,heightInches,weight,bmi,gastricBypass,experiencedSideEffects,sideEffectsDescription,doseSelection,treatmentContinuation,additionalQuestions,EDvisit,EDvisitDescription,medsLastTaken) =>{
    const formattedAnswers = [
        sex?.toString()?.toLowerCase() === 'female' ? pregnant : null,
        sex?.toString()?.toLowerCase() === 'female' && pregnant ? 'true' : null,
        `${heightFeet}'${heightInches}`,
        weight,
        bmi,
        'true',
        gastricBypass,
        experiencedSideEffects,
        experiencedSideEffects ? sideEffectsDescription : null,
        experiencedSideEffects ? doseSelection : null,
        experiencedSideEffects ? treatmentContinuation : null,
        additionalQuestions,
        'true',
        'true',
        EDvisit,
        EDvisit ? EDvisitDescription : '',
        medsLastTaken

    ]
    return formattedAnswers
}