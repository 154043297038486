import React, { useState } from "react";
import SearchIcon from '@mui/icons-material/Search';
import styles from "../../css/syncpatient.module.css";
import { Autocomplete, Box, FormControl, FormHelperText, Grid, IconButton, Input, InputAdornment, MenuItem, Select, TextField, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { sync_Patient } from "../../services/addPatient";
import SyncTable from "../../components/table/SyncTable";
import toast from "react-hot-toast";
import { isAlphabetOnly } from "../../utils/regexTestFunctions";
import { sharedStyles } from "../../sharedStyles";

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

const SyncPatient = () => {
  const [patientDetails, setPatientDetails] = useState({
    firstname: "",
    middleName:"",
    lastname: "",
    dateOfBirth: null,
    search: ""
  });
  const [payersData, setpayersData] = useState([]);
  const [results, setResults] = useState([])
  const [showResults, setShowResults] = useState(false);
  const [fieldsError, setError] = useState(false);
  const [isLoading, setIsloading] = useState(false)
  const { formWrapper } = styles;

  const handleChange = (e) => {
    if (e.target && e.target.name) {
      const { name, value } = e.target;
      if (name === "firstname" || name === "lastname") {
        if (!isAlphabetOnly(value)) {
          toast.error('This field only accepts Alphabets.');
          return;
        }
        setPatientDetails((prevState) => ({
          ...prevState,
          [name]: value,
        }));
      }
      setPatientDetails((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { firstname, lastname, search, dateOfBirth } = patientDetails;
    if (!firstname || !lastname || !search || !dateOfBirth) {
      setError(true);
      toast.error('Please enter all required fields.')
      return;
    }
    const formattedDetails = {
      ...patientDetails,
      dateOfBirth: patientDetails.dateOfBirth.format('YYYY-MM-DD'),
    };
    setIsloading(true)
    try {
      const response = await sync_Patient(formattedDetails);
      if (response?.data?.patients !== null) {
        setResults(response?.data?.patients);
        setIsloading(false)
        setShowResults(true);
      } else {
        toast.error("No Patient was found!");
      }
    } catch (error) {
      console.error(error)
    } finally {
      setIsloading(false)
    }
  };

  const inputSearch = ['AdvanceMd', 'pverify']

  return (
    <div>
      {
        showResults ?
          <Box sx={{ marginTop: '1em' }}>
            <SyncTable pagename={'syncpatient'} data={results?.length > 0 ? results : null} />
            <Button sx={{ borderRadius: '10px', color: 'white', marginBottom: '0.5em', marginTop: '1em' }} onClick={() => {
              setPatientDetails({
                firstname: "",
                lastname: "",
                dateOfBirth: null,
                search: ""
              })
              setShowResults(false)
              setError(false)
              setIsloading(false)
            }} type="submit" variant="contained" color="secondary" size="small">
              Reset
            </Button>
          </Box>
          :
          <Box className={formWrapper}>
            <form onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} lg={6}>
                  <FormControl
                    sx={{ marginBottom: "2em", color: "purple" }}
                    fullWidth
                  >
                    <TextField
                      value={patientDetails.firstname}
                      id="outlined-start-adornment"
                      sx={{
                        '& .MuiOutlinedInput-notchedOutline ': {
                          borderRadius: '12px',
                        }
                      }}
                      helperText={fieldsError && !patientDetails.firstname ? 'This Field is Required' : 'Required*'}
                      InputProps={{
                        startAdornment: <InputAdornment position="start"></InputAdornment>,
                      }}
                      error={fieldsError && !patientDetails.firstname}
                      onChange={handleChange}
                      name="firstname"
                      label="First Name"
                      variant="outlined"
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={12} lg={6}>
                  <FormControl
                    sx={{ marginBottom: "2em", color: "purple" }}
                    fullWidth
                  >
                    <TextField
                      value={patientDetails.middleName}
                      id="outlined-start-adornment"
                      sx={{
                        '& .MuiOutlinedInput-notchedOutline ': {
                          borderRadius: '12px',
                        }
                      }}
                      InputProps={{
                        startAdornment: <InputAdornment position="start"></InputAdornment>,
                      }}
                      onChange={handleChange}
                      name="middleName"
                      label="Middle Name"
                      variant="outlined"
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={12} lg={6}>
                  <FormControl sx={{ marginBottom: "2em" }} fullWidth>
                    <TextField
                      InputProps={{
                        startAdornment: <InputAdornment position="start"></InputAdornment>,
                      }}
                      sx={{
                        '& .MuiOutlinedInput-notchedOutline ': {
                          borderRadius: '12px',
                        }
                      }}
                      helperText={fieldsError && !patientDetails.lastname ? 'This Field is Required' : 'Required*'}
                      id="outlined-start-adornment"
                      onChange={handleChange}
                      value={patientDetails.lastname}
                      name="lastname"
                      label="Last Name"
                      variant="outlined"
                      error={fieldsError && !patientDetails.lastname}
                    />

                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={12} lg={6}>
                  <FormControl sx={{ marginBottom: "2em" }} fullWidth>
                    <LocalizationProvider adapterLocale={'de'} dateAdapter={AdapterDayjs}>
                      <DatePicker
                        sx={{
                          '& .MuiOutlinedInput-notchedOutline ': {
                            borderRadius: '12px'
                          },
                          '& .MuiOutlinedInput-notchedOutline ': {
                            borderColor: fieldsError && !patientDetails?.dateOfBirth ? '#d32f2f' : ''
                          },
                          '& .MuiFormLabel-root': {
                            color: fieldsError && !patientDetails?.dateOfBirth ? '#d32f2f' : 'rgba(0, 0, 0, 0.6)'
                          },
                          '& .MuiOutlinedInput-root': {
                            '& .MuiOutlinedInput-notchedOutline': {
                              borderRadius: '12px',
                            }
                          }
                        }}
                        label="Date of birth"
                        format="YYYY/MM/DD"
                        onChange={(val) => {
                          if (val) {
                            setPatientDetails({
                              ...patientDetails,
                              dateOfBirth: val,
                            });
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            required
                            error={!patientDetails.dateOfBirth && fieldsError}
                            helperText={!patientDetails.dateOfBirth && fieldsError ? 'The field is required' : 'Required*'}
                          />
                        )}
                        name={"dateOfBirth"}
                        value={patientDetails.dateOfBirth}
                        variant="outlined"
                        required
                      />
                    </LocalizationProvider>
                    {fieldsError && !patientDetails?.dateOfBirth && (
                      <FormHelperText sx={{ color: '#d32f2f' }}>
                        This field is required.
                      </FormHelperText>
                    )}
                  </FormControl>
                </Grid>


                <Grid item xs={12} lg={6}>
                  <FormControl variant="outlined" fullWidth error={fieldsError && !patientDetails.search}>
                    <Select
                      id="patient-management-dropdown"
                      value={patientDetails.search || ''}
                      onChange={(e) => setPatientDetails({ ...patientDetails, search: e.target.value })}
                      displayEmpty
                      color="success"
                      IconComponent={KeyboardArrowDownIcon}
                      renderValue={(selected) => (selected ? selected : 'Patient Management System')}
                      sx={{
                        '& .MuiSelect-outlined': {
                          borderRadius: '12px',
                        },

                        borderRadius: '12px', // Added border radius for the Select component
                        color: fieldsError && !patientDetails?.search ? '#d32f2f' : 'rgba(0, 0, 0, 0.6)'
                      }}
                    >
                      {inputSearch.map((option) => (
                        <MenuItem key={option} value={option}>
                          {option}
                        </MenuItem>
                      ))}
                    </Select>
                    {fieldsError && !patientDetails.search && (
                      <FormHelperText>This Field is required</FormHelperText>
                    )}
                  </FormControl>
                </Grid>
                <Grid item xs={12} lg={12}>
                  <Box sx={{ display: "flex", gap: '1.5em', justifyContent: "center" }}>
                    <Button disabled={isLoading} sx={sharedStyles?.whiteBtn} type="submit" variant="contained" size="large">
                      Clear
                    </Button>
                    <Button disabled={isLoading} sx={sharedStyles?.themeBtn} type="submit" variant="contained" size="small">
                      Search
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </form>
          </Box>
      }
    </div >
  );
};

export default SyncPatient;



