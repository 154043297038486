import './App.css';
import './css/Header.css';
import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Routes} from "react-router-dom";
import '@aws-amplify/ui-react/styles.css';
import Layout from './Layout/Layout';
import { useAuth0 } from '@auth0/auth0-react';
import { Toaster } from 'react-hot-toast';
import AllRoutes from './Routes';
import FullQuestionnaire from './Pages/Questionnaire/QuestionnaireForm.jsx'
import WeightLossFollowUp from './Pages/QuestionnaireFollowUp/WeightLossFollowUp.jsx';
import PatientLandingPage from './patientPortal/Pages/PatientLandingPage.jsx';
import MainPage from './Pages/MainPage/MainPage.jsx';
import PatientHistory from './Pages/BelugaPatients/PatientHistory/PatientHistory.jsx';
function App() {
  
  const { isAuthenticated, loginWithRedirect, getIdTokenClaims, isLoading, logout,loginWithPopup } = useAuth0();
  const [userType,setUserType] = useState('user')

  const domainCheck = () => {
    const currentUrl = window.location.href;
    const baseUrl = new URL(currentUrl).origin;
    if(baseUrl?.includes('beluga')){
      setUserType('patient')
    }
  }
  useEffect(() => {
    domainCheck()
  }, [isLoading, isAuthenticated, logout, getIdTokenClaims, loginWithRedirect]);


  const handleLogin = async () => {
    try {
      await loginWithPopup(); // Login using popup
    } catch (error) {
      console.error("Login failed", error);
    }
  };
  return (
    isAuthenticated ?
      <>
        <Router>
          {
            userType === 'patient' ? (
              <Layout isPatient={true}>
                <Routes>
                  <Route path="/" element={<PatientLandingPage />} />
                  <Route path="/que" element={<></>} />
                  <Route path="/questionnaire" element={<FullQuestionnaire />} />
                  <Route path="/questionnaire-follow-up" element={<WeightLossFollowUp />} />
                  <Route path='/visitsHistory' element={<PatientHistory/>} />
                </Routes>
              </Layout>
            ) : (
              <Routes>
                <Route
                  path="/*"
                  element={
                    <Layout>
                      <AllRoutes />
                    </Layout>
                  }
                />
              </Routes>
            )
          }
        </Router>
        <Toaster />
      </>

      : 
      (
        <Router>
          <Routes>
           <Route path='/' element={<MainPage userType={userType} handleLogin={handleLogin}/>}/>
          </Routes>
        </Router>
      )
  );
}
export default App;