import React, { useState } from "react";
import "./FullQuestionnaire.css";
import './PersonalInfo.css';
import { unitedStates } from "../../utils/staticData";
import { sharedStyles } from "../../sharedStyles";
import ErrorrText from "../../components/shared/ErrorrText";
import { allowNumbersOnly, allowTextOnly, isAlphabetOnly } from "../../utils/regexTestFunctions";
import HorizontalBox from "../../components/shared/HorizontalBox";
import { styles } from "./styles";

const PersonalInfoForm = ({
    firstName,
    setFirstName,
    lastName,
    setLastName,
    dob,
    setDob,
    phone,
    setPhone,
    email,
    setEmail,
    address,
    setAddress,
    city,
    setCity,
    state,
    setState,
    zip,
    setZip,
    missingFields
}) => {
    const [dobError, setDobError] = useState('');

    // Function to validate 18+ years for Date of Birth
    const handleDobChange = (value) => {
        const today = new Date();
        const birthDate = new Date(value);
        const age = today.getFullYear() - birthDate.getFullYear();
        const monthDiff = today.getMonth() - birthDate.getMonth();
        const dayDiff = today.getDate() - birthDate.getDate();

        if (
            age > 18 ||
            (age === 18 && monthDiff > 0) ||
            (age === 18 && monthDiff === 0 && dayDiff >= 0)
        ) {
            setDobError('');
            setDob(value);
        } else {
            setDobError('You must be at least 18 years old.');
        }
    };

 

    return (
        <div>
            <HorizontalBox sx={sharedStyles?.justifyBetween}>
                <img
                    src="https://r2.appzhealth.com/BW-Favicon-White.png"
                    alt="BW Favicon"
                    style={sharedStyles?.wh100}
                />

                <h2 >WEIGHT LOSS QUESTIONNAIRE</h2>
                <img
                    src="https://r2.appzhealth.com/BW-Favicon-White.png"
                    alt="BW Favicon"
                    style={styles?.hiddenLogo}
                />
            </HorizontalBox>
            <div className="personal-info">
                {/* First Name */}
                <div className="form-group">
                    <label className="label-form">
                        First Name <span className="required">*</span>
                    </label>
                    <input className="forms-inputs-one"
                        type="text"
                        value={firstName ?? ''}
                        onChange={(e) => setFirstName(e.target.value)}
                        placeholder="First Name"
                        maxLength="100"
                        required
                        style={missingFields?.includes("firstName") ? sharedStyles?.errorField : null}
                    />
                    {missingFields?.includes("firstName") && <ErrorrText importantField/>}
                </div>

                {/* Last Name */}
                <div className="form-group">
                    <label className="label-form">
                        Last Name <span className="required">*</span>
                    </label>
                    <input className="forms-inputs-one"
                        type="text"
                        value={lastName ?? ''}
                        onChange={(e) => setLastName(e.target.value)}
                        placeholder="Last Name"
                        maxLength="100"
                        required
                        style={missingFields?.includes("lastName") ? sharedStyles?.errorField : null}

                    />
                     {missingFields?.includes("lastName") && <ErrorrText importantField/>}
                </div>

                {/* Date of Birth */}
                <div className="form-group">
                    <label className="label-form">
                        Date of Birth <span className="required">*</span>
                    </label>
                    <input className="forms-inputs-one"
                        type="date"
                        value={dob ?? ''}
                        onChange={(e) => handleDobChange(e.target.value)}
                        required
                        style={missingFields?.includes("dob") ? sharedStyles?.errorField : null}
                    />
                    {dobError && <span className="error1">{dobError}</span>}
                    {missingFields?.includes("dob") && <ErrorrText importantField/>}
                </div>

                {/* Phone */}
                <div className="form-group">
                    <label className="label-form">
                        Phone
                         <span className="required">*</span>
                    </label>
                    <input className="forms-inputs-one"
                        type="tel"
                        value={phone ?? ''}
                        onChange={(e) => setPhone(e.target.value)}
                        placeholder="Phone"
                        // required
                        pattern="[0-9]{10}"
                        maxLength={10}
                        title="Please enter a valid 10-digit phone number."
                        style={missingFields?.includes("phone") ? sharedStyles?.errorField : null}
                        onInput={allowNumbersOnly}
                    />
                     {missingFields?.includes("phone") && <ErrorrText importantField/>}
                </div>

                {/* Email */}
                <div className="form-group">
                    <label className="label-form">
                        Email <span className="required">*</span>
                    </label>
                    <input className="followUpInputs"
                        type="email"
                        value={email ?? ''}
                        // onChange={(e) => setEmail(e.target.value)}
                        placeholder="Email"
                        required
                        readOnly
                        style={missingFields?.includes("email") ? sharedStyles?.errorField : null}
                    />
                     {missingFields?.includes("email") && <ErrorrText importantField/>}
                </div>

                {/* Address */}
                <div className="form-group">
                    <label className="label-form">
                        Address <span className="required">*</span>
                    </label>
                    <input className="forms-inputs-one"
                        type="text"
                        value={address ?? ''}
                        onChange={(e) => setAddress(e.target.value)}
                        placeholder="Address"
                        required
                        style={missingFields?.includes("address") ? sharedStyles?.errorField : null}
                    />
                     {missingFields?.includes("address") && <ErrorrText importantField/>}
                </div>

                {/* City */}
                <div className="form-group">
                    <label className="label-form">
                        City <span className="required">*</span>
                    </label>
                    <input className="forms-inputs-one"
                        type="text"
                        value={city ?? ''}
                        onChange={(e) => setCity(e.target.value)}
                        placeholder="City"
                        required
                        style={missingFields?.includes("city") ? sharedStyles?.errorField : null}
                        onInput={allowTextOnly}
                    />
                     {missingFields?.includes("city") && <ErrorrText importantField/>}
                </div>

                {/* State */}
                 <div className="form-group">
            <label className="label-form">
                State <span className="required">*</span>
            </label>
            <select
                className="forms-inputs-select"
                value={state}
                onChange={(e) => setState(e.target.value)}
                required
                title="Please select a valid state."
            >
                {unitedStates?.map(({ name, code }) => (
                    <option key={name} value={code}>
                        {name}
                    </option>
                ))}
            </select>
        </div>

                {/* Zip */}
                <div className="form-group">
                    <label className="label-form">
                        Zip Code <span className="required">*</span>
                    </label>
                    <input className="forms-inputs-one"
                        type="text"
                        value={zip ?? ''}
                        onChange={(e) => setZip(e.target.value)}
                        placeholder="54321"
                        maxLength="5"
                        pattern="[0-9]{5}"
                        required
                        title="Please enter a valid 5-digit zip code."
                        style={missingFields?.includes("zip") ? sharedStyles?.errorField : null}
                    />
                     {missingFields?.includes("zip") && <ErrorrText importantField/>}
                </div>
            </div>
        </div>
    );
};

export default PersonalInfoForm;
